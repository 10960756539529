import { valuesIn } from 'lodash';
import { useDispatchQuery } from '../hooks/useDispatchQuery';
import {
  GET_CUSTOMER_ACCOUNT_TYPEAHEADS,
  GET_CUSTOMER_FSR_EMAILS_BY_PARTIAL_EMAIL,
  SEARCH_CUSTOMER_POINTS_OF_CONTACT,
} from '../services/customer-account.gql';
import {
  GET_CATALOGS_FOR_CATEGORY,
  GET_MIN_REQ,
  GET_OPTIONAL_REQ,
  GENERATE_READ_SIGNED_URL,
  GET_DOC_METADATA,
  GET_BOACS_BY_PARTIAL,
  GET_REPLACEMENT_ATTACHMENT_TYPES,
  GENERATE_STORE_REPORT,
  CREATE_METADATA_RECORD,
  CREATE_REFERRAL_REPLACEMENT_ATTACHMENT,
  UPDATE_REFERRAL_REPLACEMENT_ATTACHMENT,
  DELETE_REFERRAL_REPLACEMENT_ATTACHMENT,
  DELETE_METADATA_RECORD,
  GET_DISTINCT_STANDARD_ITEMS,
  GET_ACTIVE_CONTRACTS,
} from '../services/data-layer';
import {
  GET_VEHICLE_REFERRAL_LIST,
  GET_REFERRAL_REQUEST,
  GET_AGENCY_DOCUMENT_BY_TYPE,
  CREATE_REFERRAL_REQUEST,
  GET_REFERRAL_VEHICLE_BY_FILTER,
  CREATE_REFERRAL_REPLACEMENT,
  GET_STANDARD_ITEM_BY_SIN,
  CREATE_REFERRAL_NOTE,
  GET_VEHICLES_BY_PARTIAL_VIN_OR_TAG,
  RETURN_REFERRAL_REQUEST,
  OVERRIDE_SIN,
} from '../services/vehicle-referral.gql';
import { getStandardItemForReferral } from '../pages/vehicle-referral/utils/vehicle-referral-util';
import { GET_REQUISITION_SINS_TYPEAHEAD } from '../services/data-layer/standards';

/**
 * Get the minimum requirements for a given standard item
 * and return them grouped by category.
 */
export function useGetMinOptions(standardItemId) {
  const { runQueryNow } = useDispatchQuery();

  let sid = standardItemId;
  if (typeof standardItemId === 'string') {
    sid = parseInt(standardItemId);
  }

  return runQueryNow(GET_MIN_REQ, {
    fetchPolicy: 'no-cache',
    variables: {
      standardItemId: sid,
    },
    transformResult: ({ data }) => {
      const groupedByCategory = {};

      for (let item of data) {
        if (!groupedByCategory[item.equipmentCode.categoryCode.title]) {
          groupedByCategory[item.equipmentCode.categoryCode.title] = [];
        }

        groupedByCategory[item.equipmentCode.categoryCode.title].push(item);
      }

      // sort the keys alphabetically
      const sortedKeys = Object.keys(groupedByCategory).sort();
      const sortedGroupedByCategory = {};

      for (let key of sortedKeys) {
        sortedGroupedByCategory[key] = groupedByCategory[key].sort((a, b) => {
          if (a.equipmentCode.code < b.equipmentCode.code) return -1;
          if (a.equipmentCode.code > b.equipmentCode.code) return 1;
          return 0;
        });
      }

      return sortedGroupedByCategory;
    },
  });
}

/**
 * Get the additional options for a given standard item.
 */
export function useGetAddOptions(standardItemId, options = {}) {
  const { runQueryNow } = useDispatchQuery();

  let sid = standardItemId;
  if (typeof standardItemId === 'string') {
    sid = parseInt(standardItemId);
  }

  return runQueryNow(GET_OPTIONAL_REQ, {
    fetchPolicy: 'no-cache',
    skip: options.skip,
    variables: {
      standardItemId: sid,
    },
    transformResult: ({ data }) => {
      if (options.raw) return data;

      const groupedByCategory = {};

      for (let item of data) {
        if (!groupedByCategory[item.equipmentCode.categoryCode.title]) {
          groupedByCategory[item.equipmentCode.categoryCode.title] = [];
        }

        groupedByCategory[item.equipmentCode.categoryCode.title].push(item);
      }

      // sort the keys alphabetically
      const sortedKeys = Object.keys(groupedByCategory).sort();
      const sortedGroupedByCategory = {};

      for (let key of sortedKeys) {
        sortedGroupedByCategory[key] = groupedByCategory[key].sort((a, b) => {
          if (a.equipmentCode.code < b.equipmentCode.code) return -1;
          if (a.equipmentCode.code > b.equipmentCode.code) return 1;
          return 0;
        });
      }

      return sortedGroupedByCategory;
    },
  });
}

export function useGetStateList() {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(GET_CATALOGS_FOR_CATEGORY, {
    apolloOptions: { fetchPolicy: 'no-cache' },
    transformResult: ({ data }) => {
      const temp = data
        .map(({ displayText, code }) => ({
          label: displayText,
          value: code.split('-')[1],
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      temp.unshift({ label: '-select state-', value: '-1' });
      return temp;
    },
  });
}

export function useGetStates(countryCode) {
  const { runQueryNow } = useDispatchQuery();
  return runQueryNow(GET_CATALOGS_FOR_CATEGORY, {
    skip: !countryCode,
    variables: {
      category: `ISOStateCode-${countryCode}`,
    },
    transformResult: ({ data }) => {
      const temp = data
        .map(({ displayText, code }) => ({
          label: displayText,
          value: code.split('-')[1],
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      temp.unshift({ label: '-select state-', value: '-1' });
      return temp;
    },
  });
}

export function useGetCountries(options = {}) {
  const { runQueryNow } = useDispatchQuery();

  return runQueryNow(GET_CATALOGS_FOR_CATEGORY, {
    variables: { category: 'ISOCountryCode2' },
    transformResult: ({ data }) => {
      const temp = data
        .map(({ description, code }) => ({
          label: description,
          value: code,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      temp.unshift({ label: '-select country-', value: '-1' });
      return temp;
    },
    ...options,
  });
}

/**
 * Get the minimum requirements for a given standard item
 * and return them grouped by category.
 */
export function useGetMinOptionsLazy() {
  const { registerQuery } = useDispatchQuery();

  return registerQuery(GET_MIN_REQ, {
    apolloOptions: { fetchPolicy: 'no-cache' },
    transformResult: ({ data }) => {
      const groupedByCategory = {};

      for (let item of data) {
        if (!groupedByCategory[item.equipmentCode.categoryCode.title]) {
          groupedByCategory[item.equipmentCode.categoryCode.title] = [];
        }

        groupedByCategory[item.equipmentCode.categoryCode.title].push(item);
      }

      // sort the keys alphabetically
      const sortedKeys = Object.keys(groupedByCategory).sort();
      const sortedGroupedByCategory = {};

      for (let key of sortedKeys) {
        sortedGroupedByCategory[key] = groupedByCategory[key].sort((a, b) => {
          if (a.equipmentCode.code < b.equipmentCode.code) return -1;
          if (a.equipmentCode.code > b.equipmentCode.code) return 1;
          return 0;
        });
      }

      return sortedGroupedByCategory;
    },
  });
}

/**
 * Get the additional options for a given standard item.
 */
export function useGetAddOptionsLazy() {
  const { registerQuery } = useDispatchQuery();

  return registerQuery(GET_OPTIONAL_REQ, {
    apolloOptions: { fetchPolicy: 'no-cache' },
    transformResult: ({ data }) => {
      const groupedByCategory = {};

      for (let item of data) {
        if (!groupedByCategory[item.equipmentCode.categoryCode.title]) {
          groupedByCategory[item.equipmentCode.categoryCode.title] = [];
        }

        groupedByCategory[item.equipmentCode.categoryCode.title].push(item);
      }

      // sort the keys alphabetically
      const sortedKeys = Object.keys(groupedByCategory).sort();
      const sortedGroupedByCategory = {};

      for (let key of sortedKeys) {
        sortedGroupedByCategory[key] = groupedByCategory[key].sort((a, b) => {
          if (a.equipmentCode.code < b.equipmentCode.code) return -1;
          if (a.equipmentCode.code > b.equipmentCode.code) return 1;
          return 0;
        });
      }

      return sortedGroupedByCategory;
    },
  });
}

export function useCreateReferralRequest() {
  const { registerMutation } = useDispatchQuery();

  return registerMutation(CREATE_REFERRAL_REQUEST, {
    apolloOptions: { fetchPolicy: 'no-cache' },
  });
}

export function useGetReferralRequest(payload) {
  const { runQuery } = useDispatchQuery();
  return runQuery(GET_REFERRAL_REQUEST, {
    apolloOptions: {
      fetchPolicy: 'no-cache',
      skip: payload?.assetId && !payload?.agencyCode,
    },
    variables: {
      ...payload,
    },
  });
}

export function useGetActiveContracts() {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(GET_ACTIVE_CONTRACTS, {
    apolloOptions: {
      fetchPolicy: 'no-cache',
    },
  });
}

export function useGetDistinctStandardItemsLazy(options = {}) {
  const { registerQuery } = useDispatchQuery();

  return registerQuery(GET_DISTINCT_STANDARD_ITEMS, {
    apolloOptions: {
      fetchPolicy: 'network-only',
      skip: options.skip,
    },
    ...options,
  });
}

export function useGetReferralRequestLazy() {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(GET_REFERRAL_REQUEST, {
    apolloOptions: {
      fetchPolicy: 'no-cache',
    },
  });
}

export function useGenerateReadSignedUrl() {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(GENERATE_READ_SIGNED_URL, {
    fetchPolicy: 'no-cache',
    transformResult: async ({ data }) => {
      if (!data) {
        throw new Error('No signed url found.');
      }
      return data;
    },
  });
}

export function useDocMetaData() {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(GET_DOC_METADATA, {
    fetchPolicy: 'no-cache',
    transformResult: async ({ data }) => {
      if (!data) {
        throw new Error('No signed url found.');
      }
      return data;
    },
  });
}

export function useDocumentManagementAttachments(options = {}) {
  const { runQuery } = useDispatchQuery();
  return runQuery(GET_AGENCY_DOCUMENT_BY_TYPE, {
    fetchPolicy: 'no-cache',
    ...options,
  });
}

export function useGetVehicleReferralList() {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(GET_VEHICLE_REFERRAL_LIST, {
    apolloOptions: {
      fetchPolicy: 'no-cache',
    },
  });
}

export function useGetVehicleByFilter() {
  const { registerQuery } = useDispatchQuery();

  return registerQuery(GET_REFERRAL_VEHICLE_BY_FILTER, {
    apolloOptions: {
      fetchPolicy: 'no-cache',
    },
    transformResult: async ({ data }) => {
      if (!data) {
        throw new Error('No vehicle found. Verify vehicle information.');
      }

      return data;
    },
  });
}
export function useGetVehicleByFilterNow(options = {}) {
  const { runQuery } = useDispatchQuery();

  return runQuery(GET_REFERRAL_VEHICLE_BY_FILTER, {
    apolloOptions: {
      fetchPolicy: 'no-cache',
      skip: options.skip,
    },
    ...options,
  });
}

export function useCreateReferralReplacement() {
  const { registerMutation } = useDispatchQuery();
  return registerMutation(CREATE_REFERRAL_REPLACEMENT);
}

export function useOverrideSIN() {
  const { registerMutation } = useDispatchQuery();
  return registerMutation(OVERRIDE_SIN);
}

export function useGetStandardItemBySin(fiscalYear) {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(GET_STANDARD_ITEM_BY_SIN, {
    apolloOptions: { fetchPolicy: 'no-cache' },
    transformResult: ({ data }) => {
      return getStandardItemForReferral(data, fiscalYear);
    },
  });
}

export function useGetAllStandardItemsBySin() {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(GET_STANDARD_ITEM_BY_SIN, {
    apolloOptions: { fetchPolicy: 'no-cache' },
  });
}

export function useCreateReferralNote() {
  const { registerMutation } = useDispatchQuery();
  return registerMutation(CREATE_REFERRAL_NOTE);
}

export function useGetCustomerFSREmails() {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(GET_CUSTOMER_FSR_EMAILS_BY_PARTIAL_EMAIL, {
    apolloOptions: { fetchPolicy: 'no-cache' },
  });
}

export function useGetCustomerPointOfContact() {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(SEARCH_CUSTOMER_POINTS_OF_CONTACT, {
    apolloOptions: { fetchPolicy: 'no-cache' },
  });
}

export function useGetCustomerAccountTypeAheads() {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(GET_CUSTOMER_ACCOUNT_TYPEAHEADS, {
    apolloOptions: { fetchPolicy: 'no-cache' },
  });
}

export function useGetBoacsByPartialBoac() {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(GET_BOACS_BY_PARTIAL, {
    apolloOptions: { fetchPolicy: 'no-cache' },
  });
}

export function useGetVehiclesByPartialTagOrVin() {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(GET_VEHICLES_BY_PARTIAL_VIN_OR_TAG, {
    apolloOptions: { fetchPolicy: 'no-cache' },
  });
}

export function useGetReplacementAttachmentTypes() {
  const { runQueryNow } = useDispatchQuery();
  return runQueryNow(GET_REPLACEMENT_ATTACHMENT_TYPES, {
    transformResult: ({ data }) => {
      return data.map(({ attachmentType, replacementAttachmentTypeId }) => ({
        label: attachmentType,
        value: replacementAttachmentTypeId,
      }));
    },
  });
}

export function useGenerateStoreReport() {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(GENERATE_STORE_REPORT, {
    apolloOptions: { fetchPolicy: 'no-cache' },
  });
}

export function useCreateMetadataRecord() {
  const { registerMutation } = useDispatchQuery();
  return registerMutation(CREATE_METADATA_RECORD, {
    apolloOptions: { fetchPolicy: 'no-cache' },
  });
}

export function useDeleteMetadataRecord() {
  const { registerMutation } = useDispatchQuery();
  return registerMutation(DELETE_METADATA_RECORD, {
    apolloOptions: { fetchPolicy: 'no-cache' },
  });
}

export function useCreateReferralReplacementAttachment() {
  const { registerMutation } = useDispatchQuery();
  return registerMutation(CREATE_REFERRAL_REPLACEMENT_ATTACHMENT);
}

export function useUpdateReferralReplacementAttachment() {
  const { registerMutation } = useDispatchQuery();
  return registerMutation(UPDATE_REFERRAL_REPLACEMENT_ATTACHMENT);
}

export function useDeleteReferralReplacementAttachment() {
  const { registerMutation } = useDispatchQuery();
  return registerMutation(DELETE_REFERRAL_REPLACEMENT_ATTACHMENT);
}

export function useGetDistinctStandardItems(options = {}) {
  const { runQuery } = useDispatchQuery();

  return runQuery(GET_DISTINCT_STANDARD_ITEMS, {
    apolloOptions: {
      fetchPolicy: 'network-only',
      skip: options.skip,
    },
    ...options,
  });
}

export function useGetDistinctStandardItemsOptions(options = {}) {
  const { runQueryNow } = useDispatchQuery();

  return runQueryNow(GET_DISTINCT_STANDARD_ITEMS, {
    apolloOptions: {
      fetchPolicy: 'network-only',
      skip: options.skip,
    },
    ...options,
    transformResult: ({ data }) => {
      return [
        { label: '-select-', value: '-1' },
        ...data.map((standardItem) => ({
          value: standardItem.standardItemNumber,
          label: standardItem.standardItemNumber + ' - ' + standardItem.title,
        })),
      ];
    },
  });
}

export function useReturnReferralRequest() {
  const { registerMutation } = useDispatchQuery();

  return registerMutation(RETURN_REFERRAL_REQUEST);
}

export function useGetRequisitionSinByTypeahead() {
  const { registerQuery } = useDispatchQuery();
  return registerQuery(GET_REQUISITION_SINS_TYPEAHEAD, {
    apolloOptions: { fetchPolicy: 'no-cache' },
  });
}
