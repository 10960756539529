import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import {
  SUBMIT_REQUISITION,
  SUBMIT_TO_GSA,
} from '../../../../services/data-layer';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import './PageNavigationButtons.scss';
import {
  canApproveRequisitionCart,
  canEngineerReviewRequisitionCart,
} from '../../../../utilities/authorization';
import SubmitBtnLabel from './SubmitBtnLabel';
import useRequisitionReview from '../../../../utilities/useRequisitionReview';
import { useSystemAlert } from '../../../../services/system-alert';
import {
  StoreOperations,
  StoreSubjects,
} from '../../../../constants/constants';
import { UserRoles } from '../../../../constants/user-constants';
import CancelRequisitionButton from '../../../ReviewDetails/CancelRequisitionButton/CancelRequisitionButton';

const PageNavigationButtons = ({
  handlePageNavigation,
  isLastStep,
  hideNextButton,
}) => {
  const { setSystemAlert } = useSystemAlert();
  const { state, dispatch } = useContext(VehicleRequisitionContext);
  const { draftRequisition, submitComment, reviewSubmitTab } = state;
  const navigate = useNavigate();
  const ability = useAppAbility();
  const { currentUser } = useCurrentUser();
  const { isEngineerReviewRequired, isVendorDirect } = useRequisitionReview();
  const canEngineerReviewRequisition =
    canEngineerReviewRequisitionCart(ability);
  const canApproveRequisition = canApproveRequisitionCart(ability);
  const isAdmin = useMemo(
    () => ability?.can(StoreOperations.Manage, StoreSubjects.All),
    [ability],
  );

  const isOrderingAdmin = currentUser?.roles?.some(
    (role) => role.name === UserRoles.ORDERING_ADMIN,
  );

  //  const { createdByInfo } = draftRequisition ;
  let currentUserCreatedRequisition = false;
  if (draftRequisition && draftRequisition.createdByInfo) {
    currentUserCreatedRequisition =
      currentUser.id === draftRequisition.createdByInfo.id;
  } else {
    currentUserCreatedRequisition = true;
  }

  const [submitToGSAMutation] = useMutation(SUBMIT_TO_GSA);
  const [submitRequisitionMutation] = useMutation(SUBMIT_REQUISITION, {
    onCompleted: async ({ submitRequisition }) => {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_SUBMIT_REQUISITION_LOADING,
        payload: false,
      });

      if (submitRequisition?.requisitionCartValidations !== null) {
        dispatch({
          type: VehicleRequisitionContextActions.VALIDATIONS_ERROR_STATE,
          payload: submitRequisition.requisitionCartValidations,
        });
        dispatch({
          type: VehicleRequisitionContextActions.SET_VALIDATIONS_MODAL_OPEN,
          payload: true,
        });
        return navigate(
          `/vehicle-requisition?requisitionId=${draftRequisition.requisitionId}&allowToCancel=1&allowToEdit=1`,
        );
      }

      if (
        !isEngineerReviewRequired &&
        canApproveRequisition &&
        isVendorDirect
      ) {
        await submitToGSAMutation({
          variables: {
            requisitionId: draftRequisition.requisitionId,
            submitComment,
          },
        });
      }

      return navigate(
        `/my-requisitions?requisitionId=${draftRequisition.requisitionId}&draftName=${draftRequisition.friendlyName}`,
      );
    },
    onError: (err) => {
      setSystemAlert({
        errorHint: err.message,
      });
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_SUBMIT_REQUISITION_LOADING,
        payload: false,
      });
    },
  });

  const handleSubmitRequisitionModal = async () => {
    if (draftRequisition?.requisitionId) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_SUBMIT_REQUISITION_LOADING,
        payload: true,
      });

      submitRequisitionMutation({
        variables: {
          requisitionId: draftRequisition.requisitionId,
          submitComment: submitComment || '',
          isVendorDirect: isVendorDirect || false,
        },
      });

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_DRAFT_REQUISITION,
        payload: {
          ...draftRequisition,
          forceReload: true,
        },
      });

      return;
    }

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_SUBMIT_REQUISITION_MODAL,
      payload: true,
    });
  };

  const handleCancelRequisitionModal = async () => {
    dispatch({
      type: VehicleRequisitionContextActions.CANCEL_REQUISITION_MODAL,
      payload: true,
    });
  };

  if (reviewSubmitTab !== 'Review summary') {
    return null;
  }

  return (
    <div className="page-navigation-buttons">
      {currentUserCreatedRequisition && (
        <Button
          variant="outline"
          type="button"
          data-testid="vehicle-requisition_navigation_previous"
          onClick={() => handlePageNavigation('previous')}
          label="Previous"
        />
      )}
      {!isLastStep && currentUserCreatedRequisition && !hideNextButton && (
        <Button
          variant="default"
          type="button"
          data-testid="vehicle-requisition_navigation_continue"
          onClick={() => handlePageNavigation('continue')}
          label=" Next"
        />
      )}
      {isLastStep &&
        (isAdmin ||
          isOrderingAdmin ||
          (!canEngineerReviewRequisition && currentUserCreatedRequisition)) && (
          <>
            <CancelRequisitionButton
              requisitionId={draftRequisition?.requisitionId}
            />
            <Button
              variant="default"
              type="button"
              data-testid="vehicle-requisition_navigation_submit"
              onClick={handleSubmitRequisitionModal}
              label={<SubmitBtnLabel />}
            />
          </>
        )}
    </div>
  );
};

PageNavigationButtons.defaultProps = {
  isLastStep: false,
  hideNextButton: false,
};

PageNavigationButtons.propTypes = {
  handlePageNavigation: PropTypes.func.isRequired,
  isLastStep: PropTypes.bool,
  hideNextButton: PropTypes.bool,
};

export default PageNavigationButtons;
