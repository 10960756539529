import { gql } from '@apollo/client';
import { GET_ORDERS_FOR_USER } from '../services/data-layer';

export const GET_REQUISITIONS = gql`
  query SearchRequisitionExtended(
    $offset: Int
    $limit: Int
    $order: OrderBy!
    $filters: [ExtendedFilteredField!]!
  ) {
    searchRequisitionExtended(
      offset: $offset
      limit: $limit
      order: $order
      filters: [{ operator: "$and", conditions: $filters }]
    ) {
      count
      rows {
        requisitionId
        boac
        createdAt
        updatedAt
        friendlyName
        modelCode
        quantity
        requisitionStatus
        requisitionNumber
        requisitionType
        standardItemCode
        totalSellingPrice
        transactionType
        agencyInfoName
        bureauInfoName
        allowedActions
        officeInfoName
        createdUserInfoFirstName
        createdUserInfoLastName
        createdUserInfoEmail
        assignedEngineerFirstName
        assignedEngineerLastName
        assignedEngineerEmail
        updatedUserInfoFirstName
        updatedUserInfoLastName
        updatedUserInfoEmail
      }
    }
  }
`;

export async function searchRequisition(client, state, query = {}) {
  return client
    .query({
      query: GET_REQUISITIONS,
      variables: {
        offset: query.offset || 0,
        limit: query.limit || 10,
        order: query.order || [['updated_at', 'DESC']],
        filters: query.filters || [],
      },
    })
    .then((response) => {
      // console.log(
      //   'searchRequisition-------->response',
      //   response.data.searchRequisitionExtended,
      // );
      return response.data.searchRequisitionExtended;
    })
    .catch(() => {
      return [];
    });
}

export async function getOrders(client, state) {
  // console.log(state);
  if (!state.dependencyReducer.getRequisitions) {
    return undefined;
  }
  return client
    .query({
      query: GET_ORDERS_FOR_USER,
      variables: {
        query: {
          offset: 0,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'DESC',
          filters: {
            operator: '$and',
            conditions: [
              {
                key: 'order_status',
                operator: '$in',
                value: ['AWARDED', 'IN_PROCESS'],
              },
              {
                key: 'order_status',
                operator: '$in',
                value: ['AWARDED', 'IN_PROCESS'],
              },
            ],
          },
        },
      },
    })
    .then((response) => {
      return response.data.getAllOrdersForUserWithLeasing;
    })
    .catch(() => {
      return [];
    });
}
