import React, { useEffect, useMemo, useState } from 'react';
import {
  AFPTable,
  AFPTableRowAction,
  Spinner,
  Pagination,
  Button,
  EmptyState,
} from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import Moment from 'moment';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import OrderVehicleStatusTag from './order-vehicle-status-tag';
import { UPDATE_ORDER_LINE_VEHICLE } from '../../../services/data-layer';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, OrderVehicleStatus } from '../constants/order-vehicle-constants';
import UpdateVehicleModal from '../../../components/UpdateVehicleModal/UpdateVehicleModal';
import { UserRoles } from '../../../constants/index.jsx';
import { StoreOperations, StoreSubjects } from '../../../constants/constants';
import { getOrderLines } from '../../../requests/order';
import { useOrderVehicleFilter } from '../providers/order-vehicle-filter-provider.jsx';

const OrderVehicleTable = () => {
  const dispatch = useDispatch();
  const [showUpdateVehicle, setShowUpdateVehicle] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [errorUpdating, errorUpdatingTo] = useState('');
  const [orderVehicles, setOrderVehicles] = useState('');
  const { filters } = useOrderVehicleFilter();
  const [sort, setSort] = useState([['accountingLineNumber', 'ASC']]);
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
    isReset: false,
  });

  const { orderDetails, vehicles } = useSelector((state) => state.orderReducer);
  const orderVehiclesData = useMemo(() => {
    if (vehicles?.orderVehiclesData) {
      return vehicles?.orderVehiclesData;
    }
    return [];
  }, [vehicles]);

  const loading = vehicles?.isVehiclesDataLoading;

  const { currentUser } = useCurrentUser();
  const ability = useAppAbility();
  const canUpdateOrderVehicle = useMemo(
    () =>
      ability?.can(StoreOperations.Manage, StoreSubjects.All) ||
      ability?.can(StoreOperations.updateStatus, StoreSubjects.OrderVehicle),
    [ability],
  );
  const isVehicleSupplier = useMemo(
    () =>
      currentUser?.roles?.some(
        (role) => role.name === UserRoles.VEHICLE_SUPPLIER,
      ),
    [currentUser],
  );
  const actionList = ({original}) => {
    const isAgencyReceived = original?.statusCode === OrderVehicleStatus.AR;
    return (isVehicleSupplier || canUpdateOrderVehicle) && !isAgencyReceived
      ? [
        {
          icon: 'edit',
          label: 'Update Vehicle Status',
        },
      ]
      : [];
  }
  const [updateOrderLine] = useMutation(UPDATE_ORDER_LINE_VEHICLE, {
    onError: (error) => {
      errorUpdatingTo(error.message);
    },
    onCompleted: () => {
      errorUpdatingTo('');
      setShowUpdateVehicle(false);
      dispatch(
        getOrderLines({
          orderId: orderDetails?.orderId,
          limit: 10,
          offset: 0,
          currentPage: 1,
          filters: filters,
        }),
      );
      /*      getOrderLinesForOrder({
                    fetchPolicy: 'no-cache',
                    variables: {
                      limit,
                      offset,
                      order: sort,
                      filters,
                    },
                  });*/
    },
  });

  useEffect(() => {
    if (orderVehiclesData) {
      setOrderVehicles(orderVehiclesData);
    }
  }, [orderVehiclesData]);

  // FIXME: Filter is mutating when page is selected so page always resets to page 0
  // useEffect(() => {
  //   setPaginationState({
  //     ...paginationState,
  //     offset: 0,
  //     currentPage: 1,
  //     isReset: true,
  //   });
  // }, [filters]);

  const estimatedShipmentDate = () => {
    const estimatedDeliverDays =
      orderDetails?.baseShipmentDays ??
      0 + orderDetails?.additionalShipmentDays ??
      0;

    const orderAwardedDate =
      orderDetails?.submittedToVendorAt ?? orderDetails?.createdAt;

    const formattedDate = Moment(orderAwardedDate).format('MM/DD/YYYY');

    const getContractShipmentDate = Moment(formattedDate)
      .add(estimatedDeliverDays, 'days')
      .format('MM/DD/YYYY');

    if (!getContractShipmentDate) {
      return <>&#8211;</>;
    }

    return getContractShipmentDate;
  };
  const handleSaveVehicle = (orderVehicle) => {
    errorUpdatingTo('');
    updateOrderLine({
      variables: {
        orderVehicleId: orderVehicle.orderVehicleId,
        data: {
          vehicleOrderNumber: orderVehicle.vehicleOrderNumber,
          serialNumberVin: orderVehicle.serialNumberVin?.trim()
            ? orderVehicle.serialNumberVin?.trim()?.toUpperCase()
            : null,
          statusCode: orderVehicle.statusCode ? orderVehicle.statusCode : 'SP',
          shipmentDate: orderVehicle.shipmentDate?.trim()
            ? orderVehicle.shipmentDate
            : null,
          deliveryDate: orderVehicle.deliveryDate?.trim()
            ? orderVehicle.deliveryDate
            : null,
        },
      },
    });
  };

  const handleSelectedRow = (vehicle) => {
    setSelectedVehicle({
      ...vehicle,
      estimatedShipmentDate: estimatedShipmentDate(),
      rpnNumber: orderVehicles?.rpnNumber,
    });
    errorUpdatingTo('');
    setShowUpdateVehicle(true);
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Vehicle line number',
        accessor: 'accountingLineNumber',
        // eslint-disable-next-line react/prop-types
        Cell: (cell) => {
          const { value } = cell;
          return value;
        },
      },
      {
        Header: 'VON',
        accessor: 'vehicleOrderNumber',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: (cell) => {
          const { value } = cell;
          return value ?? '-';
        },
      },
      {
        Header: 'VIN',
        accessor: 'serialNumberVin',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: (cell) => {
          const { value } = cell;
          return value ? <Button variant="unstyled" label={value} /> : '-';
        },
      },
      {
        Header: 'Vehicle status',
        accessor: 'statusCode',
        Cell: (cell) => {
          const { value } = cell;
          return <OrderVehicleStatusTag value={value} />;
        },
      },
      {
        Header: 'Shipment date',
        accessor: 'shipmentDate',
        Cell: ({ value }) => formatDate(value) || '-',
      },
      {
        Header: 'Delivery date',
        accessor: 'deliveryDate',
        Cell: ({ value }) => formatDate(value) || '-',
      },
      {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => (
          <AFPTableRowAction
            actions={actionList(props.row)}
            // eslint-disable-next-line
            onSelectAction={() => handleSelectedRow(props.row)}
            {...props}
          />
        ),
      },
    ],
    [],
  );

  const setTableSort = (newSort) => {
    const cleanSort = newSort.replace(/`([^`]+)`/, '$1');
    setSort([cleanSort.split(' ')]);
    dispatch(
      getOrderLines({
        orderId: orderDetails?.orderId,
        ...paginationState,
        order: [cleanSort.split(' ')],
        filters: { conditions: vehicles?.filters, operator: '$and' },
      }),
    );
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    dispatch(
      getOrderLines({
        orderId: orderDetails?.orderId,
        limit: itemsPerPage,
        offset,
        currentPage,
        order: sort,
        filters: { conditions: vehicles?.filters, operator: '$and' },
      }),
    );
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const handleCancelUpdate = () => {
    setShowUpdateVehicle(false);
  };

  return (
    <>
      <AFPTable
        testId="order-vehicle-table"
        columns={columns}
        data={orderVehicles?.rows || []}
        onSort={setTableSort}
        defaultSort={sort}
      />
      {loading && <Spinner aria-busy="true" size="medium" />}
      {!orderVehicles?.rows?.length && (
        <EmptyState
          hasBackground
          containerStyles="margin-top-neg-2 padding-y-10"
          topText="No results found for your selected filters"
        />
      )}

      <Pagination
        variant="advanced"
        itemsCount={orderVehicles?.count}
        itemsPerPage={paginationState.limit}
        currentPage={paginationState.currentPage}
        isReset={paginationState.isReset}
        onPageChange={handlePaginationChange}
      />
      <UpdateVehicleModal
        data-testid="update-vehicle-modal"
        isOpen={showUpdateVehicle}
        orderId={orderDetails?.orderId}
        selectedVehicle={selectedVehicle}
        onSubmit={handleSaveVehicle}
        onCancel={handleCancelUpdate}
        errorUpdating={errorUpdating}
      />
    </>
  );
};

export default OrderVehicleTable;
