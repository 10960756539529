import React, { useEffect, useRef } from 'react';
import { emDashUnicode } from '@gsa/afp-shared-form-utils';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { Button, useModal, Spinner } from '@gsa/afp-component-library';
import './selected-vehicle.scss';
import { useSelector } from 'react-redux';
import { OverrideSinModal } from '../../replace-vehicle/steps/review-submit/override-sin-modal';
import { VEHICLE_REFERRAL_STATUS } from '../../utils/vehicle-referral-util';
import {
  useOverrideSIN,
  useCreateMetadataRecord,
  useCreateReferralReplacementAttachment,
  useGetReferralRequestLazy,
  useGetStandardItemBySin,
} from '../../../../requests/vehicle-referral';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import {
  StoreOperations,
  StoreSubjects,
} from '../../../../constants/constants';
import axios from 'axios';
import {
  setReplacementAttachments,
  setSelectedStandardItem,
  setSinNotAvailable,
  setVehicleReferralActivities,
} from '../../../../reducers/vehicle-referral';
import { useDispatch } from 'react-redux';
import { UserTypes } from '../../../../constants';
import { v4 } from 'uuid';

export const AddressModifier = () => (value) => {
  const lines = [
    value?.address1,
    value?.address2,
    [[value?.city, value?.state].filter(Boolean).join(', '), value?.zip]
      .filter(Boolean)
      .join(' '),
    value?.country,
  ].filter(Boolean);

  return (
    <div>
      {lines.length === 0
        ? emDashUnicode
        : lines.map((line, index) => <div key={index}>{line}</div>)}
    </div>
  );
};

export const WeightModifier = (unit) => (value) => {
  let valueAsNumber = parseFloat(value);
  if (isNaN(valueAsNumber)) return emDashUnicode;
  return value
    ? `${valueAsNumber.toLocaleString()} ${unit || 'lbs'}`
    : emDashUnicode;
};

export const SelectedSinModifier = () => (value) => {
  return value
    ? [value.standardItemNumber, value.title].filter(Boolean).join(' - ')
    : emDashUnicode;
};

export default function SelectedVehicle() {
  const {
    garageAddress,
    selectedStandardItem,
    projectedTowingWeight,
    usedForTowing,
    referralRequestId,
    referralReplacementId,
    vehicleDetails,
    status,
    requisitionNumber,
    referralFiscalYear,
  } = useSelector((state) => state.vehicleReferralReducer);

  const ability = useAppAbility();
  const canManageReferrals = ability.can(
    StoreOperations.Manage,
    StoreSubjects.VehicleReferral,
  );

  const { currentUser } = useCurrentUser();
  const isGSAEmployee = currentUser?.userType?.id === UserTypes.GSA_EMPLOYEE;

  const [overrideSin, { loading: overrideSinLoading }] = useOverrideSIN();
  const [createMetadataRecord] = useCreateMetadataRecord();
  const [createReferralReplacementAttachment] =
    useCreateReferralReplacementAttachment();
  const [getReferralRequest] = useGetReferralRequestLazy();
  const [getStandardItemBySin] = useGetStandardItemBySin(referralFiscalYear);
  const overrideSinModal = useModal();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  if (!selectedStandardItem) return null;

  const data = {
    selectedStandardItem,
    garageAddress,
    projectedTowingWeight: usedForTowing ? projectedTowingWeight?.value : null,
    requisitionNumber,
  };

  useEffect(() => {
    formRef?.current?.setValue(
      'selectedStandardItem',
      `${selectedStandardItem?.standardItemNumber} - ${selectedStandardItem?.title}`,
    );
  }, [selectedStandardItem]);

  useEffect(() => {
    formRef?.current?.setValue('requisitionNumber', requisitionNumber);
  }, [requisitionNumber]);

  const handleOverrideSin = async (request) => {
    const payload = {
      referralRequestId,
      newSin: request?.sins,
      comment: request?.comment,
    };

    overrideSin({
      variables: {
        input: payload,
      },
    })
      .then(async () => {
        overrideSinModal.closeModal();

        await handleUpload(request);

        const { data: referralRequest } = await getReferralRequest({
          variables: {
            assetId: vehicleDetails.id,
            agencyCode: vehicleDetails?.customer?.customerAgency?.id,
            bureauCode: vehicleDetails?.customer?.customerBureau?.id,
            officeCode:
              vehicleDetails?.customer?.customerPhysicalOffice?.officeCode,
          },
        });

        if (referralRequest) {
          const { data: standardItem } = await getStandardItemBySin({
            variables: {
              filter: {
                key: 'standardItemNumber',
                operator: 'EQ',
                value: request?.sins,
              },
            },
          });

          const tempStandardItem = { ...standardItem };
          tempStandardItem.id = parseInt(standardItem.id);
          dispatch(setSelectedStandardItem(tempStandardItem));
        }
        dispatch(
          setReplacementAttachments(
            referralRequest.referralReplacement.referralReplacementAttachments.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
            ),
          ),
        );
        const activities = referralRequest.referralRequestActivities;
        if (activities?.length) {
          dispatch(
            setVehicleReferralActivities(
              activities.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
              ),
            ),
          );
        }
        dispatch(setSinNotAvailable(false));
      })
      .catch((error) => {
        console.error('Error overriding Sin', error);
      });
  };

  const handleUpload = async (data) => {
    const metadataInput = {
      name: data.file.name,
      size: data.file.size,
      fileMimeType: data.file.type,
      description: data.comment,
      fileLocation: `store/referral/replacement/${v4()}`,
      linkedEntities: {
        referralRequestId,
      },
      docSource: 'UserUploaded',
      docStore: window.AFP_CONFIG.attachment_bucket,
    };

    let metadata = null;
    try {
      const metadataRecord = await createMetadataRecord({
        variables: { input: metadataInput },
      });

      metadata = metadataRecord.data;
    } catch ({ error }) {
      throw error;
    }

    await axios.put(metadata.signedUrl, data.file, {
      headers: {
        'Content-Type': data.file.type,
        'Content-Disposition': `attachment; filename="${data.file.name}"`,
      },
    });

    try {
      await createReferralReplacementAttachment({
        variables: {
          input: {
            name: data.file.name,
            metadataId: metadata.id,
            referralReplacementId,
            replacementAttachmentTypeId: '3',
            description: data.comment,
          },
        },
      });
    } catch ({ error }) {
      throw error;
    }
  };

  const selectedVehicleContent = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    className: 'selected-vehicle',
    sections: [
      {
        altStyle: 'alt-blue-no-hover',
        gap: '30px',
        sections: [
          requisitionNumber
            ? {
                fields: [
                  {
                    id: 'requisitionNumber',
                    type: 'readonly',
                    label: 'Requisition number',
                  },
                ],
              }
            : null,
          {
            fieldLayout: 'horizontal',
            flex: ['flex-justify'],
            fields: [
              {
                id: 'selectedStandardItem',
                label: 'Selected SIN',
                type: 'readonly',
                element: {
                  label: {
                    style: {
                      marginBottom: 0,
                    },
                  },
                  control: {
                    style: {
                      marginTop: 0,
                      fontWeight: 700,
                      fontSize: '21px',
                      textTransform: 'uppercase',
                      lineHeight: '28.35px',
                      color: '#1A4480',
                    },
                    rawModifierValue: true,
                    modifier: SelectedSinModifier(),
                  },
                },
              },
              canManageReferrals &&
              status !== VEHICLE_REFERRAL_STATUS.REQUISITION_CREATED
                ? {
                    component: () => {
                      return (
                        isGSAEmployee &&
                        VEHICLE_REFERRAL_STATUS.APPROVED_AT_FSR.includes(
                          status,
                        ) && (
                          <Button
                            variant="outline"
                            onClick={overrideSinModal.openModal}
                            label="Override SIN"
                          />
                        )
                      );
                    },
                  }
                : null,
            ],
          },
          {
            fieldLayout: 'horizontal',
            fields: [
              {
                id: 'garageAddress',
                columnWidth: 'col-3-12',
                label: 'Garage address',
                type: 'readonly',
                element: {
                  label: {
                    style: {
                      marginBottom: 0,
                    },
                  },
                  control: {
                    style: {
                      marginTop: 0,
                    },
                    rawModifierValue: true,
                    modifier: AddressModifier(),
                  },
                },
              },
              {
                id: 'projectedTowingWeight',
                label: 'Projected towing weight',
                type: 'readonly',
                columnWidth: 'col-3-12',
                element: {
                  label: {
                    style: {
                      marginBottom: 0,
                    },
                  },
                  control: {
                    style: {
                      marginTop: 0,
                    },
                    rawModifierValue: true,
                    modifier: WeightModifier(projectedTowingWeight?.unit),
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  };

  return (
    <>
      <FormGenerator
        ref={formRef}
        schema={{}}
        content={selectedVehicleContent}
        defaultValues={data}
      />

      <OverrideSinModal
        isOpen={overrideSinModal.isOpen}
        handleSubmit={handleOverrideSin}
        handleClose={overrideSinModal.closeModal}
        overrideSinLoading={overrideSinLoading}
      />
    </>
  );
}
