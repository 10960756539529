import { createSlice } from '@reduxjs/toolkit';

const costSummaryInitialState = {
  _source: '',
  areqPriceWithFee: 0,
  baseAndOptionsPrice: 0,
  basePrice: 0,
  perOrderOptionsPrice: 0,
  perVehicleColorPrice: 0,
  perVehicleOptionsPrice: 0,
  purchaseRate: 0,
  quantity: 0,
  totalSellingPrice: 0,
};

const purchaseInitialState = {
  costSummary: costSummaryInitialState,
};

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState: purchaseInitialState,
  reducers: {
    resetCostSummary: (state) => {
      return { ...state, costSummary: costSummaryInitialState };
    },
    setCostSummary: (state, action) => {
      return { ...state, costSummary: action.payload };
    },
  },
});

export const { resetCostSummary, setCostSummary } = purchaseSlice.actions;
export default purchaseSlice.reducer;
