import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Alert,
  TextInput,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import LeasingNavigation from '../../../components/navigation';
import useLeasingNavigation from '@/hooks/useLeasingNavigation';
import useUpdateDraft from '../../../components/update-draft/update-leasing-draft';
import { LeasingSteps } from '../../../leasing-consts';
import { setNonLowBidJustification } from '../../../../../reducers/leasing';
import './low-bid-reason.scss';

const justificationMsg = {
  exceededLengthAlert:
    'Text you have entered is more than 800 characters, Please check',
  standardAlertMsg:
    'You are required to provide justification for this non-low price selection below before submitting your order.',
};

const NonLowBidReason = () => {
  const [updateDraft] = useUpdateDraft();
  const dispatch = useDispatch();
  const { handleLeasingNavigation } = useLeasingNavigation();
  const { nonLowBidJustification } = useSelector(
    (state) => state.leasingReducer,
  );

  const [error, setError] = useState();
  const [nonLowBidReason, setNonLowBidReason] = useState(
    nonLowBidJustification,
  );

  const validateInput = (value) => {
    setError(!value?.length);
  };

  const handleSubmit = async () => {
    if (
      !nonLowBidReason ||
      nonLowBidReason.length === 0 ||
      nonLowBidReason.length > 800
    ) {
      setError(true);
      return;
    }
    if (!error) {
      const input = {
        clientData: {
          clientState: {
            nonLowBidJustification: nonLowBidReason,
          },
        },
      };
      dispatch(setNonLowBidJustification(nonLowBidReason));
      updateDraft(input, LeasingSteps.PAINT_AND_GRAPHICS).then((data) => {
        if (data) {
          handleLeasingNavigation(LeasingSteps.PAINT_AND_GRAPHICS);
        }
      });
    }
  };

  const handlePrevious = () => {
    handleLeasingNavigation(LeasingSteps.COMPARE_AND_SELECT);
  };
  return (
    <>
      <div>
        <h2 data-testid="justification-main-title" className="usa-h2 title-1">
          Provide justification for your non-low price selection
        </h2>
        <span className="title-2">
          You have selected a non-low price vehicle.{' '}
        </span>
        <span className="title-3">
          In accordance with the Federal Acquisition Regulation Part 16.505, you
          must provide a written justification when the lowest priced vehicle as
          equipped is not selected. Your best value justification will be
          included in the order file and is subject to Inspector General review.
          You may also upload relevant supporting documents for your
          justification.
        </span>
      </div>

      <div className="text-editor-container">
        {error && (
          <Alert
            data-testid="vehicle-requisition-color-selection__alert"
            type="error"
            slim="true"
          >
            <span>
              {error && (!nonLowBidReason || nonLowBidReason?.length === 0) 
                ? justificationMsg.standardAlertMsg
                : justificationMsg.exceededLengthAlert}
            </span>
          </Alert>
        )}

        <TextInput
          type="textarea"
          label={
            <div className="justification-info">
              Provide justification for your non-low price selection{' '}
              <RequiredFieldIndicator />
            </div>
          }
          data-testid="nonLowBidJustification"
          onChange={(e) => {
            setNonLowBidReason(e.target.value);
            validateInput(e.target.value);
          }}
          name="nonLowBidJustification"
          value={nonLowBidReason}
          characterLimit={800}
          inputClass="non-low-bid-text-area"
        />
      </div>
      <LeasingNavigation
        onNext={handleSubmit}
        onPrevious={handlePrevious}
        hide={['cancel', 'submit', 'reject']}
      />
    </>
  );
};

export default NonLowBidReason;
