export const getAreqCustomerResponsePayload = (
  formData,
  vendorIdStr,
  requisitionId,
  selectedContract,
) => {
  const fundingDocuments =
    formData?.fundingDocs?.map(
      ({ description, metadataId, name, signedUrl }) => ({
        description,
        metadataId,
        name,
        signedUrl,
      }),
    ) || [];

  const color = {
    makeColorCode: formData?.areqVehicleColor,
    makeColorName: formData?.areqVehicleColorName,
    makeColorPriceToCustomer: formData?.makeColorPriceToCustomer,
    makeColorPriceToGsa: formData?.makeColorPriceToGsa,
  };

  return {
    requisitionId,
    vendorQuoteId: Number(vendorIdStr),
    justification: formData?.lowBidJustification || '',
    fundingDocuments,
    contractLineId: +selectedContract?.contractLineId,
    color,
  };
};
