import {
  Alert,
  Button,
  FilterPanel,
  Spinner,
} from '@gsa/afp-component-library';
import React, { memo, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreOperations, VMSSubjects } from '../../constants/constants';
import {
  setVehicleReferralFilters,
  setPaginationState,
} from '../../reducers/vehicle-referral';
import {
  useGenerateStoreReport,
  useGetCustomerFSREmails,
} from '../../requests/vehicle-referral';
import FMCFilterItem from '../my-orders/Filters/FMCFilterItem';
import LeasingAgencyFilterItem from '../my-orders/Filters/LeasingAgencyFilterItem';
import LeasingBureauFilterItem from '../my-orders/Filters/LeasingBureauFilterItem';
import LeasingOfficeFilterItem from '../my-orders/Filters/LeasingOfficeFilterItem';
import ZonesFilterItem from '../my-orders/Filters/ZonesFilterItem';
import {
  ReferralAction,
  ReferralRequestStatusFilters,
  VEHICLE_REFERRAL_FILTERS,
  VEHICLE_REFERRAL_REPORT,
} from './utils/vehicle-referral-util';
import StandardItemFilterItem from '../../filters/StandardItemFilterItem';
import { useSystemAlert } from '../../services/system-alert';

const VehicleReferralFilters = () => {
  const dispatch = useDispatch();
  const [getCustomerFSREmailsByPartialEmail] = useGetCustomerFSREmails();
  const [generateStoreReport, { loading: isReportLoading }] =
    useGenerateStoreReport();
  const { vehicleReferralFilters, paginationState } = useSelector(
    (state) => state.vehicleReferralReducer,
  );
  const { setSystemAlert, clearSystemAlert } = useSystemAlert();
  const [searchFilters, setSearchFilters] = useState([]);
  const [typeaheadData, setTypeaheadData] = useState([]);
  const [showFilters, setShowFilters] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (vehicleReferralFilters.length > 0) {
      setSearchFilters(vehicleReferralFilters);
      dispatch(setVehicleReferralFilters(vehicleReferralFilters));
    }
  }, []);

  useEffect(() => {
    if (error) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [error]);

  const ReferralAgencyFilterItem = memo(({ filter }) => {
    return (
      <>
        <LeasingAgencyFilterItem
          filter={filter}
          operation={StoreOperations.View}
          subject={VMSSubjects.VEHICLE}
          placeholder="-Select-"
          defaultValueSelected
        />
      </>
    );
  });

  const ReferralBureauFilterItem = memo(({ filter }) => {
    return (
      <div className="margin-top-neg-3">
        <LeasingBureauFilterItem
          filter={filter}
          operation={StoreOperations.View}
          subject={VMSSubjects.VEHICLE}
          agencyFilterPath={VEHICLE_REFERRAL_FILTERS.AGENCY}
          bureauFilterPath={VEHICLE_REFERRAL_FILTERS.BUREAU}
          type="select"
        />
      </div>
    );
  });

  // eslint-disable-next-line no-unused-vars
  const ReferralOfficeFilterItem = memo(({ filter }) => {
    return (
      <div className="margin-top-neg-3">
        <LeasingOfficeFilterItem
          filter={filter}
          operation={StoreOperations.View}
          subject={VMSSubjects.VEHICLE}
          agencyFilterPath={VEHICLE_REFERRAL_FILTERS.AGENCY}
          bureauFilterPath={VEHICLE_REFERRAL_FILTERS.BUREAU}
          officeFilterPath={VEHICLE_REFERRAL_FILTERS.OFFICE}
          type="select"
        />
      </div>
    );
  });

  const NewStandardItemFilterItem = memo(({ filter }) => {
    return <StandardItemFilterItem filter={filter} />;
  });

  const vehicleReferralFilterStructure = useMemo(() => {
    return [
      {
        key: 'titles',
        filters: [
          {
            key: 'leasingOrganization',
            type: 'custom',
            component: () => (
              <div className="text-primary title-m border-bottom">
                Leasing organization
              </div>
            ),
          },
          {
            key: 'leasingAccount',
            type: 'custom',
            component: () => (
              <div className="text-primary title-m border-bottom">
                Leasing account
              </div>
            ),
          },
          {
            key: 'vehicle',
            type: 'custom',
            component: () => (
              <div className="text-primary title-m border-bottom">Vehicle</div>
            ),
          },
          {
            key: 'requestManagement',
            type: 'custom',
            component: () => (
              <div className="text-primary title-m border-bottom margin-bottom-3">
                Request management
              </div>
            ),
          },
        ],
      },
      {
        key: 'agency-fsr-section',
        filters: [
          {
            key: VEHICLE_REFERRAL_FILTERS.AGENCY,
            title: 'Leasing agency',
            component: ReferralAgencyFilterItem,
            label: (
              <div className="margin-bottom-1 text-bold">Leasing agency</div>
            ),
            permanent: false,
            operator: '$exact',
            value: '',
            placeholder: '-Select-',
          },
          {
            key: VEHICLE_REFERRAL_FILTERS.FSR_EMAIL,
            permanent: false,
            operator: '$exact',
            placeholder: 'All',
            label: 'Fleet service representative email',
            value: '',
            type: 'typeahead',
            id: 'placeholder_fsr_email',
            ariaLabel: 'FSR email',
            customFieldProps: {
              inputCharNum: 3,
              promptText: 'Search requires 3 characters',
              debounceDelay: 500,
              showNoResults: true,
              clearPanelFilterOnEmpty: true,
            },
          },
          {
            key: VEHICLE_REFERRAL_FILTERS.TAG,
            permanent: false,
            operator: '$exact',
            prefixIcon: '',
            label: (
              <div className="margin-bottom-1 text-bold">
                License plate number
              </div>
            ),
            type: 'text',
            id: 'placeholder_license_plate',
          },
          {
            key: VEHICLE_REFERRAL_FILTERS.ACTION,
            type: 'select',
            label: (
              <div className="margin-bottom-1 text-bold">Selection type</div>
            ),
            permanent: false,
            operator: '$exact',
            options: [
              {
                label: '-Select-',
                value: '',
                defaultValue: true,
              },
              ...Object.entries(ReferralAction).map(([value, label]) => ({
                label,
                value,
              })),
            ],
            value: '',
          },
        ],
      },
      {
        key: 'bureau-zone-vin',
        filters: [
          {
            key: VEHICLE_REFERRAL_FILTERS.BUREAU,
            label: (
              <div className="margin-bottom-4 text-bold">Leasing bureau</div>
            ),
            component: ReferralBureauFilterItem,
            permanent: false,
            operator: '$exact',
            value: '',
            placeholder: '-Select-',
            customFieldProps: {
              wrapperClass: 'margin-top-3',
            },
          },
          {
            key: VEHICLE_REFERRAL_FILTERS.ZONE,
            label: <div className="margin-bottom-1 text-bold">Zone</div>,
            component: ZonesFilterItem,
            permanent: false,
            operator: '$exact',
            value: '',
            placeholder: '-Select-',
            customFieldProps: {
              wrapperClass: 'margin-top-3',
            },
          },
          {
            key: VEHICLE_REFERRAL_FILTERS.VIN,
            permanent: false,
            operator: '$exact',
            prefixIcon: '',
            label: <div className="margin-bottom-1 text-bold">VIN</div>,
            type: 'text',
            id: 'placeholder_vin',
            customFieldProps: {
              wrapperClass: 'margin-top-3',
            },
          },
          {
            key: VEHICLE_REFERRAL_FILTERS.REFERRAL_STATUS,
            label: (
              <div className="margin-bottom-1 text-bold">Request status</div>
            ),
            type: 'select',
            permanent: false,
            operator: '$exact',
            options: [
              {
                label: '-Select-',
                value: '',
                defaultValue: true,
              },
              ...Object.entries(ReferralRequestStatusFilters).map(
                ([value, label]) => ({
                  label,
                  value,
                }),
              ),
            ],
            value: '',
            customFieldProps: {
              wrapperClass: 'margin-top-3',
            },
          },
        ],
      },
      {
        key: 'filter-customer-account',
        filters: [
          {
            key: VEHICLE_REFERRAL_FILTERS.BOAC,
            permanent: false,
            operator: '$exact',
            label: <div className="margin-bottom-1 text-bold">BOAC</div>,
            prefixIcon: '',
            type: 'text',
            id: 'placeholder_customer_boac',
            customFieldProps: {
              wrapperClass: 'margin-top-3',
            },
          },
          {
            key: VEHICLE_REFERRAL_FILTERS.FMC,
            label: (
              <div className="margin-bottom-1 text-bold">
                Fleet management center
              </div>
            ),
            component: FMCFilterItem,
            permanent: false,
            operator: '$exact',
            placeholder: '-Select-',
            customFieldProps: {
              wrapperClass: 'margin-top-3',
            },
          },
          {
            key: VEHICLE_REFERRAL_FILTERS.OLD_SIN,
            permanent: false,
            label: <div className="margin-bottom-1 text-bold">Old SIN</div>,
            component: StandardItemFilterItem,
            operator: '$exact',
            value: '',
            placeholder: '-Select-',
            customFieldProps: {
              wrapperClass: 'margin-top-3',
            },
          },
        ],
      },
      {
        key: 'filter-boac',
        filters: [
          {
            key: VEHICLE_REFERRAL_FILTERS.POC_EMAIL,
            permanent: false,
            operator: '$exact',
            label: (
              <div className="margin-bottom-1 text-bold">
                Customer POC email
              </div>
            ),
            type: 'text',
            id: 'placeholder_agency',
            ariaLabel: 'Customer POC email',
            customFieldProps: {
              wrapperClass: 'margin-top-3',
            },
          },
          {
            key: VEHICLE_REFERRAL_FILTERS.LEGACY_CUSTOMER_NUMBER,
            permanent: false,
            operator: '$exact',
            label: (
              <div className="margin-bottom-1 text-bold">
                Legacy customer number
              </div>
            ),
            prefixIcon: '',
            type: 'text',
            id: 'placeholder_customer_number',
            customFieldProps: {
              wrapperClass: 'margin-top-3',
            },
          },
          {
            key: VEHICLE_REFERRAL_FILTERS.NEW_SIN,
            permanent: false,
            label: <div className="margin-bottom-1 text-bold">New SIN</div>,
            component: NewStandardItemFilterItem,
            operator: '$exact',
            value: '',
            placeholder: '-Select-',
            customFieldProps: {
              wrapperClass: 'margin-top-3',
            },
          },
        ],
      },
      {
        key: 'customer-poc-number',
        filters: [
          {
            // POC EMAIL placeholder after office is added
          },
          {
            key: VEHICLE_REFERRAL_FILTERS.CUSTOMER_NUMBER,
            permanent: false,
            operator: '$exact',
            label: (
              <div className="margin-bottom-1 text-bold">
                Customer account number
              </div>
            ),
            prefixIcon: '',
            type: 'text',
            id: 'placeholder_customer_number',
            customFieldProps: {
              wrapperClass: 'margin-top-3',
            },
          },
        ],
      },
    ];
  }, []);

  const handleTypeaheadSearch = ({ variables }) => {
    const { conditions } = variables.filters[0];
    const query = conditions.filter((c) => c.key === variables.field)[0]?.value;
    const fieldName = variables.field;

    if (fieldName === VEHICLE_REFERRAL_FILTERS.FSR_EMAIL) {
      getCustomerFSREmailsByPartialEmail({
        variables: {
          limit: 25,
          fsrUserEmail: query,
        },
      }).then(({ data }) => {
        const fsrEmails = data?.map((a) => a.fsrUserEmail);
        setTypeaheadData({
          field: fieldName,
          values: fsrEmails,
        });
      });
    }
  };

  const clearTypeaheadData = () => {
    setTypeaheadData({
      field: '',
      values: [],
    });
  };

  const handleExportSearchResults = async () => {
    if (!searchFilters || searchFilters.length === 0) return;
    const queryParams = searchFilters.reduce((acc, { key, value }) => {
      acc[key] = acc[key] ? `${acc[key]},${value}` : value;
      return acc;
    }, {});
    clearSystemAlert();
    await generateStoreReport({
      variables: {
        input: {
          outputType: 'XLSX',
          reportType: VEHICLE_REFERRAL_REPORT,
          delivery: 'EMAIL',
          queryParams: queryParams,
        },
      },
    }).then((response) => {
      setSystemAlert({
        type: 'success',
        heading: 'Export Initiated',
        focused: true,
        showClose: true,
        message: (
          <div>
            The report you&apos;ve created is now being processed for export and
            will be emailed to you upon completion. Depending on the file size,
            this may take up to 15 minutes or more. If you experience technical
            difficulties exporting, please contact the GSA Fleet Technical
            Support team at fleet.helpdesk@gsa.gov or 866-472-6711 from 8:00
            a.m. - 7:00 p.m. ET, Monday-Friday.
          </div>
        ),
      });
    });
  };

  return (
    <>
      {error && <Alert type="error">{error}</Alert>}
      <div className="margin-top-5">
        <div className="bg-primary-lightest padding-4">
          <div className="display-flex flex-justify margin-top-1">
            {
              <div className="margin-top-neg-05">
                <div className="font-family-sans font-heading-xl text-normal text-primary">
                  Search vehicles
                </div>
                <div className="margin-top-1 text-ink text-normal">
                  Apply at least one filter to see results
                </div>
              </div>
            }
            <div className="margin-left-auto margin-top-1 margin-right-neg-1">
              <Button
                label={showFilters ? 'Hide filters' : 'Show filters'}
                variant="outline"
                size="small"
                leftIcon={{
                  name: 'filter_alt',
                }}
                onClick={() => setShowFilters(!showFilters)}
                data-testid="toggle-filters-button"
              />
            </div>
          </div>
          <div className={!showFilters && 'display-none'}>
            <FilterPanel.FilterPanel
              filterStructure={vehicleReferralFilterStructure}
              setQueryFiltersState={(filters) => {
                setSearchFilters(filters);
              }}
              clearButtonLabel="Reset all"
              mode="horizontal"
              sectionTitle={<></>}
              fetchTypeaheads={handleTypeaheadSearch}
              typeaheadData={typeaheadData}
              showSearchIcon
              showClearIcon
              hideAppliedFilters
              handleClearAll={clearTypeaheadData}
            />
            <div className="grid-row margin-top-8 margin-bottom-3 justify-space-between">
              <div>
                <Button
                  type="button"
                  variant="primary"
                  label="Search"
                  onClick={() => {
                    if (searchFilters.length === 0) {
                      setError('Apply at least one filter to see results');
                    } else {
                      setError(null);
                      dispatch(
                        setPaginationState({
                          ...paginationState,
                          offset: 0,
                          currentPage: 1,
                          order: null,
                        }),
                      );
                      dispatch(setVehicleReferralFilters(searchFilters));
                    }
                  }}
                  data-testid="search"
                />
              </div>
              <div className="margin-right-neg-1">
                {isReportLoading && (
                  <Spinner
                    size="small"
                    className="display-inline-block"
                    data-testid="report-loading-spinner"
                  />
                )}
                <Button
                  type="button"
                  variant="outline"
                  label="Export search results"
                  leftIcon={{
                    name: 'FileDownload',
                  }}
                  disabled={isReportLoading}
                  onClick={() => handleExportSearchResults()}
                  data-testid="export search results"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleReferralFilters;
