import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import {
  Spinner,
  TabSet,
  StatusBadge,
  Alert,
} from '@gsa/afp-component-library';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import Breadcrumbs from './widgets/Breadcrumbs';
import { RequisitionStatus } from './RequisitionDetailsUtils';
import { groupOptionsPerCatalog2 } from '../VehicleRequisition/utils/VehicleRequisitionUtils';
import { useQueryParam } from '../../hooks/useQueryParam';
import './RequisitionDetails.scss';
import ReviewSummaryTab from './tabs/ReviewSummaryTab';
import RequisitionActivitiesTab from './tabs/RequisitionActivitiesTab';
import UrgentRequirementReviewSummaryTab from './tabs/UrgentRequirementReviewSummary';
import VehicleRequisitionContextProvider from '../../Providers/VehicleRequisitionContextProvider/VehicleRequisitionContextProvider';
import ReturnOrderModal from './ReturnOrderModal';
import ContactBuyingPopover from '../../components/ContactBuyingPopover/ContactBuyingPopover';
import { RequisitionStatusType } from '../my-requisitions/tableHelpers';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { RequisitionBoacErrors } from '../VehicleRequisition/constants/VehicleRequisitionErrors';
import { STEPS } from '../VehicleRequisition/constants/VehicleRequisitionConstants';
import ErrorContext from '../../context/ErrorContext/ErrorContext';
import AttachmentTable from './Attachment/AttachmentTable/AttachmentTable';
import SelectedOptions from '../VehicleRequisition/components/SelectedOptions/SelectedOptions';
import {
  REQUISITION_TYPE, 
  isAreqType,
} from '../non-standard-purchase/constants';
import { StoreOperations, StoreSubjects } from '../../constants/constants';
import { setSessionStorageItems } from '../../utilities/commonUtils';
import { ValidationModal } from './ValidationModal/ValidationModal';
import { withoutAREQOption } from '../../constants/utils';
import { OPTION_QUANTITY_REQUIRED_CODE } from '../../utilities/CompareVehicleUtils';
import { useGetSinContracts, useGetAttachmentTypes, useGetContractsCostBreakdown, useGetOptionalRequirements, useGetRequisition, useGetStandardItem } from '../../requests/common-requests';
import { resetState, setSinContracts, setAttachmentTypes, setContractsCostBreakdown, setCostBreakdown, setOptionalRequirements, setRequisition, setStandardItem, setContract } from '../../reducers/review';
import { useDispatch, useSelector } from 'react-redux';

const RequisitionDetails = () => {
  const query = useQueryParam();
  const requisitionId = query.get('requisitionId');
  const currentTab = query.get('tab');
  const [tab, setTab] = useState('summary');

  const dispatch = useDispatch();

  const {
    sinContracts,
    attachmentTypes,
    contractsCostBreakdown,
    contract,
    costBreakdown,
    customPaintAttachments,
    optionalRequirements,
    paintOptions,
    requisition,
    standardItem,
    taggedOptions,
    validations,
  } = useSelector((state) => state.reviewReducer);

  const [getSinContracts] = useGetSinContracts(setSinContracts);

  const [getRequisition] = useGetRequisition(setRequisition);

  const [getContractsCostBreakdown] = useGetContractsCostBreakdown(
    setContractsCostBreakdown,
  );

  const [getAttachmentTypes] = useGetAttachmentTypes(setAttachmentTypes);

  const [getStandardItem] = useGetStandardItem(setStandardItem);

  const [getOptionalRequirements] = useGetOptionalRequirements(
    setOptionalRequirements,
  );

  useEffect(() => {
    if (contractsCostBreakdown && optionalRequirements && requisition) {
      const selectedCalculatedPrice = contractsCostBreakdown.find(
        (item) => item.contractLineId === requisition.contractLineId,
      );

      if (
        selectedCalculatedPrice &&
        requisition.requisitionStatus !== RequisitionStatus.RECEIVED_BY_GSA
      ) {
        const groupedPerVehicleOptions = groupOptionsPerCatalog2(
          optionalRequirements,
          selectedCalculatedPrice.perVehicleOptions,
        );
        setPerVehicleOptionsState(groupedPerVehicleOptions);
        const groupedPerOrderOptions = groupOptionsPerCatalog2(
          optionalRequirements,
          selectedCalculatedPrice.perOrderOptions,
        );
        setPerOrderOptionsState(groupedPerOrderOptions);
        const gropedPerVehicleRequiredOptions = groupOptionsPerCatalog2(
          optionalRequirements,
          selectedCalculatedPrice.perVehicleRequiredOptions,
        );
        setPerVehicleRequiredOptionsState(gropedPerVehicleRequiredOptions);
      }
      dispatch(setCostBreakdown(selectedCalculatedPrice || null));

      if (requisition.requisitionStatus === RequisitionStatus.RECEIVED_BY_GSA) {
        const perVehicleOptions = requisition.vehicles[0]?.vehicleEquipments
          ?.filter(
            (eq) =>
              eq.quantityType === OPTION_QUANTITY_REQUIRED_CODE.NONE ||
              eq.quantityType === OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE,
          )
          .map((equipment) => {
            const {
              quantityType,
              quantity,
              equipmentCode,
              totalOptionPrice,
              unitPriceToCustomer,
            } = equipment;
            return {
              optionCode: equipmentCode,
              quantity,
              optionTotalPrice: totalOptionPrice,
              unitPrice: unitPriceToCustomer,
              optionType: quantityType,
            };
          });
        const groupedPerVehicleOptions = groupOptionsPerCatalog2(
          optionalRequirements,
          perVehicleOptions,
        );
        setPerVehicleOptionsState(groupedPerVehicleOptions);
        const perOrderOptions = requisition?.vehicles[0]?.vehicleEquipments
          ?.filter(
            (eq) => eq.quantityType === OPTION_QUANTITY_REQUIRED_CODE.PER_ORDER,
          )
          .map((equipment) => {
            const {
              quantityType,
              quantity,
              equipmentCode,
              totalOptionPrice,
              unitPriceToCustomer,
            } = equipment;
            return {
              optionCode: equipmentCode,
              quantity,
              optionTotalPrice: totalOptionPrice,
              unitPrice: unitPriceToCustomer,
              optionType: quantityType,
            };
          });
        const groupedPerOrderOptions = groupOptionsPerCatalog2(
          optionalRequirements,
          perOrderOptions,
        );
        setPerOrderOptionsState(groupedPerOrderOptions);
        setPerVehicleRequiredOptionsState([]);
      }
    }
  }, [contractsCostBreakdown && optionalRequirements && requisition]);

  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    dispatch(resetState());
    getAttachmentTypes();
    const getData = async () => {
      const getRequisitionResult = await getRequisition({
        variables: { requisitionId },
      });
      if (getRequisitionResult.data) {
        const requisition = getRequisitionResult.data;
        const standardItemId = requisition.standardItemId;
        setSessionStorageItems(requisition);

        if (standardItemId) {
          const getActiveContractsResult = await getSinContracts({
            variables: {
              standardItemId,
              transactionType: requisition.transactionType,
            },
          });
          if (getActiveContractsResult.data?.length) {
            const sinContracts = getActiveContractsResult.data;
            if (requisition.validations?.vehicleAvailability == null) {
              dispatch(
                setContract(
                  sinContracts.find(
                    (item) => item.id === requisition.contractLineId,
                  ) || null,
                ),
              );
            } else {
              dispatch(setContract(sinContracts[0]));
            }
          }

          await getStandardItem({
            variables: {
              filter: {
                operator: 'EQ',
                key: 'id',
                value: standardItemId,
              },
            },
          });
          await getOptionalRequirements({
            variables: { standardItemId },
          });
        }
      }
    };

    if (requisitionId) {
      getData().then(() => {
        setTimeout(() => {
          setDataLoaded(true);
        }, 1000);
      });
    }
  }, [requisitionId]);

  useEffect(() => {
    if (requisition) {
      let colorPriceInfo = null;
      if (
        requisition.contractLineId &&
        requisition.vehicles &&
        requisition.vehicles.length &&
        requisition.vehicles[0].makeColorCode
      )
        colorPriceInfo = {
          contractLineId: requisition.contractLineId,
          priceToCustomer:
            requisition.vehicles[0].makeColorPriceToCustomer || 0,
          priceToGsa: requisition.vehicles[0].makeColorPriceToGsa || 0,
        };
      if (requisition.standardItemId) {
        getContractsCostBreakdown({
          variables: {
            standardItemId: requisition.standardItemId,
            quantity: requisition.quantity,
            selectedOptions: withoutAREQOption(
              requisition.clientData.selectedOptions.selectedOptions,
            ),
            ...(colorPriceInfo && { colorPriceInfo }),
            transactionType: requisition.transactionType,
          },
        });
      }
    }
  }, [requisition]);

  const [viewSelectedOptions, setViewSelectedOptions] = useState(false);
  const [perVehicleOptionsState, setPerVehicleOptionsState] = useState();
  const [perOrderOptionsState, setPerOrderOptionsState] = useState();
  const [perVehicleRequiredOptionsState, setPerVehicleRequiredOptionsState] =
    useState();
  const [errorList, setErrorList] = useState([]);
  const { state: errorState } = useContext(ErrorContext);
  const { currentUser } = useCurrentUser();
  const ability = useAppAbility();

  const isEngineer = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.CatalogCodes),
    [ability],
  );

  const toggleViewSelectedOptions = () =>
    setViewSelectedOptions(!viewSelectedOptions);

  useEffect(() => {
    if (errorState[STEPS.AGENCY_INFORMATION]?.agencyInformation) {
      const boacErrors = [];
      errorState[STEPS.AGENCY_INFORMATION].agencyInformation.forEach(
        (value) => {
          boacErrors.push(<li>{value}</li>);
        },
      );

      setErrorList(boacErrors);
    }
  }, [errorState]);

  const renderSelectedOptions = () => {
    return (
      <div>
        <SelectedOptions
          activeContracts={sinContracts}
          requisition={requisition}
          standardItemData={standardItem}
          calculatedPriceData={contractsCostBreakdown}
          optionalReqData={optionalRequirements}
          toggleViewSelectedOptions={toggleViewSelectedOptions}
        />
      </div>
    );
  };

  const renderReviewSummary = () => {
    if (!requisition) {
      return null;
    }

    const { status } = requisition;
    const toggleViewSelectedOptionsFunc =
      status === RequisitionStatus.ENGINEERING_REVIEW && isEngineer
        ? toggleViewSelectedOptions
        : null;
    return (
      <>
        {!dataLoaded ? (
          <div className="afp-modal-overlay draft-requisition-loading">
            <Spinner size="large" className="margin-y-8" />
          </div>
        ) : (
          <ReviewSummaryTab
            setTab={setTab}
            requisitionId={requisitionId}
            requisitionContract={contract}
            requisitionStandardItem={standardItem}
            requisitionCartState={requisition}
            perVehicleRequiredOptionsState={perVehicleRequiredOptionsState}
            perOrderOptionsState={perOrderOptionsState}
            standardItemData={standardItem}
            perVehicleOptionsState={perVehicleOptionsState}
            paintAndGraphicsOptionsState={paintOptions}
            taggedOptionsState={taggedOptions}
            totalUploadedFiles={customPaintAttachments}
            requisitionCartValidations={validations}
            calculatedPriceData={costBreakdown}
            toggleViewSelectedOptions={toggleViewSelectedOptionsFunc}
            activeContracts={sinContracts}
            allModelCostBreakDown={contractsCostBreakdown}
            attachmentTypes={attachmentTypes}
            optionalReqData={optionalRequirements}
          />
        )}
      </>
    );
  };

  const renderUrgentReqReviewSummary = () => {
    return (
      <UrgentRequirementReviewSummaryTab
        setTab={setTab}
        requisitionId={requisitionId}
        requisitionCartState={requisition}
        attachmentTypes={attachmentTypes}
      />
    );
  };

  const isAgencyInformationErrorEmpty = () => {
    return !(
      errorState &&
      errorState[STEPS.AGENCY_INFORMATION]?.agencyInformation &&
      errorState[STEPS.AGENCY_INFORMATION]?.agencyInformation?.size > 0
    );
  };

  const renderDetailsMainSection = (reqData) => {
    if (viewSelectedOptions) {
      return renderSelectedOptions();
    }
    const isMas =
      reqData?.requisitionType === REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES;
    const isUrgent =
      reqData?.requisitionType === REQUISITION_TYPE.URGENT_REQUIREMENT;

    const activityTab = () =>
      isUrgent || isMas
        ? renderUrgentReqReviewSummary()
        : renderReviewSummary();

    const isAreq = isAreqType(requisition?.requisitionType);

    return (
      <>
        {isAreq &&
          requisition?.requisitionStatus ===
            RequisitionStatus.ENGINEERING_REVIEW &&
          isEngineer &&
          requisition?.assignedEngineer?.id !== currentUser?.id && (
            <Alert
              type="warning"
              heading="This requisition is unassigned to an engineer"
            >
              In order to take further action on this requisition, it must first
              be assigned to an engineer.
            </Alert>
          )}

        <div className="requisition-details-tabs">
          <TabSet
            tabs={[
              {
                heading: 'Review summary',
                tabSelectedWhenOpen:
                  tab === 'summary' && currentTab !== 'activities',
                content: activityTab(),
              },
              {
                heading: 'Activity',
                tabSelectedWhenOpen:
                  currentTab === 'activities' || tab === 'activities',
                content: (
                  <RequisitionActivitiesTab requisitionId={requisitionId} />
                ),
              },
              {
                heading: 'Attachments',
                tabSelectedWhenOpen: tab === 'attachments',
                content: (
                  <AttachmentTable
                    requisitionId={requisitionId}
                    setTab={setTab}
                    uploadAttachment={isMas || false}
                    status={requisition?.status}
                    reqType={reqData?.requisitionType}
                  />
                ),
              },
            ]}
          />
        </div>
      </>
    );
  };

  const checkUrgentReqErrors = Object.keys(errorState) || [];
  let detailContent = ((!!contract?.length && !_.isEmpty(costBreakdown)) ||
    requisition?.friendlyName) && (
    <>
      {checkUrgentReqErrors.length > 0 &&
        (checkUrgentReqErrors.includes('ContractingFinalReviewInfo') ||
          checkUrgentReqErrors.includes('quoteEvaluation')) && (
          <Alert type="error" heading="This page contains errors">
            Please address all fields outlined in red before proceeding to the
            next step.
          </Alert>
        )}
      <div className="draft-title-section" data-testid="req-detail-title">
        <span className="draft-name">{requisition.friendlyName}</span>

        <div className="requisition-status">
          <StatusBadge
            variant={
              (
                RequisitionStatusType[requisition.requisitionStatus] || {
                  color: 'Warning-Gray',
                }
              ).color
            }
          >
            {RequisitionStatusType[requisition.requisitionStatus].label}
          </StatusBadge>
        </div>
      </div>

      {(viewSelectedOptions ||
        requisition?.requisitionStatus === 'FINAL_APPROVAL') && (
        <h2>Customer agency approval</h2>
      )}

      {requisition?.friendlyName && renderDetailsMainSection(requisition)}
    </>
  );

  if (
    validations?.systemAvailability?.isSINexpired ||
    validations?.vehicleAvailability?.isContractExpired ||
    (validations?.optionPricesChanges &&
      validations?.optionPricesChanges.length > 0) ||
    (validations?.optionsUnavailable &&
      validations?.optionsUnavailable.length > 0) ||
    (validations?.optionCollisionChanges &&
      validations?.optionCollisionChanges.length > 0)
  ) {
    const sin = requisition?.standardItemCode || null;
    detailContent = (
      <ReturnOrderModal
        requisitionId={requisitionId}
        requisitionName={requisition.friendlyName}
        standardItemCode={sin}
        requisitionCartValidations={validations}
        canSubmitOrder
        visible={false}
      />
    );
  }

  return (
    <>
      <VehicleRequisitionContextProvider>
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            <Breadcrumbs />
            <ContactBuyingPopover />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            {!isAgencyInformationErrorEmpty() ? (
              <ErrorMessage
                id="requisition"
                alert={
                  <>
                    {RequisitionBoacErrors.title}
                    {errorList}
                    {RequisitionBoacErrors.footer}
                  </>
                }
                heading={RequisitionBoacErrors.heading}
              />
            ) : null}
          </div>
          <ValidationModal requisitionData={requisition} />
          {requisition?.friendlyName ? (
            detailContent
          ) : (
            <Spinner size="large" className="margin-y-8" />
          )}
        </>
      </VehicleRequisitionContextProvider>
    </>
  );
};

export default RequisitionDetails;
