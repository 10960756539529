import React from 'react';
import { RecoilRoot } from 'recoil';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import {
  AppProvider,
  FeatureProvider,
  NotFound,
  Unauthorized,
} from '@gsa/afp-shared-ui-utils';
import { PrivateRoute } from './components/RouteComponents/PrivateRoute';
import { PublicRoute } from './components/RouteComponents/PublicRoute';
import createReduxStore from './store';
import Layout from './utilities/Layout';
import LogoutPage from './pages/auth/logout';
import HomePage from './pages/standards/home';
import PurchasePreFilterContainerWithContext from './pages/purchase/PurchasePreFilter';
import ErrorDisplayComponent from './components/ErrorComponent/ErrorComponent';
import VehicleListingsComp from './pages/vehicle-listings/VehicleListingsPage';
import VehicleRequisitionContainerWithContext from './containers/VehicleRequistionContainer/VehicleRequisitionContainer';
import UrgentRequirementContainerWithContext from './containers/UrgentRequirementContainer/UrgentRequirementContainer';
import VehicleAvailabilityListing from './pages/vehicle-availability/VehicleAvailabilityListing';
import RequisitionDetails from './pages/ReviewDetails/RequisitionDetails';
import NonStandardOrdering from './pages/non-standard-purchase/NonStandardOrdering';
import MasRequirementContainerWithContext from './containers/MasRequirementContainer/MasRequirementContainer';
import dataStore, { GET_ENABLED_FEATURES } from './services/data-layer';
import '@gsa/afp-component-library/dist/css/index.css';
import '@gsa/afp-shared-form-utils/dist/style.css';
import './App.scss';
import RequisitionsAndOrders from './pages/requisitions-and-orders/RequisitionsAndOrders';
import ErrorContextProvider from './Providers/ErrorContextProvider/ErrorContextProvider';
import OrderDetailsContainer from './pages/order-detail/OrderDetailsContainer';
import CloneRequisition from './pages/requisitions-and-orders/CloneRequisition';
import BulkOrderMod from './pages/requisitions-and-orders/BulkOrderMod';
import { useSystemAlert } from './services/system-alert';
import OrderModDetailsContainer from './pages/order-modification-detail/OrderModDetailsContainer';
import StoreReports from './pages/store-reports/StoreReports';
import LeasingSteps from './pages/leasing/leasing-steps';
import LeasingPreFilter from './pages/leasing/LeasingPreFilter';
import OrderingGuideFilter from './pages/ordering/OrderingGuideFilter';
import SelectReplacementVehicles from './pages/leasing/steps/select-replacement-vehicles/select-replacement-vehicles';
import DestinationAndVehicleTypes from './pages/leasing/steps/destination-and-vehicle-types/destination-and-vehicle-types.jsx';
import PaintAndGraphics from './pages/leasing/steps/paint-and-graphics/paint-and-graphics.jsx';
import CompareAndSelect from './pages/leasing/steps/compare-and-select/compare-and-select.jsx';

import ReviewVehicleBuild from '@/pages/leasing/steps/review-vehicle-build/review-vehicle-build.jsx';
import DeliveryAddress from '@/pages/leasing/steps/delivery-address/delivery-address.jsx';
import ReviewAndSubmit from './pages/leasing/steps/review-and-submit/review-and-submit.jsx';
import RouteError from './components/RouteComponents/RouteError.jsx';
import VehicleReferral from "./pages/vehicle-referral/vehicle-referral.jsx";
import SelectOptions from './pages/vehicle-referral/replace-vehicle/steps/select-options/select-options.jsx';
import { Purchase_Vehicles } from './pages/constants.js';
import NonLowBidJustification from './pages/leasing/steps/non-low-bid-justification/non-low-bid-justification.jsx';

function App() {
  const { setSystemAlert } = useSystemAlert();

  // do not call functions in this component! it will cause rerenders like crazy.
  // be sure to memoize their return values like below so you can use them
  const dataStoreMemo = React.useMemo(() => dataStore({ setSystemAlert }), []);
  const store = React.useMemo(() => createReduxStore(dataStoreMemo), []);
  const RequisitionsListing = React.useMemo(
    () => RequisitionsAndOrders('requisitions'),
    [],
  );
  const OrdersListing = React.useMemo(
    () => RequisitionsAndOrders('orders'),
    [],
  );
  const OrderVehiclesListing = React.useMemo(
    () => RequisitionsAndOrders('order-vehicles'),
    [],
  );
  const OrderModListing = React.useMemo(
    () => RequisitionsAndOrders('order-modifications'),
    [],
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<RouteError />}>
        <Route
          path="/"
          element={<PrivateRoute featureName="AFP_STORE_HOME" title="Home" />}
        >
          <Route exact path="/" element={<HomePage />} />
        </Route>
        <Route path="/logout" element={<PublicRoute />}>
          <Route exact path="/logout" element={<LogoutPage />} />
        </Route>
        <Route
          path="/purchase"
          element={
            <PrivateRoute
              featureName="AFP_STORE_PURCHASE"
              title={Purchase_Vehicles}
            />
          }
        >
          <Route
            exact
            path="/purchase"
            element={<PurchasePreFilterContainerWithContext />}
          />
        </Route>

        <Route
          path="/lease"
          element={
            <PrivateRoute featureName="AFP_STORE_PURCHASE" title="Lease" />
          }
        >
          <Route path="vehicle-referral/:assetId?" element={<VehicleReferral />} />
          <Route
            path=""
            element={<LeasingPreFilter />}
          />

          <Route path=":requisitionId?" element={<LeasingSteps />}>
            <Route
              path="select-replacement-vehicles"
              element={<SelectReplacementVehicles />}
            />
            <Route
              path="destination-and-vehicle-type"
              element={<DestinationAndVehicleTypes />}
            />
            <Route path="compare-and-select" element={<CompareAndSelect />} />
            <Route path="no-low-bid-justification" element={<NonLowBidJustification />} />
            <Route path="paint-and-graphics" element={<PaintAndGraphics />} />
            <Route
              path="review-vehicle-build"
              element={<ReviewVehicleBuild />}
            />
            <Route path="delivery-address" element={<DeliveryAddress />} />
            <Route path="review-submit" element={<ReviewAndSubmit />} />
          </Route>
        </Route>

        <Route
          path="/non-standard-ordering"
          element={
            <PrivateRoute
              featureName="AFP_STORE_PURCHASE"
              title="Non Standard Ordering Options"
            />
          }
        >
          <Route
            exact
            path="/non-standard-ordering"
            element={<NonStandardOrdering />}
          />
        </Route>
        <Route
          path="/mas-requisition"
          element={
            <PrivateRoute
              featureName="AFP_STORE_PURCHASE"
              title="MAS Requirements"
            />
          }
        >
          <Route
            exact
            path="/mas-requisition"
            element={<MasRequirementContainerWithContext />}
          />
        </Route>
        <Route
          path="/urgent-requisition"
          element={
            <PrivateRoute
              featureName="AFP_STORE_PURCHASE"
              title="Urgent Requisition"
            />
          }
        >
          <Route
            exact
            path="/urgent-requisition"
            element={<UrgentRequirementContainerWithContext />}
          />
        </Route>
        <Route
          path="/vehicle-listings"
          element={
            <PrivateRoute
              featureName="AFP_STORE_VEHICLE"
              title="Vehicle Listing"
            />
          }
        >
          <Route
            exact
            path="/vehicle-listings"
            element={<VehicleListingsComp />}
          />
        </Route>
        <Route
          path="/vehicle-requisition"
          element={
            <PrivateRoute
              featureName="AFP_STORE_VEHICLE"
              title="Create a Requisition-Compare and select"
            />
          }
        >
          <Route
            exact
            path="/vehicle-requisition"
            element={<VehicleRequisitionContainerWithContext />}
          />
        </Route>
        <Route
          path="/my-requisitions"
          element={
            <PrivateRoute
              featureName="AFP_STORE_VEHICLE"
              title="Requisitions and Orders"
            />
          }
        >
          <Route
            exact
            path="/my-requisitions"
            element={<RequisitionsListing />}
          />
        </Route>
        <Route
          path="/orders"
          element={
            <PrivateRoute
              featureName="AFP_STORE_VEHICLE"
              title="Motor vehicle orders"
            />
          }
        >
          <Route exact path="/orders" element={<OrdersListing />} />
        </Route>
        <Route
          path="/order-vehicles"
          element={
            <PrivateRoute
              featureName="AFP_STORE_VEHICLE"
              title="Motor vehicle orders"
            />
          }
        >
          <Route
            exact
            path="/order-vehicles"
            element={<OrderVehiclesListing />}
          />
        </Route>
        <Route
          path="/order-modifications"
          element={
            <PrivateRoute
              featureName="AFP_STORE_VEHICLE"
              title="Order Modifications"
            />
          }
        >
          <Route
            exact
            path="/order-modifications"
            element={<OrderModListing />}
          />
        </Route>
        <Route
          path="/clone-requisition"
          element={
            <PrivateRoute
              featureName="AFP_STORE_VEHICLE"
              title="Clone Requisition"
            />
          }
        >
          <Route
            exact
            path="/clone-requisition"
            element={<CloneRequisition />}
          />
        </Route>
        <Route
          path="/create-order-modifications"
          element={
            <PrivateRoute
              featureName="AFP_STORE_VEHICLE"
              title="Create a modification"
            />
          }
        >
          <Route
            exact
            path="/create-order-modifications"
            element={<BulkOrderMod />}
          />
        </Route>
        <Route
          path="/order-modification-details"
          element={
            <PrivateRoute
              featureName="AFP_STORE_VEHICLE"
              title="Order Modification Details"
            />
          }
        >
          <Route
            exact
            path="/order-modification-details"
            element={<OrderModDetailsContainer />}
          />
        </Route>
        <Route
          path="/order/:orderId"
          element={<PrivateRoute featureName="AFP_STORE_ORDER" />}
        >
          <Route
            exact
            path="/order/:orderId"
            element={<OrderDetailsContainer />}
          />
        </Route>
        <Route
          path="/requisition-details"
          element={
            <PrivateRoute
              featureName="AFP_STORE_REQUISITION_DETAILS"
              title="Requisition Detail"
            />
          }
        >
          <Route
            exact
            path="/requisition-details"
            element={<RequisitionDetails />}
          />
        </Route>
        <Route
          path="/vehicle-availability"
          element={
            <PrivateRoute featureName="AFP_STORE_VEHICLE_AVAILABILITY" />
          }
        >
          <Route
            exact
            path="/vehicle-availability"
            element={<VehicleAvailabilityListing />}
          />
        </Route>
        <Route
          path="/store-reports"
          element={
            <PrivateRoute featureName="AFP_STORE_VEHICLE_AVAILABILITY" />
          }
        >
          <Route exact path="/store-reports" element={<StoreReports />} />
        </Route>
        <Route path="/page-not-found" element={<PublicRoute />}>
          <Route
            exact
            path="/page-not-found"
            element={<ErrorDisplayComponent />}
          />
        </Route>
        <Route
          path="/unauthorized"
          element={<PublicRoute title="Access Denied" />}
        >
          <Route exact path="/unauthorized" element={<Unauthorized />} />
        </Route>
        <Route path="*" element={<PublicRoute title="Sorry for the Detour" />}>
          <Route exact path="*" element={<NotFound />} />
        </Route>
        <Route
          path="/ordering-guide"
          element={
            <PrivateRoute
              featureName="AFP_STORE_VEHICLE"
              title="Ordering guide"
            />
          }
        >
          <Route
            exact
            path="/ordering-guide"
            element={<OrderingGuideFilter />}
          />
        </Route>
      </Route>,
    ),
  );

  return (
    <ApolloProvider client={dataStoreMemo}>
      <Provider store={store}>
        <FeatureProvider featureQuery={GET_ENABLED_FEATURES}>
          <AppProvider>
            <Layout>
              <ErrorContextProvider>
                <RouterProvider router={router} />
              </ErrorContextProvider>
            </Layout>
          </AppProvider>
        </FeatureProvider>
      </Provider>
    </ApolloProvider>
  );
}

export default () => (
  <RecoilRoot>
    <App />
  </RecoilRoot>
);
