export const getLowestPrice = (vendorQuotes = []) =>
  Math.min(...vendorQuotes.map(({ totalPrice }) => totalPrice));

export const getFullVendorQuotes = (
  vendorQuotes,
  activeContracts,
  allModelCostBreakDown,
) => {
  return vendorQuotes.map((vendorQuote) => 
    getFullVendorQuote(vendorQuote, activeContracts, allModelCostBreakDown),
  );
};

export const getFullVendorQuote = (
  vendorQuote,
  activeContracts,
  allModelCostBreakDown,
) => {
  const contract = activeContracts.find((activeContract) => {
    if (vendorQuote.contractLineId)
      return +activeContract.id === vendorQuote.contractLineId;

    return (
      activeContract.makeCode === vendorQuote.makeCode &&
      activeContract.modelCode === vendorQuote.modelCode
    );
  });

  if (!contract) {
    return {
      errorMsg: `Vendor contract schedule line ${vendorQuote.makeCode}/${vendorQuote.modelCode} is not currently actives.`,
    };
  }

  const costBreakdown =
    allModelCostBreakDown.find(
      ({ contractLineId }) => contract.id === contractLineId,
    ) ?? {};

  const selectedContractOptions = contract.equipment.filter((option) =>
    [...costBreakdown.perOrderOptions, ...costBreakdown.perVehicleOptions]
      .map((x) => x.optionCode)
      .includes(option.equipmentCode),
  );

  const areqPrice = vendorQuote.totalPrice;
  const areqPriceWithFee = areqPrice * costBreakdown.purchaseRate;
  const totalPrice =
    (costBreakdown.baseAndOptionsPrice + areqPriceWithFee) *
    costBreakdown.quantity;

  return {
    ...vendorQuote,
    ...contract,
    ...costBreakdown,
    vendorId: contract.contract?.vendor?.id,
    additionalShippingDays: selectedContractOptions?.length
      ? Math.max(
          ...selectedContractOptions.map((x) => x.additionalShippingDays),
        )
      : 0,
    totalPrice,
    totalSellingPrice: totalPrice,
    areqPrice,
    areqPriceWithFee,
  };
};
