import SectionEditButton from '../../../../../components/ReviewComponents/SectionEditButton/SectionEditButton.jsx';
import React from 'react';

const NonLowPriceJustificationReview = ({
  isEditable,
  onEdit,
  justificationContent,
}) => {
  return (
    <>
      <div className="title-section">
        <span>Non-low price justification</span>
        <SectionEditButton
          showButton={isEditable}
          id="non-low-price-edit"
          step='no-low-bid-justification'
          editPage={onEdit}
        />
      </div>
      <div>
        <p>
          {justificationContent}
        </p>
      </div>
    </>
  );
};

export default NonLowPriceJustificationReview;
