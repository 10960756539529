import {
  applyFormErrorsRecursively,
  ComboBoxControl,
  FormGenerator,
} from '@gsa/afp-shared-form-utils';
import { Spinner } from '@gsa/afp-component-library';
import {
  useCreateReferralReplacement,
  useGetCountries,
  useGetStates,
} from '../../../../../requests/vehicle-referral';
import generateVehicleGarageFormContent from './vehicle-garage-form-content';
import { vehicleGarageSchema } from './vehicle-garage-form-schema';
import VehicleReferralButtons from '../../../components/vehicle-referral-buttons/vehicle-referral-buttons';
import { useDispatch } from 'react-redux';
import {
  changeGarageAddress,
  changeProjectedTowingWeight,
  changeReplacementStep,
  setCurrentStatusForVehicle,
  setLastSin,
  setReferralReplacementId,
  setReferralRequestId,
  setSelectedReplacementOptions,
  setSelectedStandardItem,
  setUsedForTowing,
  setUseSameSin,
} from '../../../../../reducers/vehicle-referral';
import {
  setSelectedStandardItem as leasingSetSelectedStandardItem,
  setSelectedVehicleType,
} from '../../../../../reducers/leasing.jsx';
import { REPLACE_VEHICLE_STEPS } from '../../replace-vehicle';
import { useRef, useState } from 'react';
import AvailableVehicles from './available-vehicles/available-vehicles';
import { useSelector } from 'react-redux';
import { VEHICLE_REFERRAL_STATUS } from '../../../utils/vehicle-referral-util.jsx';

export default function VehicleGarage() {
  const dispatch = useDispatch();
  const {
    garageAddress,
    useSameSin,
    lastSin,
    projectedTowingWeight,
    usedForTowing,
    previousStandardItem,
    selectedStandardItem,
    referralRequestId,
    status,
  } = useSelector((state) => state.vehicleReferralReducer);
  const formGeneratorRef = useRef();
  const [showVehicleSelector, setShowVehicleSelector] = useState(!useSameSin);
  const countries = useGetCountries();
  const defaultStates = useGetStates('US');
  const [createReferralReplacement, { loading: creatingReferralReplacement }] =
    useCreateReferralReplacement();

  const loading = [countries.loading, defaultStates.loading].some(
    (loading) => loading,
  );

  const { content, values } = generateVehicleGarageFormContent({
    defaultValues: {
      countryCode: garageAddress?.country || 'US',
      addressLine1: garageAddress?.address1,
      addressLine2: garageAddress?.address2,
      city: garageAddress?.city,
      stateCode: garageAddress?.state || '-1',
      zipCode: garageAddress?.zip,
      usedForTowing: usedForTowing ? 'yes' : 'no',
      useSameSin: useSameSin ? 'yes' : 'no',
      towingWeight: projectedTowingWeight?.value || null,
      towingWeightUnit: projectedTowingWeight?.unit || 'lbs',
    },
    countries,
    defaultStates,
  });

  const handleSubmit = (data, _, draft) => {
    const shouldUseSameSin = data.useSameSin === 'yes';
    const towingWeight = data.usedForTowing === 'yes';
    const { setValue } = formGeneratorRef.current;

    let payload = {
      referralReplacementStatus:
        status === VEHICLE_REFERRAL_STATUS.RETURNED
          ? VEHICLE_REFERRAL_STATUS.RETURNED
          : VEHICLE_REFERRAL_STATUS.DRAFT,
      referralRequestId,
      replacementGarage: {
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        countryCode: data.countryCode,
        stateCode: data.stateCode,
        zipcode: data.zipCode,
      },
    };

    let standardItem = selectedStandardItem || previousStandardItem;
    if (shouldUseSameSin) {
      dispatch(setSelectedStandardItem(previousStandardItem));
      payload.newSin = previousStandardItem.standardItemNumber;
    } else if (!selectedStandardItem) {
      const { setError } = formGeneratorRef.current;
      setError('useSameSin', {
        type: 'required',
        message: 'You must select a standard item',
      });
      return;
    } else if (!shouldUseSameSin && selectedStandardItem) {
      payload.newSin = selectedStandardItem.standardItemNumber;
    }

    if (standardItem.standardItemNumber !== lastSin) {
      dispatch(setLastSin(standardItem.standardItemNumber));
      dispatch(setSelectedReplacementOptions([]));
      payload.options = [];
    }

    if (towingWeight) {
      payload.towingWeight = `${data.towingWeight} ${data.towingWeightUnit}`;
    } else {
      payload.towingWeight = '';
    }

    dispatch(setUseSameSin(shouldUseSameSin));
    dispatch(setUsedForTowing(data.usedForTowing === 'yes'));

    dispatch(
      changeGarageAddress({
        address1: data.addressLine1,
        address2: data.addressLine2,
        city: data.city,
        country: data.countryCode,
        state: data.stateCode,
        zip: data.zipCode,
      }),
    );

    dispatch(
      changeProjectedTowingWeight(
        data.usedForTowing === 'no'
          ? null
          : {
              value: data.towingWeight,
              unit: data.towingWeightUnit,
            },
      ),
    );
    if (data.usedForTowing === 'no') {
      setValue('towingWeight', '');
      setValue('towingWeightUnit', 'lbs');
    }

    createReferralReplacement({
      variables: {
        input: payload,
      },
    })
      .then(({ data }) => {
        dispatch(setReferralRequestId(data.referralRequestId));
        dispatch(
          setReferralReplacementId(
            data.referralReplacement.referralReplacementId,
          ),
        );
        dispatch(setCurrentStatusForVehicle(data.referralRequestStatusCode));

        if (draft !== true) {
          dispatch(changeReplacementStep(REPLACE_VEHICLE_STEPS.SELECT_OPTIONS));
          window.scrollTo(0, 0);
        }
      })
      .catch(console.error);
  };

  const handleSaveDraft = async () => {
    const { updateButtonControls } = formGeneratorRef.current;
    const values = formGeneratorRef.current.getValues();

    // otherwise just go through the normal submit function
    handleSubmit(values, { updateButtonControls }, true);
  };

  if (loading) {
    return <Spinner size="large" />;
  }

  return (
    <>
      <FormGenerator
        ref={formGeneratorRef}
        id="vehicle-garage-form"
        schema={vehicleGarageSchema}
        content={content}
        defaultValues={values}
        onSubmit={handleSubmit}
        onError={console.error}
        onChange={(context, fieldThatChanged) => {
          if (fieldThatChanged === 'useSameSin') {
            const useSameSin = context.getValues('useSameSin');
            setShowVehicleSelector(useSameSin === 'no');

            if (useSameSin === 'yes') {
              dispatch(setSelectedStandardItem(null));
              dispatch(leasingSetSelectedStandardItem(null));
              dispatch(setSelectedVehicleType(null));
            }
          }
        }}
        useFormProps={{
          mode: 'onBlur',
          reValidateMode: 'onBlur',
        }}
      />

      {showVehicleSelector && <AvailableVehicles />}

      <VehicleReferralButtons
        submitButton={{
          rightIcon: { name: 'arrow_forward' },
          leftIcon: null,
          label: 'Next',
          disabled:
            formGeneratorRef.current?.formState?.isSubmitting ||
            creatingReferralReplacement,
        }}
        saveButton={{
          onClick: handleSaveDraft,
          disabled:
            formGeneratorRef.current?.formState?.isSubmitting ||
            creatingReferralReplacement,
        }}
        nextFormId={'vehicle-garage-form'}
        hideButtons={['returnToSearch', 'returnToCustomer', 'overrideSIN']}
      />
    </>
  );
}
