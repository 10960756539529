import React, { useEffect, useContext, useState, useRef, useMemo } from 'react';
import { useReactToPrint } from 'react-to-print';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Spinner,
  useModal,
  connectModal,
} from '@gsa/afp-component-library';
import { useNavigate } from 'react-router-dom';
import {
  useAppAbility,
  useTitle,
  useCurrentUser,
} from '@gsa/afp-shared-ui-utils';
import {
  CREATE_REQUISITION_COMMENT,
  IS_REQ_PREVIOUSLY_RETURNED,
  GET_REQUISITION_ACTIVITIES,
  GET_REQUISITION_ATTACHMENTS,
  SUBMIT_REQUISITION,
  GET_CONTRACT_BY_CONTRACT_NUMBER,
} from '../../../services/data-layer';
import {
  URGENT_REQ_VEH_DESC,
  MAS_REQ_VEH_DESC,
  REQUISITION_TYPE,
} from '../../non-standard-purchase/constants';
import WarningSvg from '../../../assets/images/warning-icon.svg';
import CommentModal from '../../../components/CommentModal/CommentModal';
import {
  modifyAgencyInfoData,
  RequisitionStatus,
} from '../RequisitionDetailsUtils';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { resetFocusToFirstElement } from '../../../utilities/commons';
import { utcDateToMMDDYYYY } from '../../../utilities/commonUtils';
import {
  initiateBoacValidation,
  shouldCheckBoacErrorForApprover,
  shouldCheckBoacErrorForEngineer,
  validateBoacResponse,
  validateFss19Response,
} from '../../VehicleRequisition/utils/VehicleRequisitionUtils';
import ErrorContext from '../../../context/ErrorContext/ErrorContext';
import ErrorActions from '../../../context/ErrorContext/ErrorActions';
import { AccordionUtilComponent } from '../../../utilities/AccordionUtilComponent';
import VehicleSpecificationsReview from '../../non-standard-purchase/review-details/vehicle-specifications/VehicleSpecificationsReview';
import VehicleRequirementsReview from '../../non-standard-purchase/review-details/vehicle-requirements/VehicleRequirementsReview';
import JustificationReview from '../../non-standard-purchase/review-details/justification-review/JustificationReview';
import AgencyInformationReview from '../../non-standard-purchase/review-details/agency-information/AgencyInformationReview';
import DeliveryInformationReview from '../../non-standard-purchase/review-details/delivery-information/DeliveryInformationReview';
import ReturnOrderModal from '../ReturnOrderModal';
import MasReqPrintPreviewModal from '../../../components/MasReqPrintPreviewModal/MasReqPrintPreviewModal';
import SubmissionModal from '../SubmissionModal';
import ReturnRequisitionModal from '../ReturnRequisitionModal';
import MultipleAddVendors from '../../non-standard-purchase/AddVendors/MultipleAddVendors';
import CustomerQuoteEvaluation from '../../non-standard-purchase/CustomerQuoteEvaluation/CustomerQuoteEvaluation';
import {
  getRequiredTitles,
  ContractDetails,
} from '../../non-standard-purchase/urgent-requisition/utils/UrgReqCommonUtils';
import CustomerReviewAndConfirmPage from '../../non-standard-purchase/CustomerQuoteEvaluation/CustomerReviewAndConfirmPage';
import ContractingPlaceOrder from '../../non-standard-purchase/ContractingFinalApproval/ContractingPlaceOrder';
import MasContractingPlaceOrder from '../../non-standard-purchase/MasContractingFinalApproval/MasContractingPlaceOrder';
import VendorAcceptanceReview from '../../non-standard-purchase/review-details/vendor-acceptance-review/VendorAcceptanceReview';
import './UrgentRequirementReviewSummary.scss';
import {
  REQUISITION_BUTTON_DROPDOWN_ACTIONS,
  RequisitionActionsButtonDropdown,
} from '../../../components/RequisitionActions/RequisitionActionsButtonDropdown/RequisitionActionsButtonDropdown';
import { StoreOperations, StoreSubjects } from '../../../constants/constants';
import RequisitionMessageLines from './ReqMessageLines/RequisitionMessageLines';
import { UserRoles } from '../../../constants/user-constants';

const UrgentRequirementReviewSummaryTab = ({
  setTab,
  requisitionId,
  requisitionCartState,
  attachmentTypes,
}) => {
  const {
    state,
    validateBoacInWallet,
    validatedBoac,
    validateBoacInWalletForAddressCode,
    validatedBoacAddressCode,
    getBoacForSignalCodeCOrL,
    boacForSignalCodeCOrL,
  } = useContext(VehicleRequisitionContext);
  const { draftRequisition } = state;
  const ability = useAppAbility();
  const { currentUser } = useCurrentUser();
  const { dispatch: errorDispatch, state: errorState } =
    useContext(ErrorContext);
  const checkDropdownCondition =
    requisitionCartState?.requisitionStatus ===
    RequisitionStatus.FINAL_APPROVAL;

  const isApprover = useMemo(
    () => ability?.can(StoreOperations.Approve, StoreSubjects.Requisition),
    [ability],
  );
  const isContractingOfficer = currentUser?.roles?.some(
    (role) => role.name === UserRoles.CONTRACTING_OFFICER,
  );
  const isOrderingAdmin = currentUser?.roles?.some(
    (role) => role.name === UserRoles.ORDERING_ADMIN,
  );
  const isAdmin = currentUser?.roles?.some(
    (role) => role.name === UserRoles.SITE_ADMIN,
  );

  const isEngineer = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.CatalogCodes),
    [ability],
  );
  const canSubmitOrder = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.Order),
    [ability],
  );
  const navigate = useNavigate();

  const printReqModal = useModal();
  const DisplayMasReqPrintModal = connectModal(MasReqPrintPreviewModal);

  const submitModal = useModal();
  const DisplaySubmissionModal = connectModal(SubmissionModal);

  const returnReqModal = useModal();
  const DisplayReturnReqModal = connectModal(ReturnRequisitionModal);

  const {
    currentUser: { id: currentUserId },
  } = useCurrentUser();

  const currentUserCreatedRequisition =
    currentUserId === requisitionCartState.createdByInfo.id;

  useEffect(() => setTab('summary'), [setTab]);
  useTitle('Requisition detail-Review summary');
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [, setAllowPrint] = useState(false);
  const [, setAllowComments] = useState(false);
  const [isLoading] = useState(false);
  const [, setRequisitionActivities] = useState([]);
  const isMas =
    // eslint-disable-next-line no-bitwise
    (requisitionCartState?.requisitionType &
      REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES) >
    0;
  const { title, list } = getRequiredTitles(
    requisitionCartState?.requisitionStatus,
    isMas,
  );
  const [isFromReqAction] = useState(false);
  const [isPrintPreview, setIsPrintPreview] = useState(false);
  const [isExpanded, setIsExpanded] = useState(checkDropdownCondition);
  const formRef = useRef();
  const printRef = useRef(null);
  const inCOReviewStatus =
    requisitionCartState?.requisitionStatus ===
    RequisitionStatus.CONTRACTING_REVIEW;
  const inCOFinalReviewStatus =
    requisitionCartState?.requisitionStatus ===
    RequisitionStatus.CONTRACTING_FINAL_REVIEW;
  const isPendingCustomerResponse =
    requisitionCartState?.requisitionStatus ===
    RequisitionStatus.PENDING_CUSTOMER_RESPONSE;
  const contractNumber = requisitionCartState?.contractNo;
  const scheduleLine = requisitionCartState?.scheduleLine;
  const isSubmittedToGsa =
    requisitionCartState?.requisitionStatus ===
      RequisitionStatus.RECEIVED_BY_GSA ||
    requisitionCartState?.requisitionStatus ===
      RequisitionStatus.ORDER_RECEIVED;

  const [submitRequisitionComment] = useMutation(CREATE_REQUISITION_COMMENT, {
    onCompleted: () => {
      setTab('activities');
    },
  });

  const [submitRequisitionMutation] = useMutation(SUBMIT_REQUISITION, {
    fetchPolicy: 'no-cache',
  });

  const onSubmit = async () => {
    const results = await submitRequisitionMutation({
      variables: {
        requisitionId,
        submitComment: '',
      },
    });

    const resubmittedStatus = results.data.submitRequisition.resubmittedStatus
      ? '&resubmittedStatus=true'
      : '';

    navigate(
      `/my-requisitions?requisitionId=${requisitionId}&draftName=${requisitionCartState?.friendlyName}${resubmittedStatus}`,
    );
  };

  const [getRequisitionActivitiesData, { data: requisitionActivitiesData }] =
    useLazyQuery(GET_REQUISITION_ACTIVITIES, {
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    setAllowComments(
      requisitionCartState?.requisitionStatus ===
        RequisitionStatus.DRAFT_APPROVAL ||
        requisitionCartState?.requisitionStatus ===
          RequisitionStatus.FINAL_APPROVAL,
    );
    // TODO: print permission
    setAllowPrint(true);
  }, [requisitionCartState?.requisitionStatus]);

  const handleAddComment = (comment) => {
    setShowAddCommentModal(false);
    const { appURLs } = window.AFP_CONFIG;
    submitRequisitionComment({
      variables: {
        requisitionId,
        comment,
        baseUrl: appURLs.store,
      },
    });
  };

  const handleCancelComment = () => {
    setShowAddCommentModal(false);
  };

  const handleClosePrintModal = () => {
    printReqModal.closeModal();
  };

  const pageStyle = `
    @media print {
      footer {
        position: fixed;
        bottom: 0;
      }
    }
  `;

  const printSummary = useReactToPrint({
    pageStyle,
    content: () => printRef.current,
    onAfterPrint: () => setIsPrintPreview(false),
  });

  useEffect(() => {
    if (isPrintPreview) {
      printSummary();
    }
  }, [isPrintPreview]);

  const handlePrint = () => {
    printSummary();
  };

  const handlePrintSummary = () => {
    setIsExpanded(true);
    printReqModal.openModal();
  };

  const [, setIsReqPrevReturned] = useState(false);

  const [getIsReqPrevReturned, { data: isReqPrevReturnedData }] = useLazyQuery(
    IS_REQ_PREVIOUSLY_RETURNED,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getContractLineData, { data: contractLineData }] = useLazyQuery(
    GET_CONTRACT_BY_CONTRACT_NUMBER,
    {
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    getIsReqPrevReturned({
      variables: {
        requisitionId,
      },
    });
    getRequisitionActivitiesData({
      variables: {
        requisitionId,
        offset: 0,
        limit: 20,
        order: [['createdAt', 'DESC']],
      },
    });
  }, [requisitionId]);

  useEffect(() => {
    if (requisitionActivitiesData?.getRequisitionActivities) {
      const { rows } = requisitionActivitiesData.getRequisitionActivities;
      setRequisitionActivities(rows);
    }
  }, [requisitionActivitiesData]);

  useEffect(() => {
    if (isReqPrevReturnedData) {
      setIsReqPrevReturned(isReqPrevReturnedData?.isReqPreviouslyReturned);
    }
  }, [isReqPrevReturnedData]);

  useEffect(() => {
    resetFocusToFirstElement('review-section');
  }, []);

  useEffect(() => {
    if ((isContractingOfficer || isOrderingAdmin || isAdmin) && errorState) {
      errorDispatch({ type: ErrorActions.CLEAR_ERRORS });
    }
  }, []);

  useEffect(() => {
    if (contractNumber?.length > 0) {
      getContractLineData({
        variables: {
          requisitionId: requisitionCartState.requisitionId,
          contractNumber,
          scheduleLine,
          updatedQuantity: requisitionCartState.quantity,
        },
      });
    }
  }, [contractNumber]);

  const contractLineInfo = contractLineData?.getContractDetailsByContractNumber;

  const shouldCheckBoacError = () => {
    return (
      (isApprover && shouldCheckBoacErrorForApprover(requisitionCartState)) ||
      (isEngineer && shouldCheckBoacErrorForEngineer(requisitionCartState))
    );
  };

  useEffect(() => {
    if (
      boacForSignalCodeCOrL?.getBoacForFundAgencyCode &&
      shouldCheckBoacError()
    ) {
      validateFss19Response(
        modifyAgencyInfoData(requisitionCartState),
        boacForSignalCodeCOrL.getBoacForFundAgencyCode,
        validateBoacInWalletForAddressCode,
        errorDispatch,
      );
    }
  }, [boacForSignalCodeCOrL]);

  useEffect(() => {
    if (validatedBoac?.validateBOAC && shouldCheckBoacError()) {
      validateBoacResponse(
        modifyAgencyInfoData(requisitionCartState),
        validatedBoac.validateBOAC,
        'requisitionBOAC',
        errorDispatch,
      );
    }
  }, [validatedBoac]);

  useEffect(() => {
    if (validatedBoacAddressCode?.validateBOAC && shouldCheckBoacError()) {
      validateBoacResponse(
        modifyAgencyInfoData(requisitionCartState),
        validatedBoacAddressCode.validateBOAC,
        'signalSupplementaryAddress',
        errorDispatch,
      );
    }
  }, [validatedBoacAddressCode]);

  useEffect(() => {
    if (requisitionCartState?.finSignalCode && shouldCheckBoacError()) {
      initiateBoacValidation(
        modifyAgencyInfoData(requisitionCartState),
        {
          validateBoacInWallet,
          validatedBoac,
          validateBoacInWalletForAddressCode,
          validatedBoacAddressCode,
          getBoacForSignalCodeCOrL,
          boacForSignalCodeCOrL,
        },
        errorDispatch,
      );
    }
  }, [requisitionCartState?.finSignalCode]);

  const { data: attachmentsData } = useQuery(GET_REQUISITION_ATTACHMENTS, {
    fetchPolicy: 'no-cache',
    variables: {
      requisitionId,
    },
  });

  const selectedVendorReviewSection = () => {
    const selectedVendorByCustomer = requisitionCartState?.vendorQuotes?.filter(
      (o) => o.isSelected,
    );

    return (
      <div className="vendor-data-review">
        {selectedVendorByCustomer?.map((data) => {
          return (
            <div className="vendor-details-section">
              <CustomerReviewAndConfirmPage
                vendorData={data}
                attachments={attachmentsData}
                title="Selected vendor and funding confirmation"
                justification={requisitionCartState?.justification}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const RequirementsList = () => {
    return (
      <div className="my-req-summary-container">
        {isMas ? (
          <>
            <div className="vehicle-req-review bottom-5">
              <AccordionUtilComponent
                label="MAS vehicle requirements"
                printPreview={isPrintPreview}
                isMas={isMas}
                content={
                  <VehicleRequirementsReview
                    title="Non-standard Vehicle Requirements"
                    editPage={() => {}}
                    isEditable={false}
                    additionalRequirements={
                      requisitionCartState?.additionalRequirements
                    }
                  />
                }
                expanded={isExpanded}
              />
            </div>
          </>
        ) : (
          <>
            <div className="vehicle-spec-review bottom-5">
              <AccordionUtilComponent
                label="Vehicle Specifications and other requirements"
                printPreview={isPrintPreview}
                isUrgReq
                content={
                  <VehicleSpecificationsReview
                    title="Vehicle Specifications"
                    editPage={() => {}}
                    nonSopData={
                      requisitionCartState?.nonSopData ||
                      requisitionCartState?.urgentRequirement
                    }
                    additionalRequirements={
                      requisitionCartState?.additionalRequirements
                    }
                    isEditable={false}
                  />
                }
                expanded={isExpanded}
              />
            </div>

            <div className="justification-review bottom-5">
              <AccordionUtilComponent
                label="Urgent requirement justifications"
                printPreview={isPrintPreview}
                isUrgReq
                content={
                  <JustificationReview
                    title="Urgent requirement justifications"
                    editPage={() => {}}
                    urgentRequirementJustification={
                      requisitionCartState?.urgentRequirementJustification ||
                      requisitionCartState?.nonSopData?.justification
                    }
                    urgentReqJustification={
                      requisitionCartState?.clientData?.clientState
                        ?.urgentRequirement?.urgentReqJustification
                    }
                    isEditable={false}
                  />
                }
                expanded={isExpanded}
              />
            </div>
          </>
        )}

        <div className="agency-information-review bottom-5">
          <AccordionUtilComponent
            label="Agency information"
            printPreview={isPrintPreview}
            isMas={isMas}
            isUrgReq={!isMas}
            content={
              <AgencyInformationReview
                title="Agency information"
                editPage={() => {}}
                selectedContractAgencyInformation={modifyAgencyInfoData(
                  requisitionCartState,
                )}
                isEditable={false}
                reqType={draftRequisition?.requisitionType || REQUISITION_TYPE.URGENT_REQUIREMENT }
              />
            }
            expanded={isExpanded}
          />
        </div>

        <div className="delivery-info-review bottom-5">
          <AccordionUtilComponent
            label="Delivery information"
            printPreview={isPrintPreview}
            isMas={isMas}
            isUrgReq={!isMas}
            content={
              <DeliveryInformationReview
                title="Delivery information"
                editPage={() => {}}
                requisitionAddress={{
                  ...requisitionCartState?.requisitionerAddress,
                  ...requisitionCartState?.requisitionerContact,
                }}
                mailingAddress={{
                  ...requisitionCartState?.mailingAddress,
                  ...requisitionCartState?.mailingContact,
                }}
                deliveryAddress={{
                  ...requisitionCartState?.deliveryAddress,
                  ...requisitionCartState?.deliveryContact,
                }}
                deliveryDate={utcDateToMMDDYYYY(
                  requisitionCartState?.deliveryDate,
                )}
                specialDeliveryInstructions={
                  requisitionCartState?.specialDeliveryInstructions
                }
                isEditable={false}
                reqType={draftRequisition?.requisitionType || REQUISITION_TYPE.URGENT_REQUIREMENT}
              />
            }
            expanded={isExpanded}
          />
        </div>

        {isSubmittedToGsa && (
          <>
            {!isMas && (
              <>
                <div className="contract-line bottom-5">
                  <AccordionUtilComponent
                    label="Selected vendor and funding confirmation"
                    printPreview={isPrintPreview}
                    isMas={isMas}
                    isUrgReq={!isMas}
                    content={
                      <div className="vendor-data-review">
                        {selectedVendorReviewSection()}
                      </div>
                    }
                    expanded={isExpanded}
                  />
                </div>

                <div className="contract-line bottom-5">
                  <AccordionUtilComponent
                    label="Vendor acceptance"
                    printPreview={isPrintPreview}
                    isMas={isMas}
                    isUrgReq={!isMas}
                    content={
                      <div className="vendor-acceptance-review">
                        <div className="section-main-title">
                          Vendor acceptance
                        </div>
                        <VendorAcceptanceReview attachments={attachmentsData} />
                      </div>
                    }
                    expanded={isExpanded}
                  />
                </div>
              </>
            )}

            <div className="contract-line bottom-5">
              <AccordionUtilComponent
                label="Contract line data"
                printPreview={isPrintPreview}
                isMas={isMas}
                isUrgReq={!isMas}
                content={
                  <div className="contract-data-review">
                    <div className="section-main-title">Contract line data</div>
                    {contractLineInfo &&
                      Object.keys(contractLineInfo).length > 0 && (
                        <ContractDetails
                          contractLine={contractLineInfo}
                          requisition={requisitionCartState}
                        />
                      )}
                  </div>
                }
                expanded={isExpanded}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const MasNextSteps = () => {
    //  MAS review summary actions only for CO and Approver
    // CO can place or return to customer
    if (
      inCOFinalReviewStatus &&
      (isContractingOfficer || isOrderingAdmin || isAdmin)
    ) {
      return (
        <div className="action-buttons-section">
          <div className="actions-section-title">CURRENT STEP ACTIONS</div>
          <MasContractingPlaceOrder
            requisitionCartData={requisitionCartState}
          />
        </div>
      );
    }
    //  Approver can send to contracting office or return to Submitter
    //  Note : isApprover will be true for CO too
    if (
      requisitionCartState?.requisitionStatus ===
        RequisitionStatus.FINAL_APPROVAL &&
      isApprover && !isContractingOfficer
    ) {
      return (
        <div className="action-buttons-section">
          <div className="actions-section-title">CURRENT STEP ACTIONS</div>
          <div className="display-flex action-buttons">
            <ReturnOrderModal
              requisitionId={requisitionId}
              requisitionName={requisitionCartState.friendlyName}
              requisitionCartValidations={null}
              canSubmitOrder={canSubmitOrder}
              visible
              isFromReqAction={isFromReqAction}
            />
            <div className="submit-contracting-btn">
              <Button
                type="submit"
                variant="standard"
                data-testid=""
                onClick={() => {
                  onSubmit();
                }}
                leftIcon={{ name: 'check' }}
                label="Submit to contracting officer"
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  /*  const canSubmitToContracting = useMemo(() => {
    if (
      requisitionCartState?.requisitionStatus !==
        RequisitionStatus.PENDING_CUSTOMER_RESPONSE &&
      requisitionCartState?.requisitionStatus !==
        RequisitionStatus.CONTRACTING_REVIEW &&
      requisitionCartState?.requisitionStatus !==
        RequisitionStatus.CONTRACTING_FINAL_REVIEW &&
      requisitionCartState?.requisitionStatus !==
        RequisitionStatus.RECEIVED_BY_GSA
    ) {
      return true;
    }
    return false;
  }, [requisitionCartState?.requisitionStatus]); */
  const contractingOfficerView = () => {
    if (inCOReviewStatus )
     {
      return (
        <>
          <section className="vendor-quote-section-title">
            <div className="quote-title">
              Provide vendor responses and quotes{' '}
            </div>
            <div className="title-desc">
              Please provide vendor responses and quotes below. Multiple vendors
              may be added as needed. If a vendor has not provided a quote or
              response, you can note that, but an uploaded document is required
              for each vendor listed.
            </div>
          </section>

          <MultipleAddVendors
            reference={formRef}
            draftId={requisitionId}
            requisitionName={requisitionCartState.friendlyName}
          />

          {((requisitionCartState?.requisitionStatus !==
            RequisitionStatus.PENDING_CUSTOMER_RESPONSE &&
            (isApprover || isAdmin || isOrderingAdmin)) ||
            (isContractingOfficer && !inCOFinalReviewStatus)) && (
            <div className="display-flex action-buttons">
              <ReturnOrderModal
                requisitionId={requisitionId}
                requisitionName={requisitionCartState.friendlyName}
                requisitionCartValidations={null}
                canSubmitOrder={canSubmitOrder}
                visible
                isFromReqAction={isFromReqAction}
              />
              <div className="submit-contracting-btn">
                <Button
                  type="submit"
                  variant="standard"
                  data-testid=""
                  onClick={() => {
                    if (isContractingOfficer || isOrderingAdmin || isAdmin) {
                      formRef.current.submitForm();
                    } else {
                      onSubmit();
                    }
                  }}
                  leftIcon={{ name: 'check' }}
                  label={
                    isContractingOfficer || isOrderingAdmin || isAdmin
                      ? 'Approve and send to customer'
                      : 'Submit to contracting'
                  }
                />
              </div>
            </div>
          )}
        </>
      );
    }

    if (inCOFinalReviewStatus) {
      const selectedVendorByCustomer =
        requisitionCartState?.vendorQuotes?.filter((o) => o.isSelected);

      return selectedVendorByCustomer?.map((data) => {
        return (
          <div className="vendor-details-section">
            <CustomerReviewAndConfirmPage
              vendorData={data}
              attachments={attachmentsData}
              justification={requisitionCartState?.justification}
            />
            <ContractingPlaceOrder
              requisitionCartData={requisitionCartState}
              attachmentTypes={{ getRequisitionAttachmentTypes: attachmentTypes}}
            />
          </div>
        );
      });
    }

    if (!inCOFinalReviewStatus || isApprover) {
      return (
        <div className="display-flex action-buttons">
          <ReturnOrderModal
            requisitionId={requisitionId}
            requisitionName={requisitionCartState.friendlyName}
            requisitionCartValidations={null}
            canSubmitOrder={canSubmitOrder}
            visible
            isFromReqAction={isFromReqAction}
          />
          <div className="submit-contracting-btn">
            <Button
              type="submit"
              variant="standard"
              data-testid=""
              onClick={() => formRef.current.submitForm()}
              leftIcon={{ name: 'check' }}
              label="Approve and send to customer"
            />
          </div>
        </div>
      );
    }

    return null;
  };

  const UrgNextSteps = () => {
    if (
      (isContractingOfficer || isOrderingAdmin || isAdmin) &&
      [
        RequisitionStatus.CONTRACTING_REVIEW,
        RequisitionStatus.CONTRACTING_FINAL_REVIEW,
      ].includes(requisitionCartState?.requisitionStatus)
    ) {
      return (
        <div className="action-buttons-section">
          <div className="actions-section-title">CURRENT STEP ACTIONS</div>
          {contractingOfficerView()}
        </div>
      );
    }
    return (
      <>
        {isApprover &&
          !isContractingOfficer &&
          requisitionCartState?.requisitionStatus ===
            RequisitionStatus.FINAL_APPROVAL && (
            <div className="action-buttons-section">
              <div className="actions-section-title">CURRENT STEP ACTIONS</div>
              <div className="display-flex action-buttons">
                <ReturnOrderModal
                  requisitionId={requisitionId}
                  requisitionName={requisitionCartState.friendlyName}
                  requisitionCartValidations={null}
                  canSubmitOrder={canSubmitOrder}
                  visible
                  isFromReqAction={isFromReqAction}
                />
                <div className="submit-contracting-btn">
                  <Button
                    type="submit"
                    variant="standard"
                    data-testid=""
                    onClick={() => {
                      if (isApprover || isOrderingAdmin || isAdmin) {
                          onSubmit();
                      } else {
                          formRef.current.submitForm();
                      }
                    }}
                    leftIcon={{ name: 'check' }}
                    label="Submit to contracting"
                  />
                </div>
              </div>
            </div>
          )}
        {isPendingCustomerResponse &&
          (currentUserCreatedRequisition || isOrderingAdmin || isAdmin) && (
            <div className="action-buttons-section">
              <div className="actions-section-title">CURRENT STEP ACTIONS</div>
              <CustomerQuoteEvaluation
                requisitionCartData={requisitionCartState}
              />
            </div>
          )}
      </>
    );
  };

  return (
    <>
      {isLoading && (
        <div className="afp-modal-overlay requisition-loading">
          <Spinner size="large" className="margin-y-8" />
        </div>
      )}
      <div className="urg-req-review-summary" ref={printRef}>
        <div className="non-sop-left-panel">
          <div className="panel-container">
            <div className="card-title">
              <img src={WarningSvg} alt="warning icon" />
              <b>
                {isMas
                  ? 'This is a Multiple Award Schedules (MAS) Vehicle Requirement'
                  : 'This is an Urgent Requirement'}
              </b>
            </div>
            {isMas ? MAS_REQ_VEH_DESC : URGENT_REQ_VEH_DESC} <br />
            <br />
          </div>
        </div>

        <div className="urgent-req-review review-section-urg-req">
          <div className="review-main-section">
            {isSubmittedToGsa ? null : (
              <div>
                <div className="title">{title}</div>
                <div className="text-desc-section">
                  <ol className="title-desc">
                    {list.map((desc) => (
                      <li>{desc}</li>
                    ))}
                  </ol>
                </div>
              </div>
            )}

            <div className="display-flex flex-align-end justify-content-end requisition-action-container">
              <RequisitionActionsButtonDropdown
                menuItems={{
                  [REQUISITION_BUTTON_DROPDOWN_ACTIONS.PRINT]: {
                    onClick: handlePrintSummary,
                  },
                  [REQUISITION_BUTTON_DROPDOWN_ACTIONS.COMMENT]: {
                    onClick: () => {
                      setShowAddCommentModal(true);
                    },
                  },
                  // NOTE: This is commented out because old code did not include this action in list
                  // [REQUISITION_BUTTON_DROPDOWN_ACTIONS.SUBMIT_TO_APPROVER]: {
                  //   onClick: () => submitModal.openModal(),
                  // },
                  // [REQUISITION_BUTTON_DROPDOWN_ACTIONS.RETURN_TO_SUBMITTER]: {
                  //   onClick: () => returnReqModal.openModal(),
                  // },
                }}
              />
            </div>
            <RequirementsList />

            {isSubmittedToGsa ? (
              <div className="message-lines">
                <RequisitionMessageLines
                  requisitionId={requisitionId}
                  isEditAllowed={false}
                />
              </div>
            ) : (
              <>{!isMas ? <UrgNextSteps /> : <MasNextSteps />}</>
            )}
          </div>
        </div>
      </div>

      <CommentModal
        data-testid="add-comment-modal-urg-req"
        isOpen={showAddCommentModal}
        onSubmit={handleAddComment}
        onCancel={handleCancelComment}
        title="Post a comment"
        body={
          isMas
            ? 'Your comment will be added to the activity tracker and viewable by all participating parties'
            : 'Your comment will be added to this timeline and sent to your GSA service rep.'
        }
        isRequired
      />

      <DisplayMasReqPrintModal
        isOpen={printReqModal.isOpen}
        handleClose={handleClosePrintModal}
        handlePrint={handlePrint}
        isUrgReq={!isMas}
        status={requisitionCartState?.requisitionStatus}
      />

      <DisplaySubmissionModal
        isOpen={submitModal.isOpen}
        onClose={() => submitModal.closeModal()}
        requisitionId={requisitionId}
        requisitionName={requisitionCartState.friendlyName}
      />

      <DisplayReturnReqModal
        isOpen={returnReqModal.isOpen}
        onClose={() => returnReqModal.closeModal()}
        requisitionId={requisitionId}
        requisitionName={requisitionCartState.friendlyName}
      />
    </>
  );
};

UrgentRequirementReviewSummaryTab.propTypes = {
  setTab: PropTypes.func.isRequired,
  requisitionId: PropTypes.string.isRequired,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
  attachmentTypes: PropTypes.instanceOf(Array),
};

UrgentRequirementReviewSummaryTab.defaultProps = {
  attachmentTypes: [],
};

export default UrgentRequirementReviewSummaryTab;
