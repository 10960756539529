import { Modal, Button, connectModal, Alert, Spinner } from '@gsa/afp-component-library';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { useDispatch, useSelector } from 'react-redux';
import { generateSubmitRequisitionForm } from './submit-requisition-modal-form';
import { submitRequisitionSchema } from './submit-requisition-modal-form-schema';
import { useReturnRequisition } from '../../../../../requests/leasing';
import { useNavigate } from 'react-router-dom';
import { RequisitionStatus } from '../../../../ReviewDetails/RequisitionDetailsUtils';
import {
    setDisplayReqValidationModal,
    setRequisitionCartValidations,
    setSubmitButtonDisabled,
} from '../../../../../reducers/leasing.jsx';
import requisitionValidationModal from '../requisition-validation-modal/requisition-validation-modal.jsx';

export function SubmitRequisitionModal({
    onSubmit,
    onClose,
    method,
}) {
    const { requisitionName, requisitionStatus, requisitionId, requisitionNumber } = useSelector((state) => state.leasingReducer);
    const [submitRequisition, { loading, error, setError, clearError }] = method;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [modalFormContent, defaultValues] = generateSubmitRequisitionForm({
        requisitionName
    });
    const closeModal = () => {
        onClose()
    }
    const handleSubmit = async (data) => {
        clearError();

        if (!submitRequisition) {
            console.warn('submitRequisition method not provided');
            return;
        }

        let payload = {
            requisitionId,
        };

        if (requisitionStatus === RequisitionStatus.ORDERING_ADMIN_APPROVAL) {
            payload.approvalComment = data.comment;
        } else {
            payload.submitComment = data.comment;
        }

        submitRequisition({
            variables: payload
        }).then((res) => {
            const requisitionCartValidations = res?.data?.requisitionCartValidations;
            if(!res?.data?.operationStatus && requisitionCartValidations && requisitionCartValidations?.validationErrors?.length > 0){
                dispatch(setRequisitionCartValidations(requisitionCartValidations));
                dispatch(setDisplayReqValidationModal(true));
                closeModal();
            }else {
                navigate(`/my-requisitions?filter-requisitionNumber=${encodeURIComponent(requisitionNumber)}`);
                dispatch(setSubmitButtonDisabled(false));
            }
        }).catch((err) => {
            setError(err?.error?.message || err?.error || err?.message || err);
        });
    };



    let submitButtonLabel = 'Submit requisition';
    let areYouSure = 'Are you sure you would like to approve the following requisition';

    if (requisitionStatus === RequisitionStatus.FMC_APPROVAL) {
        areYouSure = 'Are you sure you would like to approve the following requisition and submit to zonal approval?';
        submitButtonLabel = 'Submit to zonal approval';
    }

    return (
        <Modal
            variant="small"
            onClose={closeModal}
            actions={
                <div>
                    <Button
                        variant="unstyled"
                        onClick={closeModal}
                        label="Cancel"
                        disabled={loading}
                    />
                    <Button
                        label={submitButtonLabel}
                        type="submit"
                        form="modal-form" // important to target the form inside the modal
                        disabled={loading}
                    />
                </div>
            }
        >
            {loading ? ( <Spinner /> ) : (
                <>
                    <div>
                        <h2>
                            {areYouSure}
                        </h2>

                        <div className='padding-y-2 font-body-md margin-bottom-3 text-bold'>
                            {requisitionName}
                        </div>
                    </div>

                    {error && <Alert className='margin-bottom-1' type='error'>{error}</Alert>}

                    <FormGenerator
                        id="modal-form" // important to give the form an id so that the submit button can target it
                        schema={submitRequisitionSchema}
                        content={modalFormContent}
                        defaultValues={defaultValues}
                        onSubmit={handleSubmit}
                    />
                </>
            )}
        </Modal>
    );
}

export default connectModal(SubmitRequisitionModal);