import { Accordion, Spinner } from '@gsa/afp-component-library';

import { useTitle } from '@gsa/afp-shared-ui-utils';
import OptionsPerVehicle from '@/components/ReviewComponents/OptionsPerVehicle/OptionsPerVehicle.jsx';
import PaintAndGraphics from '@/components/ReviewComponents/PaintAndGraphics/PaintAndGraphics.jsx';
import TaggedOptionDetails from '@/components/ReviewComponents/TaggedOptionDetails/TaggedOptionDetails.jsx';
import CostSummary from '@/components/ReviewComponents/CostSummary/CostSummary.jsx';
import VehicleInformation from '../../components/vehicle-information/vehicle-information';
import React, { useEffect, useMemo, useState } from 'react';
import {
  REQUISITION_BUTTON_DROPDOWN_ACTIONS,
  RequisitionActionsButtonDropdown,
} from '@/components/RequisitionActions/RequisitionActionsButtonDropdown/RequisitionActionsButtonDropdown.jsx';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_REQUISITION_COMMENT } from '@/services/data-layer.jsx';
import { useGeneratePDF } from '@/requests/leasing.jsx';
import { useSelector } from 'react-redux';
import CommentModal from '@/components/CommentModal/CommentModal.jsx';
import useLeasingNavigation from '@/hooks/useLeasingNavigation.jsx';
import { LeasingSteps } from '@/pages/leasing/leasing-consts.js';
import LeasingNavigation from '@/pages/leasing/components/navigation.jsx';
import groupOptionsPerCatalog from '@/pages/VehicleRequisition/utils/VehicleRequisitionUtils.jsx';
import { filterOptions } from '@/components/ReviewComponents/ReviewOptions/reviewOptionsUtil.jsx';
import RequisitionLayout from '../../components/requisition-layout/requisition-layout';
import { selectedPVOptions } from '../../leasing-utils.jsx';
import SectionEditButton from '../../../../components/ReviewComponents/SectionEditButton/SectionEditButton.jsx';
import { STEPS } from '../../../VehicleRequisition/constants/VehicleRequisitionConstants.jsx';
import NonLowPriceJustificationReview from '../non-low-bid-justification/components/non-low-price-justification-review.jsx';
import { setCostSummary } from '../../../../reducers/purchase.jsx';
import { useDispatch } from 'react-redux';

const ReviewVehicleBuild = () => {
  useTitle('Review Vehicle Build | Leasing');

  const dispatch = useDispatch();
  const { handleLeasingNavigation } = useLeasingNavigation();
  const [submitRequisitionComment] = useMutation(CREATE_REQUISITION_COMMENT);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);

  let items = [];

  const {
    selectedStandardItem,
    paintAndGraphics,
    compareSelect,
    requisitionId,
    vehicleQuantity,
    attachmentTypes,
    attachments,
    nonLowBidJustification,
  } = useSelector((state) => state.leasingReducer);
  const requisition = {
    quantity: vehicleQuantity,
    vehicles: [],
  };
  const {
    selectedContract,
    calculatedPriceBreakdown,
    addOptions,
    selectedEngine,
  } = compareSelect;
  const { selectedColors, paintAndGraphicsDescription } = paintAndGraphics;
  useEffect(() => {
    if (!addOptions || !selectedContract) {
      return;
    }
    const optionalOptions = {
      getOptionalOptions: [...addOptions],
    };

    const groupedPerVehicleOptions = groupOptionsPerCatalog(
      optionalOptions,
      selectedContract?.perVehicleOptions || [],
    );
    console.log({ groupedPerVehicleOptions });
    // No taggedOptions for leasing
    const myOptions = filterOptions(
      groupedPerVehicleOptions,
      paintAndGraphicsDescription,
      [],
    );

    setOptions(myOptions);
  }, [addOptions, selectedContract]);

  useEffect(() => {
    dispatch(
      setCostSummary({
        // _source: 'Review Build Leasing',
        baseAndOptionsPrice: selectedContract?.baseAndOptionsPrice || 0,
        basePrice: selectedContract?.basePrice || 0,
        perOrderOptionsPrice: selectedContract?.perOrderOptionsPrice || 0,
        perVehicleColorPrice: selectedContract?.perVehicleColorPrice || 0,
        perVehicleOptionsPrice: selectedContract?.perVehicleOptionsPrice || 0,
        purchaseRate: selectedContract?.purchaseRate || 0,
        quantity: selectedContract?.quantity || 0,
        totalSellingPrice: selectedContract?.totalSellingPrice || 0,
      }),
    );
  }, [selectedContract]);

  const handleSubmit = () => {
    handleLeasingNavigation(LeasingSteps.DELIVERY_ADDRESS);
  };

  const handlePreviousNavigation = () => {
    handleLeasingNavigation(LeasingSteps.PAINT_AND_GRAPHICS);
  };
  const handleAddComment = async (comment) => {
    const { appURLs } = window.AFP_CONFIG;
    await submitRequisitionComment({
      variables: {
        requisitionId: requisitionId,
        comment,
        baseUrl: appURLs.store,
      },
    });
    setShowAddCommentModal(false);
  };
  const [getPDFUrl] = useGeneratePDF();
  //TODO: Get Data from state

  const printPDF = () => {
    const firstActivity = {};
    const lastActivity = {};
    let agencyName = '';
    let bureauName = '';
    const reqType = sessionStorage.getItem('requisitionType');
    if (reqType === 'on-behalf') {
      agencyName = sessionStorage.getItem('receivingAgency')?.split('-')[1];
      bureauName = sessionStorage.getItem('receivingBureau')?.split('-')[1];
    } else {
      agencyName = sessionStorage.getItem('orderingAgency')?.split('-')[1];
      bureauName = sessionStorage.getItem('orderingBureau')?.split('-')[1];
    }
    const agencyInfo = {
      name: agencyName,
    };
    const bureauInfo = {
      name: bureauName,
    };
    setIsLoading(true);

    getPDFUrl({
      variables: {
        contentModel: 'new_requisition',
        recordId: requisition?.requisitionId,
        friendlyName: 'test',
        sections: 'all',
        vehicleContract: JSON.stringify({
          clarifications: selectedContract?.clarifications ?? [],
          offerLineArtifact: selectedContract?.offerLineArtifact ?? [],
          baseEngineRating: selectedContract?.baseEngineRating ?? {},
          vendorName: selectedContract?.vendorName ?? '',
          modelName: selectedContract?.modelName ?? '',
          modelYear: selectedContract?.modelYear ?? '',
          comment: selectedContract?.comment ?? '',
        }),
        requisitionStandardItem: JSON.stringify(selectedStandardItem),
        calculatedPriceInfoState: JSON.stringify(selectedContract),
        selectedContractAgencyInformation: JSON.stringify({}),
        agencyInfo: JSON.stringify(agencyInfo),
        bureauInfo: JSON.stringify(bureauInfo),
        firstActivity: JSON.stringify(firstActivity),
        lastActivity: JSON.stringify(lastActivity),
        perVehicleOptions: JSON.stringify(
          selectedContract?.perVehicleOptions ?? [],
        ),
        selectedColor: '',
        paintAndGraphicsOptions: JSON.stringify(paintAndGraphicsDescription),
        taggedOptions: JSON.stringify([]),
        requisitionAddress: JSON.stringify({}),
        mailingAddress: JSON.stringify({}),
        deliveryAddress: JSON.stringify({}),
        nonLowBidJustification: JSON.stringify({ nonLowBidJustification }),
        areqList: JSON.stringify([]),
      },
    }).then((response) => {
      waitingPDFLoop(response.data.signedUrl);
    });
  };
  const waitingPDFLoop = (url) => {
    const maxTry = 15; // 30 seconds wait in total
    const timer = setTimeout(() => {
      const httpReq = new XMLHttpRequest();
      httpReq.open('GET', url, true);
      httpReq.send();
      httpReq.onload = (response) => {
        if (response?.target && response?.target?.status === 200) {
          // open the PDF
          window.open(url, '_blank');
          setIsLoading(false);
        } else if (
          response?.target &&
          response?.target?.status !== 200 &&
          waitingPDFLoop.tryCount < maxTry
        ) {
          waitingPDFLoop.tryCount += 1;
          waitingPDFLoop(url);
          setIsLoading(false);
        } else {
          // throw error
          setIsLoading(false);
        }
      };
      httpReq.onerror = () => {
        setIsLoading(false);
      };
      clearTimeout(timer);
    }, 3000);
  };
  waitingPDFLoop.tryCount = 0;

  const editPage = (step) => {
    handleLeasingNavigation(step);
  };
  const showLowBidJustification = useMemo(() => {
    return !selectedContract?.isLowBidContract;
  }, [selectedContract]);
  if (showLowBidJustification) {
    items.push({
      id: 'Non-low price justification',
      title: 'Non-low price justification',
      content: (
        //TODO: replace placeHolders after justification step implemented
        <NonLowPriceJustificationReview
          isEditable={true}
          onEdit={editPage}
          justificationContent={nonLowBidJustification}
        />
      ),
      expanded: true,
    });
  }

  items.push({
    id: 'Options per vehicle',
    title: 'Options per vehicle',
    content: (
      <OptionsPerVehicle
        selectedContractCostBreakdown={selectedContract}
        isEditable={true}
        options={options}
        onEdit={editPage}
        transactionType={'VEHICLE_LEASE'}
      />
    ),
    expanded: true,
  });
  items.push({
    id: 'Paint and graphics',
    title: 'Paint and graphics',
    content: (
      <PaintAndGraphics
        vehicleColors={
          !paintAndGraphicsDescription?.length ? selectedColors : []
        }
        taggedOptions={[]}
        paintAndGraphicsOptions={paintAndGraphicsDescription}
        requisition={requisition}
        isEditable={true}
        requisitionAttachmentTypes={attachmentTypes}
        onEdit={editPage}
        transactionType={'VEHICLE_LEASE'}
        attachments={attachments}
      />
    ),
    expanded: true,
  });

  items.push({
    id: 'Options details',
    title: 'Options details',
    content: (
      <TaggedOptionDetails
        taggedOptions={[]}
        draftRequisition={requisition}
        isEditable={false}
        requisitionAttachmentTypes={attachmentTypes}
        onEdit={editPage}
      />
    ),
    expanded: true,
  });

  items.push({
    id: 'Cost summary',
    title: 'Cost summary',
    content: (
      <CostSummary
        calculatedPriceData={selectedContract}
        isEditable={true}
        requisition={requisition}
        isAreq={false}
        selectedContractCostBreakdown={selectedContract}
        transactionType={'VEHICLE_LEASE'}
        onEdit={editPage}
      />
    ),
    expanded: true,
  });

  return (
    <RequisitionLayout
      left={
        <VehicleInformation
          contract={selectedContract}
          options={addOptions}
          quantity={vehicleQuantity}
          standardItem={selectedStandardItem}
          selectedContractCostBreakdown={calculatedPriceBreakdown?.find(
            (modelCost) =>
              modelCost.contractLineId === selectedContract?.contractLineId,
          )}
          engine={selectedEngine}
        />
      }
      right={
        <>
          {isLoading && (
            <div className="afp-modal-overlay requisition-loading">
              <Spinner size="large" className="margin-y-8" />
            </div>
          )}
          <div
            role="region"
            tabIndex="0"
            className="margin-bottom-205 review-vehicle-build-title"
          >
            <h2 className="usa-h2">Review vehicle build details</h2>
            <p>
              Please review the following vehicle build details and proceed to
              the next step.
            </p>
          </div>
          <div className="display-flex flex-align-end justify-content-end margin-bottom-4 requisition-action-container">
            <RequisitionActionsButtonDropdown
              className="requisition-action-button"
              menuItems={{
                //Disable print button - AFP-148390
                /*[REQUISITION_BUTTON_DROPDOWN_ACTIONS.PRINT]: {
                                    onClick: () => { printPDF() },
                                },*/
                [REQUISITION_BUTTON_DROPDOWN_ACTIONS.POST_COMMENT]: {
                  onClick: () => {
                    setShowAddCommentModal(true);
                  },
                },
              }}
            />
          </div>

          <div className="review-detail">
            <Accordion items={items} />
          </div>
          <LeasingNavigation
            onNext={handleSubmit}
            onPrevious={handlePreviousNavigation}
            hide={['submit', 'reject']}
          />
          <CommentModal
            data-testid="add-comment-modal-urg-req"
            isOpen={showAddCommentModal}
            onSubmit={handleAddComment}
            onCancel={() => setShowAddCommentModal(false)}
            title="Post a comment"
            body="Your comment will be added to this timeline and sent to your GSA service rep."
            isRequired
          />
        </>
      }
    />
  );
};
export default ReviewVehicleBuild;
