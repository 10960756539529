export const LeasingSteps = Object.freeze({
  BEGIN: 'lease',
  REPLACEMENT_VEHICLES: 'select-replacement-vehicles',
  DESTINATION_AND_VEHICLE_TYPE: 'destination-and-vehicle-type',
  COMPARE_AND_SELECT: 'compare-and-select',
  PAINT_AND_GRAPHICS: 'paint-and-graphics',
  REVIEW_VEHICLE_BUILD: 'review-vehicle-build',
  ORDERING_INFORMATION: 'ordering-information',
  DELIVERY_ADDRESS: 'delivery-address',
  REVIEW_SUBMIT: 'review-submit',
  VEHICLE_REFERRAL: 'vehicle-referral',
  NON_LOW_PRICE_JUSTIFICATION: 'no-low-bid-justification',
});

export const InitialLeasingSteps = Object.freeze([
  {
    stepNumber: 1,
    key: LeasingSteps.REPLACEMENT_VEHICLES,
    label: 'Select replacement vehicles',
    status: 'not completed',
  },
  {
    stepNumber: 2,
    key: LeasingSteps.DESTINATION_AND_VEHICLE_TYPE,
    label: 'Destination and vehicle type',
    status: 'not completed',
  },
  {
    stepNumber: 3,
    key: LeasingSteps.COMPARE_AND_SELECT,
    label: 'Compare and select',
    status: 'not completed',
  },
  {
    stepNumber: 4,
    key: LeasingSteps.PAINT_AND_GRAPHICS,
    label: 'Paint and graphics',
    status: 'not completed',
  },
  {
    stepNumber: 5,
    key: LeasingSteps.REVIEW_VEHICLE_BUILD,
    label: 'Review vehicle build',
    status: 'not completed',
  },
  {
    stepNumber: 6,
    key: LeasingSteps.DELIVERY_ADDRESS,
    label: 'Delivery address',
    status: 'not completed',
  },
  {
    stepNumber: 7,
    key: LeasingSteps.REVIEW_SUBMIT,
    label: 'Review and submit',
    status: 'not completed',
  },
]);

export const OCONUSStateCodes = [
  'AA',
  'AE',
  'AP',
  'FM',
  'GE',
  'IT',
  'MH',
  'NU',
  'PW',
  'AK',
  'AS',
  'GU',
  'HI',
  'MP',
  'PR',
  'UM',
  'VI',
];

export const RequisitionViewMode = Object.freeze({
  /**
   * no steppers, no nothing. read. only.
   */
  READONLY: 'readonly',

  /**
   * review/submit page only - no steppers
   */
  REVIEW: 'review',

  /**
   * review/submit page only - no steppers
   */
  RETURNED: 'returned',
  /**
   * review/submit, no steppers, editable sections
   */
  REVIEW_EDIT: 'review-edit',

  /**
   * default
   */
  EDIT: 'edit',
});
