import { useEffect } from 'react';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import generateSupplementaryInformationForm from './supplementary-information-form';
import { supplementaryInformationSchema } from './supplementary-information-schema';
import {
  useCreateMetadataRecord,
  useCreateReferralReplacement,
  useCreateReferralReplacementAttachment,
  useDeleteMetadataRecord,
  useDeleteReferralReplacementAttachment,
  useGetReplacementAttachmentTypes,
  useUpdateReferralReplacementAttachment,
} from '../../../../../../requests/vehicle-referral';
import { Spinner } from '@gsa/afp-component-library';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import {
  addReplacementAttachment,
  removeReplacementAttachment,
  setReferralRequestId,
  setSupplementaryInformation,
  updateReplacementAttachment,
} from '../../../../../../reducers/vehicle-referral';
import { VEHICLE_REFERRAL_STATUS } from '../../../../utils/vehicle-referral-util';
import { EDITABLE_STATUSES } from '../review-submit';
import useBackToList from '../../../../utils/use-back-to-list';
import { v4 } from 'uuid';

// onSubmit -> (finished: boolean, error: Error) => void

export default function SupplementaryInformation({ formRef, onSubmit }) {
  const [submitReferralReplacement] = useCreateReferralReplacement();
  const [createMetadataRecord] = useCreateMetadataRecord();
  const [deleteMetadataRecord] = useDeleteMetadataRecord();
  const {
    referralRequestId,
    referralReplacementId,
    replacementAttachments,
    status,
    supplementaryInformation,
    vehicleDetails,
  } = useSelector((state) => state.vehicleReferralReducer);
  const { loading, data: attachmentTypes } = useGetReplacementAttachmentTypes();
  const [createReferralReplacementAttachment, { loading: uploading }] =
    useCreateReferralReplacementAttachment();
  const [deleteReferralReplacementAttachment, { loading: deleting }] =
    useDeleteReferralReplacementAttachment();
  const [updateReferralReplacementAttachment, { loading: saving }] =
    useUpdateReferralReplacementAttachment();
  const dispatch = useDispatch();
  const { backToList } = useBackToList();

  const handleUpload = async (data, { addFileToList }) => {
    const metadataInput = {
      name: data.file.name,
      size: data.file.size,
      fileMimeType: data.file.type,
      description: data.note,
      fileLocation: `store/referral/replacement/${v4()}`,
      linkedEntities: {
        replacementAttachmentTypeId: data.category,
        referralRequestId,
      },
      docSource: 'UserUploaded',
      docStore: window.AFP_CONFIG.attachment_bucket,
    };

    let metadata = null;
    try {
      const metadataRecord = await createMetadataRecord({
        variables: { input: metadataInput },
      });

      metadata = metadataRecord.data;
    } catch ({ error }) {
      throw error;
    }

    await axios.put(metadata.signedUrl, data.file, {
      headers: {
        'Content-Type': data.file.type,
        'Content-Disposition': `attachment; filename="${data.file.name}"`,
      },
    });

    try {
      const { data: attachment } = await createReferralReplacementAttachment({
        variables: {
          input: {
            name: data.file.name,
            metadataId: metadata.id,
            referralReplacementId,
            replacementAttachmentTypeId: data.category,
            description: data.note,
          },
        },
      });

      dispatch(addReplacementAttachment(attachment));
      addFileToList({
        ...attachment,
        id: attachment.referralReplacementAttachmentId,
      });
    } catch ({ error }) {
      throw error;
    }
  };

  const handleSave = async (data, { updateFileInList }) => {
    try {
      const { data: attachment } = await updateReferralReplacementAttachment({
        variables: {
          input: {
            referralReplacementAttachmentId:
              data.file.referralReplacementAttachmentId,
            replacementAttachmentTypeId: data.category,
            description: data.note,
          },
        },
      });

      dispatch(updateReplacementAttachment(attachment));
      updateFileInList({
        ...attachment,
        id: attachment.referralReplacementAttachmentId,
      });
    } catch ({ error }) {
      throw error;
    }
  };

  const handleDelete = async (data, { removeFileFromList }) => {
    try {
      await deleteReferralReplacementAttachment({
        variables: {
          referralReplacementAttachmentId: data.referralReplacementAttachmentId,
        },
      });

      await deleteMetadataRecord({
        variables: {
          deleteMetadataRecordId: data.metadataId,
        },
      });

      dispatch(removeReplacementAttachment(data));
      removeFileFromList({
        ...data,
        id: data.referralReplacementAttachmentId,
      });
    } catch ({ error }) {
      throw error;
    }
  };

  const handleEditAction = (data, { openFileModal }) => {
    openFileModal();
  };

  const handleDeleteAction = async (data, { openConfirmDeleteModal }) => {
    openConfirmDeleteModal();
  };

  useEffect(() => {
    formRef?.current?.setValue(
      'files',
      replacementAttachments.map((file) => ({
        ...file,
        id: file.referralReplacementAttachmentId,
      })),
    );
  }, [replacementAttachments]);

  const { formContent, defaultValues } = generateSupplementaryInformationForm({
    handleUpload,
    handleSave,
    handleDelete,
    handleEditAction,
    handleDeleteAction,
    uploading,
    saving,
    deleting,
    attachmentTypes,
    defaultValues: {
      requireMakeModel: !!supplementaryInformation?.missionMakeModel
        ? 'yes'
        : 'no',
      makeModel: supplementaryInformation?.missionMakeModel || '',
      files: replacementAttachments.map((file) => ({
        ...file,
        id: file.referralReplacementAttachmentId,
      })),
      specialInstructions: supplementaryInformation?.specialInstructions || '',
      acknowledge: supplementaryInformation?.acknowledge || false,
    },
    readonly: !EDITABLE_STATUSES.includes(status),
  });

  const handleSubmit = (data) => {
    onSubmit(false);
    const payload = {
      referralRequestId,
      referralReplacementStatus: VEHICLE_REFERRAL_STATUS.SUBMIT,
      missionMakeModel: data.requireMakeModel === 'yes' ? data.makeModel : null,
      specificInstructions: data.specialInstructions,
      acknowledged: data.acknowledge,
      agencyCode: vehicleDetails?.customer?.customerAgency?.id,
      bureauCode: vehicleDetails?.customer?.customerBureau?.id,
      officeCode: vehicleDetails?.customer?.customerPhysicalOffice?.officeCode,
    };

    submitReferralReplacement({
      variables: {
        input: payload,
      },
    })
      .then(({ data }) => {
        dispatch(setReferralRequestId(data.referralRequestId));
        dispatch(
          setSupplementaryInformation({
            missionMakeModel:
              data.requireMakeModel === 'yes' ? data.makeModel : null,
            specialInstructions: data.specialInstructions,
            acknowledge: data.acknowledge,
          }),
        );

        onSubmit(true);
        backToList({
          submitted: true,
          tagNumber: vehicleDetails?.tagNumber,
          status: data?.referralRequestStatusCode,
        });
      })
      .catch((error) => {
        console.error(error);
        onSubmit(true, error);
      });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="replacement-request-supplementary-information">
      <FormGenerator
        ref={formRef}
        id="supplementary-information-form"
        content={formContent}
        schema={supplementaryInformationSchema}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        onError={console.log}
        element={{
          title: {
            style: {
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '32.4px',
              color: '#1B1B1B',
              borderBottom: '2px solid #565C65',
              paddingBottom: '5px',
              marginBottom: '25px',
            },
          },
        }}
      />
    </div>
  );
}
