import {
  GET_ACTIVE_CONTRACTS,
  GET_CALCULATED_PRICE,
  GET_OPTIONAL_REQ,
  GET_REQ_ATTACHMENT_TYPES,
  GET_REQUISITION_CART,
} from '../services/data-layer';
import { useDispatchQuery } from '../hooks/useDispatchQuery';
import { GET_STANDARD_ITEM_BY_ID } from '../services/data-layer/standards';

function queryProxy(dispatcher, query, errorMsg) {
    const { registerQuery } = useDispatchQuery();

    return registerQuery(query, {
      apolloOptions: { fetchPolicy: 'no-cache' },
      transformResult: async ({ data, getState, dispatch }) => {
        if (!data) {
          throw new Error(errorMsg);
        }
        dispatch(dispatcher(data));
      },
    });
}

export function useGetSinContracts(dispatcher) {
    return queryProxy(dispatcher, GET_ACTIVE_CONTRACTS, 'No contracts found.');
}

export function useGetRequisition(dispatcher) {
    return queryProxy(dispatcher, GET_REQUISITION_CART, 'No requisition found.');
}

export function useGetContractsCostBreakdown(dispatcher) {
    return queryProxy(dispatcher, GET_CALCULATED_PRICE, 'No contracts cost breakdown found.');
}

export function useGetAttachmentTypes(dispatcher) {
    return queryProxy(dispatcher, GET_REQ_ATTACHMENT_TYPES, 'No attachment types found.');
}

export function useGetOptionalRequirements(dispatcher) {
    return queryProxy(dispatcher, GET_OPTIONAL_REQ, 'No optional requirements found.');
}

export function useGetStandardItem(dispatcher) {
    return queryProxy(dispatcher, GET_STANDARD_ITEM_BY_ID, 'Standard item not found.');
}

