export const OPTIONS = {
  TURNIN: 'TURNIN',
  KEEP: 'KEEP',
  REPLACE: 'REPLACE',
};

export const STANDARD_ITEM_PARENT_CATEGORY = {
  FED: 'Federal Standard Codes',
  GROUP: 'Vehicle Group',
};

export const VEHICLE_REQUEST_TAB = {
  SELECTION_DETAILS: 'selectionDetails',
  ACTIVITY: 'activity',
};

export const VEHICLE_REFERRAL_STATUS = {
  DRAFT: 'DRAFT',
  SUBMIT: 'SUBMIT',
  RETURNED: 'RETURNED',
  COMPLETED: 'COMPLETED',
  PENDING_CUST_ACTION: 'PENDING_CUST_ACTION',
  CUSTOMER_SUBMISSION: 'CUSTOMER_SUBMISSION',
  FSR_APPROVAL: 'FSR_APPROVAL',
  AGENCY_APPROVAL: 'AGENCY_APPROVAL',
  BUREAU_APPROVAL: 'BUREAU_APPROVAL',
  OFFICE_APPROVAL: 'OFFICE_APPROVAL',
  RE_OPENED: 'RE_OPENED',
  AGENCY_APPROVED: 'AGENCY_APPROVED',
  APPROVED_AT_FSR: 'APPROVED_AT_FSR',
  REQUISITION_CREATED: 'REQUISITION_CREATED',
  REQUISITION_CANCEL: 'REQUISITION_CANCEL',
};

export const VehicleReferralSortMapping = {
  tagNumber: 'tag_number',
  boac: 'boac',
  oldSin: 'standard_item_number',
  newSin: 'new_vehicle_sin',
  mileage: 'current_mileage',
  lawEnforcementDesignation: 'law_enforcement',
  selectionType: 'referral_action',
  referralStatus: 'referral_request_status_code',
};

// need to add Agency approved, Requisition Created
export const ReferralRequestStatusFilters = Object.freeze({
  AGENCY_APPROVED: 'Agency approved',
  AGENCY_APPROVAL: 'Agency review',
  APPROVED_AT_FSR: 'Approved at FSR',
  BUREAU_APPROVAL: 'Bureau review',
  FSR_APPROVAL: 'FSR review',
  // OFFICE_APPROVAL: 'Office review',
  PENDING_CUST_ACTION: 'Referred',
  REQUISITION_CREATED: 'Requisition created',
  RETURNED: 'Returned',
});

export const ReferralRequestStatus = Object.freeze({
  APPROVE: 'Approve',
  DENY: 'Deny',
  KEEP: 'Do not replace',
  TURNIN: 'Turn In',
  REPLACE: 'Replace',
  SUBMIT_FOR_APPROVAL: 'Submit for approval',
  COMMENT_ADDED: 'Comment added',
  COMMENT_DELETED: 'Comment deleted',
  AGENCY_APPROVAL: 'Agency review',
  BUREAU_APPROVAL: 'Bureau review',
  CUSTOMER_SUBMISSION: 'Customer submission',
  DRAFT: 'Draft',
  FSR_APPROVAL: 'FSR review',
  OFFICE_APPROVAL: 'Office review',
  PENDING_CUST_ACTION: 'Referred',
  RETURNED: 'Returned',
  COMPLETED: 'Approved at FSR',
  AGENCY_APPROVED: 'Agency approved',
  APPROVED_AT_FSR: 'Approved at FSR',
  REQUISITION_CREATED: 'Requisition created',
});

export const ReferralRequestStatusActivity = Object.freeze({
  APPROVE: 'Approve',
  DENY: 'Deny',
  KEEP: 'Do not replace',
  TURNIN: 'Turn In',
  REPLACE: 'Replace',
  COMMENT_ADDED: 'Comment added',
  COMMENT_DELETED: 'Comment deleted',
  AGENCY_APPROVAL: 'Referred to agency review',
  BUREAU_APPROVAL: 'Referred to bureau review',
  CUSTOMER_SUBMISSION: 'Customer submission',
  DRAFT: 'Draft',
  FSR_APPROVAL: 'Referred to FSR review',
  OFFICE_APPROVAL: 'Referred to office review',
  PENDING_CUST_ACTION: 'Referred',
  RETURNED: 'Returned to customer POC',
  AGENCY_APPROVED: 'Agency approved',
  APPROVED_AT_FSR: 'Approved at FSR',
  REQUISITION_CREATED: 'Requisition created',
  REQUISITION_CANCEL: 'Requisition cancelled',
  OVERRIDE_SIN: 'SIN Override',
});

export const ReferralAction = Object.freeze({
  REPLACE: 'Replace vehicle',
  TURNIN: 'Turn in vehicle',
  KEEP: 'Do not replace',
});

export const displayReferralStatus = (status) => {
  return ReferralRequestStatus[status] || status;
};

export const VEHICLE_REFERRAL_FILTERS = {
  AGENCY: 'customer_agency_code',
  BUREAU: 'customer_bureau_code',
  OFFICE: 'customer_physical_office_code',
  CUSTOMER_NUMBER: 'customer_account_id',
  LEGACY_CUSTOMER_NUMBER: 'legacy_customer_number',
  BOAC: 'boac',
  TAG: 'tag_number',
  VIN: 'serial_number_vin',
  FSR_EMAIL: 'fsr_user_email_address',
  POC_EMAIL: 'email_address',
  ACTION: 'referral_action',
  ZONE: 'zone_id',
  FMC: 'fmc_id',
  REFERRAL_STATUS: 'referral_request_status_code',
  OLD_SIN: 'standard_item_number',
  NEW_SIN: 'new_vehicle_sin',
};

export const VEHICLE_REFERRAL_REPORT = '1504';

export const getSubmitLabel = (status) => {
  const isEditStatus =
    status === VEHICLE_REFERRAL_STATUS.PENDING_CUST_ACTION ||
    status === VEHICLE_REFERRAL_STATUS.DRAFT ||
    status === VEHICLE_REFERRAL_STATUS.RETURNED;

  if (isEditStatus) {
    return 'Save and submit';
  }
  if (status === VEHICLE_REFERRAL_STATUS.OFFICE_APPROVAL) {
    return 'Submit to office reviewer';
  }
  if (status === VEHICLE_REFERRAL_STATUS.AGENCY_APPROVAL) {
    return 'Submit to agency reviewer';
  }
  if (status === VEHICLE_REFERRAL_STATUS.BUREAU_APPROVAL) {
    return 'Submit to bureau reviewer';
  }
  return 'Approve';
};

export const validateHiddenButtons = (
  approvalLevel,
  isEditable,
  isReopenUsers,
  designatedLevelUsers,
) => {
  const status = [
    VEHICLE_REFERRAL_STATUS.FSR_APPROVAL,
    VEHICLE_REFERRAL_STATUS.OFFICE_APPROVAL,
    VEHICLE_REFERRAL_STATUS.BUREAU_APPROVAL,
    VEHICLE_REFERRAL_STATUS.AGENCY_APPROVAL,
    VEHICLE_REFERRAL_STATUS.AGENCY_APPROVED,
    VEHICLE_REFERRAL_STATUS.APPROVED_AT_FSR,
    VEHICLE_REFERRAL_STATUS.COMPLETED,
  ];

  if (
    (isReopenUsers && status.includes(approvalLevel)) ||
    (designatedLevelUsers && status.includes(approvalLevel))
  ) {
    return ['cancel', 'save'];
  }

  if (isEditable) {
    return ['returnToSearch', 'reOpen'];
  }

  return ['cancel', 'save', 'submit'];
};

export const ReviewSelectedAction = ({ selectedInfo, status }) => {
  return (
    <div style={{ width: '767px' }}>
      <div className="padding-1">
        <strong>Selected action:</strong>
        <div>{displayReferralStatus(status)}</div>
      </div>
      <div className="padding-1">
        <strong>Reason:</strong>
        <div>{selectedInfo?.selectedOption?.value}</div>
      </div>
      <div className="padding-1">
        <strong>Additional information for reason selected:</strong>
        <div>{selectedInfo?.comment}</div>
      </div>
    </div>
  );
};

export const TURN_IN_OPTIONS = [
  {
    label: 'Agency is downsizing due to budget',
    value: 'Agency is downsizing due to budget',
  },
  {
    label: 'Vehicle is underutilized',
    value: 'Vehicle is underutilized',
  },
  {
    label: 'Vehicle will be replaced with agency owned vehicle',
    value: 'Vehicle will be replaced with agency owned vehicle',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const DO_NOT_REPLACE_OPTIONS = [
  {
    label:
      'Added equipment on vehicle: vehicle has added equipment that is expensive or difficult to replace',
    value:
      'Added equipment on vehicle: vehicle has added equipment that is expensive or difficult to replace',
  },
  {
    label:
      'Change in vehicle utilization: vehicle is utilized in a new way that has decreased utilization; lowering expected miles and impacting eligibility',
    value:
      'Change in vehicle utilization: vehicle is utilized in a new way that has decreased utilization; lowering expected miles and impacting eligibility',
  },
  {
    label:
      'Lack of vehicle availability: the SIN and/or options customer requires are not available during this acquisition year',
    value:
      'Lack of vehicle availability: the SIN and/or options customer requires are not available during this acquisition year',
  },
  {
    label:
      'Recent vehicle enhancement: vehicle has M&R or new equipment that extends the life of the vehicle; i.e. a new engine',
    value:
      'Recent vehicle enhancement: vehicle has M&R or new equipment that extends the life of the vehicle; i.e. a new engine',
  },
  {
    label:
      'Mission requires make/model variety: customer needs to hold on to a vehicle to maintain diversity of undercover vehicle models ',
    value:
      'Mission requires make/model variety: customer needs to hold on to a vehicle to maintain diversity of undercover vehicle models ',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const getRequestSuccessMsg = (status, tag) => {
  switch (status) {
    case VEHICLE_REFERRAL_STATUS.RETURNED:
      return (
        <>
          You have successfully returned request for <strong>{tag}</strong>
        </>
      );
      break;
    case VEHICLE_REFERRAL_STATUS.RE_OPENED:
      return (
        <>
          You have successfully re-opened request for <strong>{tag}</strong>
        </>
      );
      break;
    default:
      return (
        <>
          You have successfully submitted the request for <strong>{tag}</strong>
        </>
      );
  }
};

export const getFormattedComment = (comment, status) => {
  if (Object.keys(ReferralRequestStatus).includes(status))
    return comment?.replace(status, ReferralRequestStatus[status]);
  return comment;
};

export const isExistInRoles = (roles, roleName) => {
  return roles?.some((role) => role?.name === roleName);
};

export const getStandardItemForReferral = (data, fiscalYear) => {
  console.groupCollapsed('getting standard item');
  console.log('given fiscal year', fiscalYear);
  console.log('all standard items returned', data.rows);

  const activeVehicle = data.rows.find((item) => item.status === 'Active');
  console.log('active vehicle found when getting standard item by sin', activeVehicle);

  const currentYearVehicle = data.rows.find((item) => item.year === fiscalYear);
  console.log('current year vehicle found when getting standard item by sin', currentYearVehicle);

  console.groupEnd();
  return currentYearVehicle || null;
}