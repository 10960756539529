import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  StepIndicator,
  Spinner,
  StatusBadge,
} from '@gsa/afp-component-library';
import ReactRouterPrompt from 'react-router-prompt';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useQueryParam } from '../../hooks/useQueryParam';
import VehicleRequisitionBreadCrumbs from './widgets/Breadcrumbs';
import { validateAddress } from '../../utilities/deliveryAddressUtils';
import VehicleInformation from './components/VehicleInformation/VehicleInformation';
import PageNavigationButtons from './components/PageNavigationButtons/PageNavigationButtons';
import {
  NonLowBidRequisitionSteps,
  LowBidRequisitionSteps,
  VALIDATION_ERRORS_SECTIONS,
  RequisitionStep,
  STEPS,
} from './constants/VehicleRequisitionConstants';
import SaveDraftRequisition from '../../components/SaveDraftRequisition/SaveDraftRequisition';
import './VehicleRequisition.scss';
import SaveDraftRequisionMessage from '../../components/SaveDraftRequistionSuccessMessage/SaveDraftRequisitionSuccessMessage';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import CompareVehicles from './components/compare-vehicles/CompareVehicles';
import PageAlerts from '../../components/PageAlert/PageAlert';
import ErrorContext from '../../context/ErrorContext/ErrorContext';
import ErrorActions from '../../context/ErrorContext/ErrorActions';
import VehicleRequisitionErrors, {
  RequisitionBoacErrors,
} from './constants/VehicleRequisitionErrors';
import { GET_PROGRAM_NAME_BY_STD_CODE } from '../../services/data-layer';
import RequisitionSteps from './RequisitionSteps';
import ContactBuyingPopover from '../../components/ContactBuyingPopover/ContactBuyingPopover';
import { resetFocusToFirstElement } from '../../utilities/commons';
import {
  addressValidations,
  initiateBoacValidation,
  otherStatusForSubmitter,
  shouldCheckBoacErrorForSubmitter,
  validateBoacResponse,
  validateFss19Response,
} from './utils/VehicleRequisitionUtils';
import CancelRequisitionModal from '../../components/CancelRequisitionModal/CancelRequisitionModal';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import {
  IN_REQ_TAG,
  PAINT_OPT,
  StoreOperations,
  StoreSubjects,
  TagsToWatch,
} from '../../constants/constants';
import SubmitRequisitionModal from '../../components/SubmitRequisitionModal/SubmitRequisitionModal';
import { withoutAREQOption } from '../../constants/utils';
import { TRANSACTION_TYPES } from '../../utilities/CompareVehicleUtils';
import { buildSelectedEngine } from '../../utilities/engineUtils';

let indicatorSteps = [];

const VehicleRequisition = ({ lastStepRef }) => {
  const {
    state,
    dispatch,
    getPurchaseCollisionInfoLoading,
    getAddressValidation,
    setShowUspsModal,
    addressValidationCompleted,
    setAddressValidationCompleted,
    handleRequisitionHasError,
    validateBoacInWallet,
    validatedBoac,
    validateBoacInWalletForAddressCode,
    validatedBoacAddressCode,
    getBoacForSignalCodeCOrL,
    boacForSignalCodeCOrL,
    updateDraftRequisition,
    updateRequisitionClientState,
    getCalculatedPriceData,
  } = useContext(VehicleRequisitionContext);

  const { state: errorState, dispatch: errorDispatch } =
    useContext(ErrorContext);

  const {
    draftRequisition,
    nonLowBidJustification,
    currentStandardItem,
    allActiveContracts,
    selectedContract,
    selectedContractAgencyInformation,
    selectedContractCostBreakdown,
    vehicleQuantity,
    vehicleColors,
    allCostBreakdown,
    selectedOptionsForPrice,
    currentStep,
    addOptionsState,
    requisitionStateContext,
    mailingStateContext,
    deliveryStateContext,
    submitRequisitionModal,
    submitRequisitionLoading,
    paintAndGraphicsOptions,
    requisitionAddressErrorContext,
    mailingAddressErrorContext,
    deliveryAddressErrorContext,
    taggedOptions,
    isNotifyModal,
    isAreq,
    reviewSubmitTab,
    availableColors,
    activeEngineInfo,
    selectedEngine,
  } = state;
  const navigate = useNavigate();
  const query = useQueryParam();
  const ability = useAppAbility();
  const isSubmitter = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.Requisition),
    [ability],
  );
  const requisitionDraftId = query.get('requisitionId');
  const standardItem =
    currentStandardItem || JSON.parse(sessionStorage.getItem('standardItem'));

  const currentSin = query.get('sin') || standardItem?.standardItemNumber;
  const mode = query.get('mode');
  const [showDraftSuccessMessage, setShowDraftSuccessMessage] = useState(false);
  const [hasTaggedOptions, setHasTaggedOptions] = useState(false);
  const [contractIndex] = useState();
  const requisitionHeaderRef = useRef(null);
  const [status, setStatus] = useState('');
  const [errorList, setErrorList] = useState([]);
  const [preventNavigation, setPreventNavigation] = useState(false);

  const [getProgramData] = useLazyQuery(GET_PROGRAM_NAME_BY_STD_CODE, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!standardItem) {
      return;
    }

    try {
      getProgramData({
        variables: { fedStdCode: standardItem.fedStandardCode },
      });
    } catch (err) {
      console.log(err.message);
    }
  }, [standardItem]);

  useEffect(() => {
    if (isNotifyModal || draftRequisition) {
      setPreventNavigation(false);
    }
  }, [isNotifyModal, draftRequisition]);

  useEffect(() => {
    if (
      currentStep?.current > 1 &&
      requisitionHeaderRef.current.scrollIntoView
    ) {
      requisitionHeaderRef.current.scrollIntoView();
    }
  }, [currentStep?.current, requisitionHeaderRef?.current]);

  const clickStepper = () => {
    // do nothing
  };
  const isTaggedOptionsExist = Boolean(taggedOptions.length);

  const setRequisitionSteps = (selectedContractIndex) => {
    let stepsForCurrentContract = [];
    // this check is to identify if Option Details step exist when draft is loaded
    const isCurrentPageOptionDetails = currentStep?.key === 'OPTION_DETAILS';
    if (selectedContractIndex > 0) {
      // Load steps for Non-low-bid
      stepsForCurrentContract = NonLowBidRequisitionSteps(
        isTaggedOptionsExist || isCurrentPageOptionDetails,
        isAreq,
        paintAndGraphicsOptions,
      );
    } else {
      // Load steps for Low-bid
      stepsForCurrentContract = LowBidRequisitionSteps(
        isTaggedOptionsExist || isCurrentPageOptionDetails,
        isAreq,
        paintAndGraphicsOptions,
      );
    }

    const nextStepIndex = currentStep.current - 1;
    for (let i = 0; i < stepsForCurrentContract.length; i++) {
      if (i < nextStepIndex) {
        stepsForCurrentContract[i].status = 'completed';
      } else if (i === nextStepIndex) {
        stepsForCurrentContract[i].status = 'current';
      } else {
        stepsForCurrentContract[i].status = 'not completed';
      }
    }
    indicatorSteps = stepsForCurrentContract;
  };

  useEffect(() => {
    if (
      currentSin &&
      selectedContract &&
      allActiveContracts &&
      allCostBreakdown
    ) {
      const selectedContractIndex = allActiveContracts.findIndex((vendor) => {
        return vendor.contractLineId === selectedContract.contractLineId;
      });
      setRequisitionSteps(selectedContractIndex);
      const contractFound = !selectedContract
        ? allActiveContracts[selectedContractIndex]
        : selectedContract;

      if (!contractFound) {
        // no contract found, then redirect back to the vehicle-listing page
        navigate('vehicle-listings');
      }

      const costBreakdownFound = allCostBreakdown.find((cb) =>
        cb && contractFound
          ? cb.contractLineId === contractFound.contractLineId
          : false,
      );

      if (!selectedContract || !selectedContractCostBreakdown) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_SELECTED_CONTRACT_INFO,
          selectedContract: contractFound,
          selectedContractCostBreakdown: costBreakdownFound,
          currentStep,
        });
      }
    }

    if (
      requisitionDraftId &&
      allActiveContracts &&
      allCostBreakdown &&
      currentStep?.current > 1
    ) {
      const { requisitionStatus, contractLineId } = draftRequisition;
      setStatus(requisitionStatus);
      const contractLineIdToUse =
        selectedContract?.contractLineId || contractLineId;

      const selectedContractIndex = allActiveContracts.findIndex(
        (vendor) =>
          parseInt(vendor.contractLineId, 10) ===
          parseInt(contractLineIdToUse, 10),
      );

      const selectedEngine = buildSelectedEngine(draftRequisition);

      if (selectedContractIndex > -1) {
        setRequisitionSteps(selectedContractIndex);
        if (!selectedContract) {
          dispatch({
            type: VehicleRequisitionContextActions.UPDATE_SELECTED_CONTRACT_INFO,
            selectedContract: allActiveContracts[selectedContractIndex],
            selectedEngine,
            selectedContractCostBreakdown:
              allCostBreakdown[selectedContractIndex],
          });
        }
      }

      if (
        (requisitionStateContext.countryCode === 'US' ||
          mailingStateContext.countryCode === 'US' ||
          deliveryStateContext.countryCode === 'US') &&
        currentStep.text === 'Delivery address'
      ) {
        //  validate onload if data is there ( ideally we should not save empty address)
        if (
          !(
            requisitionStateContext.addressLine1 === '' &&
            requisitionStateContext.city === ''
          )
        ) {
          validateAddress(
            requisitionStateContext,
            VehicleRequisitionContextActions.UPDATE_REQUISITION_ADDRESS_HAS_ERROR,
            dispatch,
          );
        }
        if (
          !(
            mailingStateContext.addressLine1 === '' &&
            mailingStateContext.city === ''
          )
        ) {
          validateAddress(
            mailingStateContext,
            VehicleRequisitionContextActions.UPDATE_REQUISITION_MAILING_HAS_ERROR,
            dispatch,
          );
        }
        if (
          !(
            deliveryStateContext.addressLine1 === '' &&
            deliveryStateContext.city === ''
          )
        ) {
          validateAddress(
            deliveryStateContext,
            VehicleRequisitionContextActions.UPDATE_REQUISITION_DELIVERY_HAS_ERROR,
            dispatch,
          );
        }
      }
    }
  }, [
    allActiveContracts,
    allCostBreakdown,
    selectedContract,
    hasTaggedOptions,
  ]);

  useEffect(() => {
    if (addOptionsState.length > 0 && isAreq) {
      const customPaintTaggedOptions = [];
      addOptionsState.forEach((list) => {
        list.options.forEach((vendOption) => {
          if (vendOption.isChecked) {
            const { optionCode, optionDescription } = vendOption;
            const checkPaintOptions = !customPaintTaggedOptions.find(
              (li) => li.optionCode === optionCode,
            );
            // custom paint tagged options
            if (
              vendOption.tags.value.includes(PAINT_OPT) &&
              checkPaintOptions
            ) {
              const opt = {
                optionCode,
                optionDescription,
                customerInputExist: true,
                customerInput: '',
                unitPrice: 0,
              };
              if (optionCode === 'CPL') {
                opt.customerCPLInput = '';
              }
              customPaintTaggedOptions.push(opt);
            }
          }
        });
      });
      // const customPaintOptionsFrmDraft =
      //   draftRequisition?.clientData?.selectedOptions?.customerInputs
      //     ?.paintAndGraphicsOptions;
      // if (draftRequisition?.requisitionId) {
      //   customPaintTaggedOptions = customPaintOptionsFrmDraft;
      // }
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_PAINT_AND_GRAPHIC_OPTIONS_DESC,
        payload: customPaintTaggedOptions,
      });
    }
    setRequisitionSteps();
  }, [isAreq, addOptionsState]);

  useEffect(() => {
    if (isAreq) {
      setRequisitionSteps();
    }
  }, [isAreq, paintAndGraphicsOptions]);

  useEffect(() => {
    if (taggedOptions.length) {
      setHasTaggedOptions(taggedOptions.length > 0);
    }
  }, [taggedOptions]);

  const handleStepper = (nextStep) => {
    const isIndex = Number.isInteger(nextStep); // can pass step key or index to handler
    const nextStepIndex = isIndex
      ? nextStep
      : indicatorSteps.findIndex(({ key }) => key === nextStep); // sets nextStepIndex

    let newStep = isIndex
      ? indicatorSteps[nextStep]
      : indicatorSteps?.find(({ key }) => key === nextStep); // sets newStep object

    if (!newStep) newStep = indicatorSteps[indicatorSteps.length - 1]; // if no newStep, set to last step
    const currentStepIndex = indicatorSteps.findIndex(
      ({ key }) => key === currentStep.key,
    );
    const goingBack = nextStepIndex < currentStepIndex;

    if (newStep?.key !== currentStep.key && draftRequisition) {
      const clientState = { ...draftRequisition.clientData.clientState };
      clientState.currentStep = newStep;
      updateDraftRequisition(draftRequisition?.requisitionId);
      updateRequisitionClientState({
        variables: {
          clientState,
          requisitionId: draftRequisition.requisitionId,
        },
      });
    }

    if (newStep?.key) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_CURRENT_REQUISITION_STEP,
        payload: {
          key: newStep?.key,
          current: newStep?.stepNumber,
          text: newStep?.label,
        },
      });
    }

    const updatedIndicatorSteps = indicatorSteps;
    for (let i = 0; i < updatedIndicatorSteps.length; i++) {
      if (i < nextStepIndex) {
        updatedIndicatorSteps[i].status = 'completed';
      } else if (i === nextStepIndex) {
        updatedIndicatorSteps[i].status = 'current';
      } else {
        updatedIndicatorSteps[i].status = 'not completed';
      }
    }

    // below are review steps, need to update currentStep when those pages are loaded
    // updateDraftRequisition will update currentStep
    const nonEditSteps = ['REVIEW_VEHICLE_BUILD', 'REVIEW_SUBMIT'];
    if (
      draftRequisition?.requisitionId &&
      !nonEditSteps.includes(currentStep?.key) &&
      !goingBack &&
      currentStepIndex !== nextStepIndex
    ) {
      updateDraftRequisition(undefined, undefined, { sop: newStep });
    }
    indicatorSteps = updatedIndicatorSteps;
  };

  const handleSOPStep = (direction) => {
    const currentStepIndex = indicatorSteps.findIndex(
      (step) => step.stepNumber === currentStep.current,
    );
    const nextStepIndex =
      direction === 'previous' ? currentStepIndex - 1 : currentStepIndex + 1;

    const nextStep = indicatorSteps[nextStepIndex];

    if (lastStepRef.current !== nextStep.key) {
      // eslint-disable-next-line no-param-reassign
      lastStepRef.current = nextStep.key;
      handleStepper(nextStepIndex);
    }
  };

  useEffect(() => {
    if (errorState[STEPS.AGENCY_INFORMATION]?.agencyInformation) {
      const boacErrors = [];
      errorState[STEPS.AGENCY_INFORMATION].agencyInformation.forEach(
        (value) => {
          boacErrors.push(<li>{value}</li>);
        },
      );

      setErrorList(boacErrors);

      if (boacErrors.length && isSubmitter) {
        if (shouldCheckBoacErrorForSubmitter(draftRequisition)) {
          handleStepper(STEPS.AGENCY_INFORMATION);
        } else if (otherStatusForSubmitter(draftRequisition)) {
          handleStepper(5);
        }
      }
    }
  }, [errorState]);

  useEffect(() => {
    if (
      boacForSignalCodeCOrL?.getBoacForFundAgencyCode &&
      isSubmitter &&
      shouldCheckBoacErrorForSubmitter(draftRequisition)
    ) {
      validateFss19Response(
        selectedContractAgencyInformation,
        boacForSignalCodeCOrL.getBoacForFundAgencyCode,
        validateBoacInWalletForAddressCode,
        errorDispatch,
        state?.draftRequisition?.agencyInfo?.isDOD,
      );
    }
  }, [boacForSignalCodeCOrL]);

  useEffect(() => {
    if (
      validatedBoac?.validateBOAC &&
      isSubmitter &&
      shouldCheckBoacErrorForSubmitter(draftRequisition)
    ) {
      validateBoacResponse(
        selectedContractAgencyInformation,
        validatedBoac.validateBOAC,
        'requisitionBOAC',
        errorDispatch,
        state?.draftRequisition?.agencyInfo?.isDOD,
      );
    }
  }, [validatedBoac]);

  useEffect(() => {
    if (
      validatedBoacAddressCode?.validateBOAC &&
      isSubmitter &&
      shouldCheckBoacErrorForSubmitter(draftRequisition)
    ) {
      validateBoacResponse(
        selectedContractAgencyInformation,
        validatedBoacAddressCode.validateBOAC,
        'signalSupplementaryAddress',
        errorDispatch,
        state?.draftRequisition?.agencyInfo?.isDOD,
      );
    }
  }, [validatedBoacAddressCode]);

  useEffect(() => {
    if (isSubmitter && shouldCheckBoacErrorForSubmitter(draftRequisition)) {
      initiateBoacValidation(
        selectedContractAgencyInformation,
        {
          validateBoacInWallet,
          validatedBoac,
          validateBoacInWalletForAddressCode,
          validatedBoacAddressCode,
          getBoacForSignalCodeCOrL,
          boacForSignalCodeCOrL,
        },
        errorDispatch,
        state?.draftRequisition?.agencyInfo?.isDOD,
      );
    }
  }, [
    selectedContractAgencyInformation?.serviceCode,
    selectedContractAgencyInformation?.fundCode,
    selectedContractAgencyInformation?.signalCode,
    selectedContractAgencyInformation?.requisitionBOAC,
    selectedContractAgencyInformation?.signalSupplementaryAddress,
  ]);
  // const isTaggedOption = (tags) => {
  //   return TagsToWatch.some((value) => tags.includes(value));
  // };

  useEffect(() => {
    // FIXME: This does not appear to clear the custom paint options when all the options are unchecked
    if (addOptionsState.length > 0 && selectedContract?.contractLineId) {
      let customPaintTaggedOptions = [];
      let optionDetailsTagged = [];
      let engineerTaggedOptions = [];
      addOptionsState.forEach((list) => {
        list.options.forEach((vendOption) => {
          if (vendOption.isChecked) {
            vendOption.vendorValues.forEach((vendorsList) => {
              if (
                parseInt(selectedContract?.contractLineId, 10) ===
                parseInt(vendorsList.contractLineId, 10)
              ) {
                const checkPaintOptions = !customPaintTaggedOptions.find(
                  (li) => li.optionCode === vendorsList.optionCode,
                );

                // custom paint tagged options
                if (
                  vendOption.tags.value.includes(PAINT_OPT) &&
                  checkPaintOptions
                ) {
                  const opt = {
                    optionCode: vendOption.optionCode,
                    optionDescription: vendOption.optionDescription,
                    customerInputExist: true,
                    customerInput: '',
                    unitPrice: vendorsList.unitPrice,
                  };
                  if (vendOption.optionCode === 'CPL') {
                    opt.customerCPLInput = '';
                  }
                  customPaintTaggedOptions.push(opt);
                }

                // Option Details tagged options - where customer input is required
                const checkTaggedOptions = !optionDetailsTagged.find(
                  (li) => li.optionCode === vendorsList.optionCode,
                );
                if (
                  vendOption.tags.value.includes(IN_REQ_TAG) &&
                  checkTaggedOptions &&
                  !vendOption.tags.value.includes(PAINT_OPT)
                ) {
                  optionDetailsTagged.push({
                    optionCode: vendOption.optionCode,
                    optionDescription: vendOption.optionDescription,
                    customerInputExist: true,
                    customerInput: '',
                    unitPrice: vendorsList.unitPrice,
                    optionQuantity: vendOption?.optionValue || null,
                  });
                }

                // Engineer review tagged options
                if (vendOption.tags.value.includes(TagsToWatch[0])) {
                  engineerTaggedOptions.push({
                    optionCode: vendOption.optionCode,
                    optionDescription: vendOption.optionDescription,
                    customerInputExist: true,
                    customerInput: '',
                    unitPrice: vendorsList.unitPrice,
                    optionQuantity: vendOption?.optionValue || null,
                  });
                }
              }
            });
          }
        });
      });

      setHasTaggedOptions(optionDetailsTagged.length > 0);

      const customPaintOptionsFrmDraft =
        draftRequisition?.clientData?.selectedOptions?.customerInputs
          ?.paintAndGraphicsOptions;
      const taggedOptionsFrmDraft =
        draftRequisition?.clientData?.selectedOptions?.customerInputs
          ?.taggedOptions;
      const engineerTaggedOptionsFrmDraft =
        draftRequisition?.clientData?.selectedOptions?.customerInputs
          ?.engineerTaggedOptions;

      if (draftRequisition?.requisitionId && !selectedContract) {
        customPaintTaggedOptions = customPaintOptionsFrmDraft;
        optionDetailsTagged = taggedOptionsFrmDraft;
        engineerTaggedOptions = engineerTaggedOptionsFrmDraft;
      }

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_TAGGED_OPTIONS,
        payload: { taggedOptions: optionDetailsTagged, engineerTaggedOptions },
      });

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_PAINT_AND_GRAPHIC_OPTIONS_DESC,
        payload: customPaintTaggedOptions,
      });
    }

    return () => {};
  }, [selectedContract, addOptionsState]);

  useEffect(() => {
    if (indicatorSteps.length > 0 && currentStep?.key) {
      if (currentStep.key !== lastStepRef.current) {
        // eslint-disable-next-line no-param-reassign
        lastStepRef.current = currentStep.key;
      }
      handleStepper(currentStep?.key);
    }
  }, [currentStep?.key, indicatorSteps]);

  useEffect(() => {
    if (
      !requisitionAddressErrorContext?.length > 0 ||
      !mailingAddressErrorContext?.length > 0 ||
      !deliveryAddressErrorContext?.length > 0
    ) {
      errorDispatch({
        type: ErrorActions.CLEAR_ERRORS,
        payload: {
          page: RequisitionStep[RequisitionStep[currentStep.current]],
        },
      });
    }
  }, []);

  const validateCurrentStep = (direction) => {
    let isValid = true;
    if (currentStep.key === STEPS.PAINT_GRAPHICS) {
      const totalSelectedVehicles = vehicleColors.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.quantity),
        0,
      );
      const areAllColorsValid =
        totalSelectedVehicles === Number(vehicleQuantity) &&
        vehicleColors?.filter((i) => !!i.color?.label).length ===
          vehicleColors?.length;
      const isCPT = paintAndGraphicsOptions?.some(
        (opt) => opt.customerInputExist,
      );
      if ((!vehicleColors?.length || !areAllColorsValid) && !isAreq && !isCPT) {
        errorDispatch({
          type: ErrorActions.ADD_ERROR,
          payload: {
            page: RequisitionStep.COLOR_SELECTION,
            form: 'colors',
            error: VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS],
          },
        });
        isValid = false;
      }

      if (direction === 'previous') {
        errorDispatch({
          type: ErrorActions.CLEAR_ERRORS,
          payload: {
            page: RequisitionStep.COLOR_SELECTION,
            form: 'colors',
            error: VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS],
          },
        });

        errorDispatch({
          type: ErrorActions.CLEAR_ERRORS,
          payload: {
            page: RequisitionStep.COLOR_SELECTION,
            form: 'NO_COLORS',
            error: (
              <span>
                Please select another vehicle or contact our Vehicle Buying Team
                at 866-472-1200 or{' '}
                <a href="https://vehicle.buying@gsa.gov">
                  vehicle.buying@gsa.gov
                </a>{' '}
                for assistance.
              </span>
            ),
          },
        });

        if (paintAndGraphicsOptions && errorState?.commentSection?.comment) {
          paintAndGraphicsOptions.forEach((item) => {
            errorDispatch({
              type: ErrorActions.REMOVE_ERROR,
              payload: {
                page: RequisitionStep.COMMENTS_SECTION,
                form: 'comment',
                error: `${
                  VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS]
                }#${item.optionCode}`,
              },
            });
            if (errorState?.commentSection?.comment) {
              errorDispatch({
                type: ErrorActions.REMOVE_ERROR,
                payload: {
                  page: RequisitionStep.COMMENTS_SECTION,
                  form: 'comment',
                  error: `${
                    VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS]
                  }#${item.optionCode}$CPL`,
                },
              });
            }
          });
        }
      }

      if (paintAndGraphicsOptions && direction !== 'previous') {
        paintAndGraphicsOptions.forEach((list) => {
          if (
            (list?.customerInput && list?.customerInput?.length < 1) ||
            list?.customerInput?.length === 0
          ) {
            errorDispatch({
              type: ErrorActions.ADD_ERROR,
              payload: {
                page: RequisitionStep.COMMENTS_SECTION,
                form: 'comment',
                error: `${
                  VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS]
                }#${list.optionCode}`,
              },
            });
            isValid = false;
          }
          if (
            list.optionCode === 'CPL' &&
            list?.customerCPLInput?.length === 0
          ) {
            errorDispatch({
              type: ErrorActions.ADD_ERROR,
              payload: {
                page: RequisitionStep.COMMENTS_SECTION,
                form: 'comment',
                error: `${
                  VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS]
                }#${list.optionCode}#CPL`,
              },
            });
            isValid = false;
          }
        });
      }
    }

    if (
      currentStep.key === STEPS.NON_LOW_BID_REASON &&
      direction !== 'previous'
    ) {
      if (nonLowBidJustification?.length === 0) {
        errorDispatch({
          type: ErrorActions.ADD_ERROR,
          payload: {
            page: STEPS.NON_LOW_BID_REASON,
            form: 'nonLowBidJustification',
            error: VALIDATION_ERRORS_SECTIONS.JUSTIFICATION,
          },
        });
        isValid = false;
      }
    }

    if (currentStep.key === STEPS.OPTION_DETAILS) {
      const optionDetails = errorState[STEPS.OPTION_DETAILS];
      const optionDetailsError = !optionDetails
        ? true
        : Object.values(optionDetails)?.some(({ size }) => size > 0);
      isValid = !optionDetailsError;
      state?.taggedOptions?.forEach(({ optionCode, customerInput }) => {
        if (!customerInput) {
          errorDispatch({
            type: ErrorActions.ADD_ERROR,
            payload: {
              page: STEPS.OPTION_DETAILS,
              form: optionCode,
              error: `error in option code ${optionCode}`,
            },
          });
          isValid = false;
        }
      });
    }

    if (!isValid) {
      return isValid;
    }

    errorDispatch({
      type: ErrorActions.CLEAR_ERRORS,
      payload: {
        page: RequisitionStep[RequisitionStep[currentStep.current]],
      },
    });
    return true;
  };

  const REQUISITION_ADDRESS = 'REQUISITION_ADDRESS';
  const MAILING_ADDRESS = 'MAILING_ADDRESS';
  const DELIVERY_ADDRESS = 'DELIVERY_ADDRESS';

  const performAddressValidation = () => {
    if (!requisitionStateContext.isMilitary) {
      getAddressValidation(
        REQUISITION_ADDRESS,
        addressValidations.getAddressValidationVariables(
          requisitionStateContext,
        ),
      );
    }
    if (!mailingStateContext.isMilitary) {
      getAddressValidation(
        MAILING_ADDRESS,
        addressValidations.getAddressValidationVariables(mailingStateContext),
      );
    }
    if (deliveryStateContext && deliveryStateContext?.countryCode === 'US') {
      getAddressValidation(
        DELIVERY_ADDRESS,
        addressValidations.getAddressValidationVariables(deliveryStateContext),
      );
    }
  };

  const initAddressValidation = async () => {
    setShowUspsModal(false);
    setAddressValidationCompleted(false);
    await dispatch({
      type: VehicleRequisitionContextActions.RESET_USPS_VALIDATION,
    });
  };

  const clickPaginationHandler = (direction, isFromModal) => {
    let hasError = false;

    const checkIfExport = selectedOptionsForPrice.some(
      (li) => li.optionCode === '1611',
    );

    const validScreen = validateCurrentStep(direction);
    if (!validScreen && direction !== 'previous') {
      return false;
    }

    if (
      currentStep.key === STEPS.DELIVERY_ADDRESS &&
      direction !== 'previous'
    ) {
      const reqErrors = addressValidations.getAddressErrors(
        requisitionStateContext,
        checkIfExport,
      );
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_REQUISITION_ADDRESS_HAS_ERROR,
        payload: reqErrors,
      });

      const mailingErrors = addressValidations.getAddressErrors(
        mailingStateContext,
        checkIfExport,
      );
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_REQUISITION_MAILING_HAS_ERROR,
        payload: mailingErrors,
      });

      const deliveryErrors = addressValidations.getAddressErrors(
        deliveryStateContext,
        checkIfExport,
      );
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_REQUISITION_DELIVERY_HAS_ERROR,
        payload: deliveryErrors,
      });

      hasError =
        reqErrors.length > 0 ||
        mailingErrors.length > 0 ||
        deliveryErrors.length > 0;
      if (hasError) {
        errorDispatch({
          type: ErrorActions.ADD_ERROR,
          payload: {
            page: RequisitionStep.DELIVERY_ADDRESS,
            form: 'address',
            error: VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.ADDRESS],
          },
        });
        return false;
      }
    } else {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_REQUISITION_DELIVERY_HAS_ERROR,
        payload: [],
      });

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_REQUISITION_ADDRESS_HAS_ERROR,
        payload: [],
      });

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_REQUISITION_MAILING_HAS_ERROR,
        payload: [],
      });

      handleSOPStep(direction);
    }
    initAddressValidation();

    if (currentStep.key === STEPS.PAINT_GRAPHICS && direction !== 'previous') {
      getCalculatedPriceData({
        variables: {
          standardItemId: +currentStandardItem.id,
          quantity: Number(vehicleQuantity),
          selectedOptions: withoutAREQOption(selectedOptionsForPrice),
          ...(vehicleColors.length > 0 && {
            colorPriceInfo: {
              contractLineId: selectedContract.contractLineId,
              priceToCustomer: vehicleColors[0].color.price,
              priceToGsa: vehicleColors[0].color.vendorPrice,
            },
          }),
          transactionType: TRANSACTION_TYPES.PURCHASING,
        },
      });
    }

    // check to exclude if the delivery option code 1611 from usps validation
    const uspsDeliveryOption = selectedOptionsForPrice.find(
      (option) => option.optionCode === '1611',
    );

    const reqToBeValidated =
      requisitionStateContext.countryCode === 'US' &&
      !requisitionStateContext?.isMilitary;
    const mailingToBeValidated =
      mailingStateContext.countryCode === 'US' &&
      !mailingStateContext?.isMilitary;
    const deliveryToBeValidated = deliveryStateContext.countryCode === 'US';

    if (
      (reqToBeValidated || mailingToBeValidated || deliveryToBeValidated) &&
      currentStep.key === STEPS.DELIVERY_ADDRESS &&
      direction !== 'previous' &&
      !isFromModal &&
      (reqToBeValidated || mailingToBeValidated || !uspsDeliveryOption)
    ) {
      performAddressValidation();
    } else {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_ADDRESS_VERIFICATION_MODAL,
        payload: false,
      });
      if (direction === 'previous') {
        handleSOPStep(direction);
      }

      handleRequisitionHasError([]);

      if (direction === 'continue') {
        handleSOPStep(direction);
        resetFocusToFirstElement('create-req-title');
      }
    }

    return null;
  };

  function continueAfterAddressValidation(isValidationCompleted, step) {
    if (isValidationCompleted && step.key === STEPS.DELIVERY_ADDRESS) {
      clickPaginationHandler('continue', true);
    }
  }

  useEffect(() => {
    continueAfterAddressValidation(addressValidationCompleted, currentStep);
  }, [addressValidationCompleted, currentStep, contractIndex]);

  const saveDraftSuccess = (id) => {
    setShowDraftSuccessMessage(true);
    setTimeout(() => {
      navigate(`/vehicle-requisition?requisitionId=${id}&mode=${mode}`);
    });
    setTimeout(() => {
      setShowDraftSuccessMessage(false);
    }, 10000);
  };

  const submitRequisitionSuccess = () => {
    setTimeout(() => {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_SUBMIT_REQUISITION_LOADING,
        payload: false,
      });
    }, 10000);
  };

  const draftViewTitle = (draftTitle) => {
    return <>{draftTitle}</>;
  };

  const isAgencyInformationErrorEmpty = () => {
    return !(
      errorState &&
      errorState[STEPS.AGENCY_INFORMATION]?.agencyInformation &&
      errorState[STEPS.AGENCY_INFORMATION]?.agencyInformation?.size > 0
    );
  };

  const bannerSelect = (errors) => {
    if (errors?.commentSection?.comment?.size) {
      return 3;
    }
    if (currentStep.key === 'DELIVERY_ADDRESS') {
      return 6;
    }
    return 2;
  };

  const displayOverallBanner = bannerSelect(errorState);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <VehicleRequisitionBreadCrumbs mode={mode} />
        <ContactBuyingPopover />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        {!isAgencyInformationErrorEmpty() ? (
          <ErrorMessage
            id="requisition"
            alert={
              <>
                {RequisitionBoacErrors.title}
                {errorList}
                {RequisitionBoacErrors.footer}
              </>
            }
            heading={RequisitionBoacErrors.heading}
          />
        ) : null}
      </div>
      <PageAlerts
        overall
        page={RequisitionStep[RequisitionStep[displayOverallBanner]]}
      />
      {showDraftSuccessMessage && <SaveDraftRequisionMessage />}

      {submitRequisitionLoading && (
        <div className="afp-modal-overlay requisition-loading">
          <Spinner size="large" className="margin-y-8" />
        </div>
      )}
      {!getPurchaseCollisionInfoLoading &&
        !!allActiveContracts &&
        !!currentStep &&
        currentStep.current === 1 && (
          <CompareVehicles setPreventNavigation={setPreventNavigation} />
        )}
      {!!currentStep && !!indicatorSteps && currentStep.current > 1 && (
        <>
          <div
            ref={requisitionHeaderRef}
            className="vehicle-requisition-page-header-row"
          >
            <div className="create-req-title" tabIndex="0" role="tab">
              <h1 className="usa-h1" data-testid="create-requisition__title">
                {requisitionDraftId
                  ? draftViewTitle(draftRequisition?.friendlyName)
                  : 'Create a Requisition'}
                <span className="status-badge">
                  {(status === 'RETURNED' ||
                    draftRequisition?.requisitionStatus === 'RETURNED') && (
                    <StatusBadge variant="Urgent-Gray">Returned</StatusBadge>
                  )}
                  {(status === 'DRAFT' ||
                    draftRequisition?.requisitionStatus === 'DRAFT') && (
                    <StatusBadge variant="Warning-Gray">Draft</StatusBadge>
                  )}
                </span>
              </h1>
            </div>
            <SaveDraftRequisition
              sin={currentSin}
              saveDraftSuccess={saveDraftSuccess}
              quantity={vehicleQuantity}
              nonLowBidJustification={nonLowBidJustification}
              currentStandardItem={standardItem}
              selectedContract={selectedContract}
              setPreventNavigation={setPreventNavigation}
            />
          </div>

          <StepIndicator
            steps={indicatorSteps}
            counterSize="big"
            heading={currentStep}
            onClick={clickStepper}
            className="vehicle-requisition-stepper-no-pointer"
          />

          <div tabIndex="0" role="tab" className="grid-row">
            {reviewSubmitTab !== 'Attachments' && (
              <div className="grid-col-3">
                <VehicleInformation
                  contract={selectedContract}
                  quantity={Number(vehicleQuantity)}
                  standardItem={standardItem}
                  engine={selectedEngine}
                />
              </div>
            )}
            <div
              className={
                reviewSubmitTab !== 'Attachments'
                  ? 'grid-col-9 padding-x-3'
                  : 'grid-col-12'
              }
            >
              <RequisitionSteps
                clickPaginationHandler={clickPaginationHandler}
                setPreventNavigation={setPreventNavigation}
                preventNavigation={preventNavigation}
                handleStepper={handleStepper}
                allSteps={indicatorSteps}
                indicatorSteps={indicatorSteps}
              />
              {currentStep.key !== STEPS.AREQ_INFORMATION &&
                currentStep.key !== STEPS.AGENCY_INFORMATION && (
                  <PageNavigationButtons
                    handlePageNavigation={clickPaginationHandler}
                    isLastStep={currentStep?.current === indicatorSteps?.length}
                    hideNextButton={
                      currentStep.key === STEPS.PAINT_GRAPHICS &&
                      availableColors.length === 0 &&
                      !isAreq
                    }
                  />
                )}
            </div>
          </div>
        </>
      )}

      <ReactRouterPrompt when={preventNavigation}>
        {({ onConfirm, onCancel }) => (
          <>
            <SaveDraftRequisition
              sin={currentSin}
              saveDraftSuccess={saveDraftSuccess}
              onConfirm={onConfirm}
              onCancel={onCancel}
              isForUnsaveChanges
            />
          </>
        )}
      </ReactRouterPrompt>
      
      {submitRequisitionModal && (
        <SubmitRequisitionModal
          sin={currentSin}
          submitRequisitionSuccess={submitRequisitionSuccess}
          setPreventNavigation={setPreventNavigation}
        />
      )}
    </>
  );
};

export default VehicleRequisition;

VehicleRequisition.propTypes = {
  lastStepRef: PropTypes.shape({
    current: PropTypes.string,
  }).isRequired,
};
