import { Modal, Button, connectModal, Alert } from '@gsa/afp-component-library';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setDisplayReqValidationModal } from '../../../../../reducers/leasing.jsx';

const VALIDATION_MESSAGES = {
  NO_ERRORS: {
    title: 'No validation errors found.',
    subtitle: 'Your requisition has no issues.',
  },
  SIN_EXPIRED: {
    title: "We're sorry, the Standard Item Number for this vehicle has become unavailable",
    subtitle: 'Since the Standard Item Number for this vehicle has become unavailable, you will have to cancel this requisition to continue. To select another Standard Item Number, you will have to start a new requisition.',
  },
  VEHICLE_CHANGES: {
    title: "This order cannot be placed. The following selections are no longer available.",
    subtitle: ``,
  },
  VEHICLE_UNAVAILABLE: {
    title: 'This order cannot be placed. The vehicle you previously selected is no longer available.',
    subtitle: '',
  },
  OTHER_VALIDATION_ERRORS: {
    title: 'This order cannot be placed. The following section(s) has issues',
    subtitle: '',
  }
};

export function RequisitionValidationModal({ onClose, requisitionCartValidations }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    onClose();
    dispatch(setDisplayReqValidationModal(false));
  }
  const validationsToDisplay = useMemo(() => {
    if (!requisitionCartValidations || Object.keys(requisitionCartValidations).length === 0) {
      return { ...VALIDATION_MESSAGES.NO_ERRORS, validationErrors: [] };
    }

    const {
      systemAvailability,
      lowPriceChange,
      modelYearChange,
      purchaseFeeChange,
      optionPricesChanges,
      optionsUnavailable,
      optionCollisionChanges,
      vehicleAvailability,
    } = requisitionCartValidations;

    if (systemAvailability?.isSINexpired) {
      return {
        ...VALIDATION_MESSAGES.SIN_EXPIRED,
        validationErrors: requisitionCartValidations.validationErrors || [],
      };
    }
    if (vehicleAvailability?.isContractExpired) {
      return {
        ...VALIDATION_MESSAGES.VEHICLE_UNAVAILABLE,
        validationErrors: requisitionCartValidations.validationErrors || [],
      };
    }
    const hasChanges = [
      lowPriceChange?.oldLowPrice != null && lowPriceChange?.newLowPrice != null,
      modelYearChange?.oldModelYear != null && modelYearChange?.newModelYear != null,
      purchaseFeeChange?.oldPurchaseRate != null && purchaseFeeChange?.newPurchaseRate != null,
      optionPricesChanges?.length > 0,
      optionCollisionChanges?.length > 0,
    ].some(Boolean);

    if(optionsUnavailable.length > 0) {
      const optionsNotAvailable = optionsUnavailable?.map((option) => {
        return option.optionCode + ' - ' + option.optionDescription;
      });
      return {
        ...VALIDATION_MESSAGES.VEHICLE_CHANGES,
        validationErrors:optionsNotAvailable || []
      }
    }

    if (hasChanges) {
      return {
        ...VALIDATION_MESSAGES.VEHICLE_CHANGES,
        validationErrors: requisitionCartValidations.validationErrors || [],
      };
    }

    return {
      ...VALIDATION_MESSAGES.OTHER_VALIDATION_ERRORS,
      validationErrors: requisitionCartValidations.validationErrors || [],
    };
  }, [requisitionCartValidations]);

  return (
    <Modal
      variant="large"
      className="requisition-validation-modal"
      onClose={handleClose}
      actions={
        <div>
          <Button variant="primary" onClick={handleClose} label="OK" />
        </div>
      }
    >
      <div className="modal-content">
        <div>
          <Alert type="error" slim>
            <div className="modal-content-title">
              <span><strong>{validationsToDisplay.title}</strong></span>
            </div>
          </Alert>
        </div>
        <div className="modal-content-body">
          {validationsToDisplay.validationErrors.map((item, index) => (
            <div className="validation-error-display" key={`warning-${index}`}>
              <ul>
                <li className="error-item">{item}</li>
              </ul>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default connectModal(RequisitionValidationModal);
