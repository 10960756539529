import React, { useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import {
  formatCurrency,
  TRANSACTION_TYPES,
} from '../../../utilities/CompareVehicleUtils';
import {
  getAdditionalMaterialsFromSelectedContractCostBreakdown,
  getFormattedValue,
  getPriceDataFromRequisition,
} from '../../../pages/VehicleRequisition/utils/ReviewDetailsUtils';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';

import './CostSummary.scss';
import { LeasingSteps } from '@/pages/leasing/leasing-consts.js';
import SectionEditButton from '@/components/ReviewComponents/SectionEditButton/SectionEditButton.jsx';
import { STEPS } from '@/pages/VehicleRequisition/constants/VehicleRequisitionConstants.jsx';
import { useSelector } from 'react-redux';

const CostSummary = ({
  calculatedPriceData,
  isEditable,
  isAreq,
  requisition,
  selectedContractCostBreakdown,
  transactionType = 'VEHICLE_SALE',
  onEdit,
}) => {
  const { costSummary } = useSelector((state) => state.purchaseReducer);

  let selectedVendorQuoteConst = {
    areqPriceWithFee: null,
    totalSellingPrice: calculatedPriceData?.totalSellingPrice || 0,
  };
  const context = useContext(VehicleRequisitionContext);
  if (transactionType !== TRANSACTION_TYPES.LEASING) {
    selectedVendorQuoteConst = context.selectedVendorQuote;
  }

  const GSA_PURCHASE_FEE =
    costSummary?.purchaseRate &&
    costSummary?.purchaseRate > 1.0
      ? `${(costSummary?.purchaseRate * 100 - 100).toPrecision()}%`
      : '2%';

  const additionalMaterials =
    getAdditionalMaterialsFromSelectedContractCostBreakdown(
      calculatedPriceData,
    );

  const requisitionCalculatedData = getPriceDataFromRequisition(requisition);

  return (
    <>
      <div role="tab" tabIndex="0" className="cost-summary">
        <div className="title-section">
          Cost summary
          <SectionEditButton
            showButton={isEditable}
            id="options-per-vehicle-edit"
            step={
              transactionType === 'VEHICLE_LEASE'
                ? LeasingSteps.COMPARE_AND_SELECT
                : STEPS.COMPARE_SELECT
            }
            editPage={onEdit}
          />
        </div>

        {isAreq && !selectedVendorQuoteConst ? (
          <>
            <div className="vehicle-common-section-no-border">
              <span className="label-left margin-right-10">
                <span className="margin-right-10">Quantity of vehicles</span>
              </span>
              <span data-testid="quantity-of-vehicle" className="value-right">
                {costSummary?.quantity || 0}
              </span>
            </div>
            <div className="total-price">
              <span className="total-price-label">
                Total price{' '}
                <label htmlFor="totalPrice" className="total-price-label-info">
                  Total price will be calculated after vehicle and AREQ
                  selection.
                </label>
              </span>
              <span data-testid="total-price" className="price-value">
                --
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="info-line">
              <span>Base</span>
              <span className="price-value">
                {formatCurrency(costSummary?.basePrice || 0)}
              </span>
            </div>

            {costSummary?.perVehicleColorPrice > 0 && (
              <div className="info-line">
                <span>Premium color</span>
                <span className="price-value">
                  {formatCurrency(costSummary?.perVehicleColorPrice || 0)}
                </span>
              </div>
            )}

            <div className="info-line">
              <span>Total vehicle options</span>
              <span className="price-value">
                {formatCurrency(costSummary?.perVehicleOptionsPrice || 0)}
              </span>
            </div>

            {costSummary?.areqPriceWithFee > 0 && (
              <div className="vehicle-common-section-no-border">
                <span>Total AREQ amount</span>
                <span className="price-value">
                  {formatCurrency(costSummary.areqPriceWithFee)}
                </span>
              </div>
            )}

            {additionalMaterials.length > 0 && (
              <>
                <div className="additional-materials">
                  <div className="additional-material-title bordered-bottom labeled-left">
                    Additional materials (per order)
                  </div>
                  {additionalMaterials.map((priceOrderList) => {
                    return (
                      <div
                        className="common-sub-section bordered-bottom"
                        key={uuidv4()}
                      >
                        <span>
                          {priceOrderList.optionCode} -{' '}
                          {priceOrderList.optionDescription}
                          {priceOrderList.quantity > 1
                            ? ` x ${priceOrderList.quantity}`
                            : ''}
                        </span>
                        <span className="price-value">
                          {getFormattedValue(
                            priceOrderList.optionType,
                            priceOrderList.optionTotalPrice,
                          )}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="vehicle-common-section total-material bordered-bottom label-left">
                  <div className="vehicle-common-label">
                    Total materials price
                  </div>
                  <div className="vehicle-common-value">
                    <span className="price-value">
                      {formatCurrency(costSummary?.perOrderOptionsPrice || 0)}
                    </span>
                  </div>
                </div>
                <hr />
              </>
            )}

            <div className="vehicle-common-section-no-border subtotal-section label-left">
              <span>
                Subtotal unit price{' '}
                <label htmlFor="subTotal" className="subtotal-unit-price-info">
                  (Base+Options
                  {transactionType === 'VEHICLE_LEASE'
                    ? isAreq && `+AREQ`
                    : isAreq && `+AREQ+${GSA_PURCHASE_FEE} GSA purchasing fee`}
                  )
                </label>
              </span>
              <span data-testid="subtotal-unit-price" className="price-value">
                {formatCurrency(costSummary?.baseAndOptionsPrice || 0)}
              </span>
            </div>

            <div className="vehicle-common-section-no-border label-left">
              <span className="vehicle-common-label">Quantity of vehicles</span>
              <span
                data-testid="quantity-of-vehicle"
                className="price-value vehicle-common-value"
              >
                {costSummary?.quantity || 0}
              </span>
            </div>
            <div className="total-price">
              <span className="total-price-label">
                Total price{' '}
                <label htmlFor="totalPrice" className="total-price-label-info">
                  [Base+Options{isAreq && '+AREQ '}{' '}
                  {transactionType === 'VEHICLE_LEASE'
                    ? ''
                    : `+ ${GSA_PURCHASE_FEE} GSA
                  purchasing fee]`}{' '}
                  x [Quantity] + Additional materials
                </label>
              </span>
              <span data-testid="total-price" className="price-value">
                {formatCurrency(costSummary?.totalSellingPrice || 0)}
              </span>
            </div>
          </>
        )}
        {transactionType === 'VEHICLE_LEASE' &&
          selectedContractCostBreakdown && (
            <div className="leasing-summary-section">
              <div className="title-section">
                <span>
                  Lease rates {''}
                  <label
                    htmlFor="leaseRates"
                    className="lease-summary-label-info"
                  >
                    (Rates estimated at time of contract award; actual rates may
                    vary)
                  </label>
                </span>
              </div>
              <div className="lease-data-label">
                <span>Base monthly lease rate</span>
                <span>
                  {formatCurrency(
                    selectedContractCostBreakdown?.leaseRates?.monthlyLeaseRate,
                  ) || '-'}
                </span>
              </div>
              <div className="lease-data-label">
                <span>Base mileage rate</span>
                <span className="price-value">
                  {formatCurrency(
                    selectedContractCostBreakdown?.leaseRates?.mileageRate,
                  ) || '-'}
                </span>
              </div>
              <div className="lease-data-label">
                <span>Optional equipment rate</span>
                <span className="price-value">
                  {formatCurrency(
                    selectedContractCostBreakdown?.leaseRates
                      ?.optionalEquipmentRate,
                  ) || '-'}
                </span>
              </div>
              <div className="lease-data-label">
                <span>AFV incremental cost</span>
                <span className="price-value">
                  {formatCurrency(selectedContractCostBreakdown?.afvTotal) ||
                    '-'}
                </span>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

CostSummary.propTypes = {
  calculatedPriceData: PropTypes.instanceOf(Object).isRequired,
  requisition: PropTypes.instanceOf(Object).isRequired,
  isAreq: PropTypes.bool,
  selectedContractCostBreakdown: PropTypes.instanceOf(Object).isRequired,
};

CostSummary.defaultProps = {
  isAreq: false,
};

export default CostSummary;
