import React, { useEffect, useContext, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  useModal,
  connectModal,
  Alert,
} from '@gsa/afp-component-library';
import {
  useAppAbility,
  useCurrentUser,
  useTitle,
} from '@gsa/afp-shared-ui-utils';
import {
  CREATE_REQUISITION_COMMENT,
  IS_REQ_PREVIOUSLY_RETURNED,
  GET_REQUISITION_ACTIVITIES,
  GET_STANDARD_CODES,
} from '../../../services/data-layer';
import VehicleInformation from '../../VehicleRequisition/components/VehicleInformation/VehicleInformation';
import ReviewSummaryMessage from './ReviewSummaryMessage';
import {
  modifyAgencyInfoData,
  RequisitionStatus,
  RequisitionTransactionType,
} from '../RequisitionDetailsUtils';
import CommentModal from '../../../components/CommentModal/CommentModal';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import { resetFocusToFirstElement } from '../../../utilities/commons';
import SystemAlert from '../../../components/SystemAlert/SystemAlert';
import ActionButtons from '../ActionButtons';
import { canEditInReview } from '../../../utilities/requisitionReviewUtils';
import {
  groupOptionsPerCatalog2,
  initiateBoacValidation,
  shouldCheckBoacErrorForApprover,
  shouldCheckBoacErrorForEngineer,
  validateBoacResponse,
  validateFss19Response,
} from '../../VehicleRequisition/utils/VehicleRequisitionUtils';
import ErrorContext from '../../../context/ErrorContext/ErrorContext';
import MultipleVendorsAcceptance from '../../../components/MultipleVendorsAcceptance/MultipleVendorsAcceptance';
import './ReviewSummaryTab.scss';
import ReviewSummaryOnHoldHeader from './ReviewSummaryOnHoldHeader/ReviewSummaryOnHoldHeader';
import { RequisitionPrintPreviewModal } from '../../../components/RequisitionPrintPreviewModal/RequisitionPrintPreviewModal';
import { AccordionUtilComponent } from '../../../utilities/AccordionUtilComponent';
import PaintAndGraphics from '../../../components/ReviewComponents/PaintAndGraphics/PaintAndGraphics';
import CostSummary from '../../../components/ReviewComponents/CostSummary/CostSummary';
import OptionsPerVehicle from '../../../components/ReviewComponents/OptionsPerVehicle/OptionsPerVehicle';
import Justification from '../../../components/ReviewComponents/Justification/Justification';
import AddressReviewGroup from '../../../components/ReviewComponents/AddressReviewGroup/AddressReviewGroup';
import AgencyFinanceInfo from '../../../components/ReviewComponents/AgencyFinancial/AgencyFinancialInformation';
import TaggedOptionDetails from '../../../components/ReviewComponents/TaggedOptionDetails/TaggedOptionDetails';
import AreqDetails from '../../../components/Areq/AreqDetails/AreqDetails';
import MultipleVendorInstructions from './MultipleVendorInstructions/MultipleVendorInstructions';
import SelectedVendorAcceptance from '../../../components/SelectedVendorAcceptance/SelectedVendorAcceptance';
import { convertNumToWors } from '../../requisitions-and-orders/change-sets/DeliveryAddress/SectionErrors';
import AreqAddMultipleVendors from '../../areqMultipleVendors/AreqAddMultipleVendors/AreqAddMultipleVendors';
import { ReviewSummaryMultipleAdditionalRequirements } from './MultipleAdditionalRequirements/ReviewSummaryMultipleAdditionalRequirements';
import { ReviewSummaryTaskList } from './ReviewSummaryTaskList/ReviewSummaryTaskList';
import { filterOptions } from '../../../components/ReviewComponents/ReviewOptions/reviewOptionsUtil';
import {
  REQUISITION_BUTTON_DROPDOWN_ACTIONS,
  RequisitionActionsButtonDropdown,
} from '../../../components/RequisitionActions/RequisitionActionsButtonDropdown/RequisitionActionsButtonDropdown';
import {
  getSimplifiedContract,
  OPTION_QUANTITY_REQUIRED_CODE,
} from '../../../utilities/CompareVehicleUtils';
import { isAreqType } from '../../non-standard-purchase/constants';
import { StoreOperations, StoreSubjects } from '../../../constants/constants';
import RequisitionMessageLines from './ReqMessageLines/RequisitionMessageLines';
import { getFullVendorQuotes } from '../../../components/MultipleVendorsAcceptance/vendorQuoteUtil';
import { UserRoles } from '../../../constants/user-constants';
import { displayMessageLines } from './reviewSummaryTabUtils';
import { buildSelectedEngine } from '../../../utilities/engineUtils';
import { setCostSummary } from '../../../reducers/purchase';
import { useDispatch } from 'react-redux';

const ReviewSummaryTab = ({
  setTab,
  requisitionId,
  requisitionContract,
  requisitionStandardItem,
  requisitionCartState,
  setRequisitionCartState,
  standardItemData,
  perVehicleOptionsState,
  paintAndGraphicsOptionsState,
  taggedOptionsState,
  totalUploadedFiles,
  requisitionCartValidations,
  calculatedPriceData,
  toggleViewSelectedOptions = false,
  activeContracts,
  allModelCostBreakDown,
  attachmentTypes,
  optionalReqData,
}) => {
  const { vendorQuotes, deliveryDealershipCode } = requisitionCartState;
  const {
    state,
    dispatch,
    validateBoacInWallet,
    validatedBoac,
    validateBoacInWalletForAddressCode,
    validatedBoacAddressCode,
    getBoacForSignalCodeCOrL,
    boacForSignalCodeCOrL,
    selectedVendorQuote,
    setSelectedVendorQuote,
    setIsAreq: dispatchIsAreq,
  } = useContext(VehicleRequisitionContext);

  const { dispatch: errorDispatch } = useContext(ErrorContext);
  const ability = useAppAbility();
  const { currentUser } = useCurrentUser();
  const isApprover = useMemo(
    () => ability?.can(StoreOperations.Approve, StoreSubjects.Requisition),
    [ability],
  );
  const isSubmitter = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.Requisition),
    [ability],
  );
  const isCustomer = isApprover || isSubmitter;
  const isEngineer = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.CatalogCodes),
    [ability],
  );
  const isContractingOfficer = currentUser?.roles?.some(
    (role) => role.name === UserRoles.CONTRACTING_OFFICER,
  );
  const canSubmitOrder = useMemo(() => {
    return ability?.can(StoreOperations.Create, StoreSubjects.Order);
  }, [ability]);
  const isOrderingAdmin = currentUser?.roles?.some(
    (role) => role.name === UserRoles.ORDERING_ADMIN,
  );
  const isAdmin = currentUser?.roles?.some(
    (role) => role.name === UserRoles.SITE_ADMIN,
  );
  const status = requisitionCartState.requisitionStatus; // || {};

  const inEngineerApprovalStatus =
    status === RequisitionStatus.ENGINEERING_APPROVAL;
  const inPendingCustomerResponseStatus =
    status === RequisitionStatus.PENDING_CUSTOMER_RESPONSE;
  const inOrderAdminApprovalStatus =
    status === RequisitionStatus.ORDERING_ADMIN_APPROVAL;
  const isCOandCoApproval =
    (isContractingOfficer || isAdmin || isOrderingAdmin) &&
    status === RequisitionStatus.CONTRACTING_APPROVAL;
  const isCOandCoReview =
    (isContractingOfficer || isAdmin || isOrderingAdmin) &&
    status === RequisitionStatus.CONTRACTING_REVIEW;
  const isFinalApproval = status === RequisitionStatus.FINAL_APPROVAL;
  const isNot_CO_Engineer = !isContractingOfficer && !isEngineer;
  const allowComments = true;

  const { paintAndGraphicsOptions } = state;
  useEffect(() => setTab('summary'), [setTab]);

  useTitle('Requisition detail-Review summary');
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [requisitionActivities, setRequisitionActivities] = useState([]);
  const [isPrintPreview, setIsPrintPreview] = useState(false);
  const [editingVendors, setIsEditingVendors] = useState(false);
  const [reviewOptions, setSelectedReviewOptions] = useState([]);
  const [selectedEngine, setSelectedEngine] = useState(null);

  const [primary, setPrimary] = useState({});
  const [secondary, setSecondary] = useState({});

  const [initialized, setInitialized] = useState(false);

  const [getStandardCodes] = useLazyQuery(GET_STANDARD_CODES, {
    fetchPolicy: 'c',
  });
  const rDispatch = useDispatch();

  const [isAreq, setIsAreq] = useState(false);

  useEffect(() => {
    if (isAreq) {
      rDispatch(
        setCostSummary({
          _source: 'ReviewSummaryTab AREQ',
          areqPriceWithFee: selectedVendorQuote?.areqPriceWithFee || 0,
          baseAndOptionsPrice: selectedVendorQuote?.baseAndOptionsPrice + selectedVendorQuote?.areqPriceWithFee || 0,
          basePrice: selectedVendorQuote?.basePrice || 0,
          perOrderOptionsPrice: selectedVendorQuote?.perOrderOptionsPrice || 0,
          perVehicleColorPrice: selectedVendorQuote?.perVehicleColorPrice || 0,
          perVehicleOptionsPrice: selectedVendorQuote?.perVehicleOptionsPrice || 0,
          purchaseRate: requisitionCartState?.purchaseRate || 0,
          quantity: requisitionCartState?.quantity || 0,
          totalSellingPrice: selectedVendorQuote?.totalSellingPrice || 0,
        }),
      );
    } else {
      if (calculatedPriceData)
        rDispatch(
          setCostSummary({
            _source: 'ReviewSummaryTab',
            areqPriceWithFee: 0,
            baseAndOptionsPrice: calculatedPriceData.baseAndOptionsPrice,
            basePrice: calculatedPriceData.basePrice,
            perOrderOptionsPrice: calculatedPriceData.perOrderOptionsPrice,
            perVehicleColorPrice: calculatedPriceData.perVehicleColorPrice,
            perVehicleOptionsPrice: calculatedPriceData.perVehicleOptionsPrice,
            purchaseRate: calculatedPriceData.purchaseRate,
            quantity: calculatedPriceData.quantity,
            totalSellingPrice: calculatedPriceData.totalSellingPrice,
          }),
        );
    }
  }, [calculatedPriceData, requisitionCartState, isAreq, selectedVendorQuote]);

  useEffect(() => {
    if (!standardItemData) {
      return;
    }

    const retrievePocs = async () => {
      const { data } = await getStandardCodes({
        variables: {
          filters: {
            operator: 'EQ',
            key: 'code',
            value:
              standardItemData?.vehicleTypeCode?.parentCode,
          },
        },
      });

      const standardCodePocs = data.getStandardCodes?.rows.reduce(
        (list, code) => {
          return list.concat(code.standardsVehicleGroupPocs || []);
        },
        [],
      );

      setPrimary(
        standardCodePocs.find((c) => c.pocType === 'PocPrimaryEngineer') || {},
      );

      setSecondary(
        standardCodePocs.find((c) => c.pocType === 'PocSecondaryEngineer') ||
          {},
      );
    };
    retrievePocs();
  }, [standardItemData]);

  const [isExpanded, setIsExpanded] = useState(() => {
    if (status === RequisitionStatus.ORDERING_ADMIN_REVIEW) {
      return false;
    }
    if (
      inPendingCustomerResponseStatus ||
      inEngineerApprovalStatus ||
      inOrderAdminApprovalStatus
    ) {
      return false;
    }
    if (isCustomer || isEngineer) {
      return true;
    }

    return false;
  });
  const [multipleVendorAcceptanceErrors, setMultipleVendorAcceptanceErrors] =
    useState(null);

  const displayAreqPrintPreviewModal = useModal();
  const DisplayAreqPrintPreviewModal = connectModal(
    RequisitionPrintPreviewModal,
  );

  const handlePrintSummary = () => {
    setIsExpanded(true);
    displayAreqPrintPreviewModal.openModal();
  };

  const menuItems = {
    [REQUISITION_BUTTON_DROPDOWN_ACTIONS.PRINT]: {
      onClick: handlePrintSummary,
    },
    [REQUISITION_BUTTON_DROPDOWN_ACTIONS.POST_COMMENT]: {
      onClick: () => setShowAddCommentModal(true),
    },
  };

  const formRef = useRef();
  const printRef = useRef(null);

  const taggedOptions =
    requisitionCartState?.clientData?.selectedOptions?.customerInputs
      ?.taggedOptions;
  const selectedContractCostBreakdown = calculatedPriceData;
  const selectedContractAgencyInformation =
    modifyAgencyInfoData(requisitionCartState);
  const requistionAddressAndContact = {
    ...requisitionCartState?.requisitionerAddress,
    ...requisitionCartState?.requisitionerContact,
  };
  const mailingAddressAndContact = {
    ...requisitionCartState?.mailingAddress,
    ...requisitionCartState?.mailingContact,
  };
  const deliveryAddressAndContact = {
    ...requisitionCartState?.deliveryAddress,
    ...requisitionCartState?.deliveryContact,
  };
  const nonLowBidJustification = requisitionCartState?.justification;

  const agencyReferenceNumber = requisitionCartState?.agencyReferenceNumber;
  const additionalRequirements = requisitionCartState?.additionalRequirements;

  useEffect(() => {
    if (!paintAndGraphicsOptions.length) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_PAINT_AND_GRAPHIC_OPTIONS_DESC,
        payload: paintAndGraphicsOptionsState,
      });
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_TAGGED_OPTIONS,
        payload: taggedOptionsState,
      });
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_TOTAL_UPLOADED_FILES,
        payload: totalUploadedFiles,
      });
    }
  }, []);

  const [submitRequisitionMutation] = useMutation(CREATE_REQUISITION_COMMENT, {
    onCompleted: () => {
      setTab('activities');
    },
  });

  const [
    getRequisitionActivitiesData,
    { data: requisitionActivitiesData, requisitionActivityLoading },
  ] = useLazyQuery(GET_REQUISITION_ACTIVITIES, {
    fetchPolicy: 'no-cache',
  });

  const isEditableInReview = canEditInReview(
    ability,
    requisitionCartState,
    currentUser,
  );

  const handleAddComment = (comment) => {
    setShowAddCommentModal(false);
    const { appURLs } = window.AFP_CONFIG;
    submitRequisitionMutation({
      variables: {
        requisitionId,
        comment,
        baseUrl: appURLs.store,
      },
    });
  };

  const handleCancelComment = () => {
    setShowAddCommentModal(false);
  };
  const [isReqPrevReturned, setIsReqPrevReturned] = useState(false);
  const isCOandEngineerReturned = isContractingOfficer && isReqPrevReturned;

  const [
    getIsReqPrevReturned,
    { data: isReqPrevReturnedData, isPrevReqDataLoading },
  ] = useLazyQuery(IS_REQ_PREVIOUSLY_RETURNED, {
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    getIsReqPrevReturned({
      variables: {
        requisitionId,
      },
    });
    getRequisitionActivitiesData({
      variables: {
        requisitionId,
        offset: 0,
        limit: 20,
        order: [['createdAt', 'DESC']],
      },
    });
  }, [requisitionId]);

  useEffect(() => {
    if (requisitionActivitiesData?.getRequisitionActivities) {
      const { rows } = requisitionActivitiesData.getRequisitionActivities;
      setRequisitionActivities(rows);
    }
  }, [requisitionActivitiesData]);

  useEffect(() => {
    if (isReqPrevReturnedData) {
      setIsReqPrevReturned(isReqPrevReturnedData?.isReqPreviouslyReturned);
    }
  }, [isReqPrevReturnedData]);

  const colors = requisitionCartState?.selectedColorsS;
  const [vehicleContract, _engineData] = requisitionContract
    ? getSimplifiedContract(requisitionContract)
    : [null, null];

  useEffect(() => {
    resetFocusToFirstElement('review-section');
  }, []);

  let fullVendorQuotes = useMemo(
    () =>
      getFullVendorQuotes(vendorQuotes, activeContracts, allModelCostBreakDown),
    [vendorQuotes, activeContracts, allModelCostBreakDown],
  );

  useEffect(() => {
    if (!fullVendorQuotes || fullVendorQuotes.length === 0) {
      return () => {};
    }

    const fullSelectedVendorQuote = fullVendorQuotes.find(
      ({ isSelected }) => isSelected,
    );

    if (fullSelectedVendorQuote) {
      setSelectedVendorQuote(fullSelectedVendorQuote);

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_SELECTED_CONTRACT_INFO,
        selectedContract: fullSelectedVendorQuote,
        selectedContractCostBreakdown: fullSelectedVendorQuote,
      });
    }

    return () => {};
  }, [fullVendorQuotes]);

  const pageStyle = `
    @media print {
      * {
          color-adjust: exact !important;
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
      }

      .header-col-label {
        color: var(--fleet-ink, #1B1B1B);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 135%;
      }
      .custom-review-wrapper {
        max-width: 1300px !important;
      }

      .review-detail div.usa-accordion__content.usa-prose {
        border: none !important;
        padding-right: 30px;
      }

      .review-summary-message-container {
        display: none;
      }

      h2.usa-accordion__heading {
        display: none;
      }

      footer {
        position: fixed;
        bottom: 0;
      }      
    }
  `;

  const printSummary = useReactToPrint({
    pageStyle,
    content: () => printRef.current,
    onAfterPrint: () => setIsPrintPreview(false),
  });

  useEffect(() => {
    if (isPrintPreview) {
      printSummary();
    }
  }, [isPrintPreview]);

  const handlePrint = () => {
    setIsPrintPreview(true);
  };

  const shouldCheckBoacError = () => {
    // https://cm-jira.usa.gov/browse/AFP-159542
    if (requisitionCartState?.agencyCode?.startsWith('N')) {
      return false;
    }

    return (
      (isApprover && shouldCheckBoacErrorForApprover(requisitionCartState)) ||
      (isEngineer && shouldCheckBoacErrorForEngineer(requisitionCartState))
    );
  };

  useEffect(() => {
    if (
      boacForSignalCodeCOrL?.getBoacForFundAgencyCode &&
      shouldCheckBoacError()
    ) {
      validateFss19Response(
        modifyAgencyInfoData(requisitionCartState),
        boacForSignalCodeCOrL.getBoacForFundAgencyCode,
        validateBoacInWalletForAddressCode,
        errorDispatch,
      );
    }
  }, [boacForSignalCodeCOrL]);

  useEffect(() => {
    if (validatedBoac?.validateBOAC && shouldCheckBoacError()) {
      validateBoacResponse(
        modifyAgencyInfoData(requisitionCartState),
        validatedBoac.validateBOAC,
        'requisitionBOAC',
        errorDispatch,
      );
    }
  }, [validatedBoac]);

  useEffect(() => {
    if (validatedBoacAddressCode?.validateBOAC && shouldCheckBoacError()) {
      validateBoacResponse(
        modifyAgencyInfoData(requisitionCartState),
        validatedBoacAddressCode.validateBOAC,
        'signalSupplementaryAddress',
        errorDispatch,
      );
    }
  }, [validatedBoacAddressCode]);

  useEffect(() => {
    if (
      requisitionCartState.vehicles &&
      requisitionCartState.vehicles.length > 0 &&
      shouldCheckBoacError()
    ) {
      initiateBoacValidation(
        modifyAgencyInfoData(requisitionCartState),
        {
          validateBoacInWallet,
          validatedBoac,
          validateBoacInWalletForAddressCode,
          validatedBoacAddressCode,
          getBoacForSignalCodeCOrL,
          boacForSignalCodeCOrL,
        },
        errorDispatch,
      );
    }
  }, [requisitionCartState?.finSignalCode]);

  useEffect(() => {
    if (
      !requisitionCartState.additionalRequirements &&
      !requisitionCartState.agencyReferenceNumber
    ) {
      return;
    }

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_ADDITIONAL_REQUIREMENTS,
      payload: requisitionCartState.additionalRequirements,
    });

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_AGENCY_REF_NUMBER,
      payload: requisitionCartState.agencyReferenceNumber,
    });
  }, [
    requisitionCartState.additionalRequirements,
    requisitionCartState.agencyReferenceNumber,
  ]);

  useEffect(() => {
    setSelectedEngine(buildSelectedEngine(requisitionCartState));
  }, [requisitionCartState]);

  useEffect(() => {
    if (requisitionContract) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_SELECTED_CONTRACT_INFO,
        selectedContract: requisitionContract,
      });
    }
  }, [requisitionContract]);

  useEffect(() => {
    if (!optionalReqData || initialized) {
      return;
    }

    setInitialized(true);
    const perVehicleOptions =
      requisitionCartState?.vehicles[0]?.vehicleEquipments
        ?.filter(
          (eq) =>
            eq.quantityType === OPTION_QUANTITY_REQUIRED_CODE.NONE ||
            eq.quantityType === OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE,
        )
        .map((equipment) => {
          const {
            quantityType,
            quantity,
            equipmentCode,
            totalOptionPrice,
            unitPriceToCustomer,
          } = equipment;
          return {
            optionCode: equipmentCode,
            quantity,
            optionTotalPrice: totalOptionPrice,
            unitPrice: unitPriceToCustomer,
            optionType: 'COST',
            quantityType,
          };
        });
    let groupedPerVehicleOptions = [];
    if (
      requisitionCartState?.requisitionStatus ===
      RequisitionStatus.RECEIVED_BY_GSA
    ) {
      groupedPerVehicleOptions = groupOptionsPerCatalog2(
        optionalReqData,
        perVehicleOptions,
      );
    } else {
      groupedPerVehicleOptions = groupOptionsPerCatalog2(
        optionalReqData,
        isAreq
          ? requisitionCartState?.clientData?.selectedOptions?.selectedOptions
          : selectedContractCostBreakdown?.perVehicleOptions,
      );
    }

    const myOptions = filterOptions(
      groupedPerVehicleOptions,
      paintAndGraphicsOptions,
      taggedOptions,
    );

    setSelectedReviewOptions(myOptions);
  }, [optionalReqData, selectedContractCostBreakdown, isAreq]);

  useMemo(() => {
    const val = isAreqType(requisitionCartState?.requisitionType);
    setIsAreq(val);
    // NOTE: Dispaching to state so that the print modal knows how to handle AREQ
    dispatchIsAreq(val);
  }, [requisitionCartState?.requisitionType]);

  const ReviewSections = () => {
    return (
      <div className="review-detail">
        {isAreq && status !== RequisitionStatus.ORDERING_ADMIN_REVIEW && (
          <AccordionUtilComponent
            label="AREQ details"
            printPreview={isPrintPreview}
            content={
              <AreqDetails
                allActiveContracts={activeContracts}
                agencyReferenceNumber={agencyReferenceNumber}
                additionalRequirements={additionalRequirements}
                isEditable={isEditableInReview}
                createdByInfo={requisitionCartState?.createdByInfo}
                primary={primary}
                secondary={secondary}
                contractCostBreakDowns={allModelCostBreakDown}
                isLeasing={
                  requisitionCartState?.transactionType ===
                  RequisitionTransactionType.VEHICLE_LEASE
                }
              />
            }
            expanded={isExpanded}
          />
        )}
        <AccordionUtilComponent
          label="Options per vehicle"
          printPreview={isPrintPreview}
          content={
            <OptionsPerVehicle
              selectedContractCostBreakdown={selectedContractCostBreakdown}
              isEditable={isEditableInReview}
              toggleViewSelectedOptions={toggleViewSelectedOptions}
              options={reviewOptions}
              isAreq={isAreq}
              selectedVendorQuote={selectedVendorQuote}
            />
          }
          expanded={isExpanded}
        />
        <AccordionUtilComponent
          label="Paint and graphics"
          printPreview={isPrintPreview}
          content={
            <PaintAndGraphics
              taggedOptions={taggedOptions}
              paintAndGraphicsOptions={paintAndGraphicsOptions}
              requisition={requisitionCartState}
              isEditable={isEditableInReview}
              requisitionAttachmentTypes={attachmentTypes}
            />
          }
          expanded={isExpanded}
        />
        {taggedOptions?.length > 0 && (
          <AccordionUtilComponent
            label="Options details"
            printPreview={isPrintPreview}
            content={
              <TaggedOptionDetails
                taggedOptions={taggedOptions}
                isEditable={isEditableInReview}
                requisitionAttachmentTypes={attachmentTypes}
              />
            }
            expanded={isExpanded}
          />
        )}
        <AccordionUtilComponent
          label="Cost summary"
          printPreview={isPrintPreview}
          content={
            <CostSummary
              calculatedPriceData={selectedContractCostBreakdown}
              // {
              //   state.selectedContractCostBreakdown || calculatedPriceData
              // }
              selectedContract={
                []
                // state.selectedContract || selectedContractCostBreakdown
              }
              isEditable={isEditableInReview}
              isAreq={isAreq}
              requisition={requisitionCartState}
            />
          }
          expanded={isExpanded}
        />
        {nonLowBidJustification && (
          <AccordionUtilComponent
            label="Non-low price justification"
            printPreview={isPrintPreview}
            content={
              <Justification
                title="Justification for your non-low price selection"
                justificationData={nonLowBidJustification}
                isEditable={isEditableInReview}
              />
            }
            expanded={isExpanded}
          />
        )}
        {selectedContractAgencyInformation?.agencyCode && (
          <AccordionUtilComponent
            label="Agency information"
            printPreview={isPrintPreview}
            content={
              <AgencyFinanceInfo
                selectedContractAgencyInformation={
                  selectedContractAgencyInformation
                }
                standardItemData={standardItemData}
                isEditable={isEditableInReview}
                calculatedPriceData={calculatedPriceData}
                isAreq={
                  isAreq || isAreqType(requisitionCartState?.requisitionType)
                }
                requisitionNumber={requisitionCartState?.requisitionNumber}
              />
            }
            expanded={isExpanded}
          />
        )}

        {(requistionAddressAndContact ||
          mailingAddressAndContact ||
          deliveryAddressAndContact) && (
          <AccordionUtilComponent
            label="Delivery information"
            printPreview={isPrintPreview}
            content={
              <AddressReviewGroup
                requisitionAddress={requistionAddressAndContact}
                mailingAddress={mailingAddressAndContact}
                deliveryAddress={deliveryAddressAndContact}
                isEditable={isEditableInReview}
                dealershipCode={deliveryDealershipCode}
              />
            }
            expanded={isExpanded}
          />
        )}
      </div>
    );
  };

  // Only display acceptable quotes to the customer
  if (status === RequisitionStatus.PENDING_CUSTOMER_RESPONSE) {
    fullVendorQuotes = fullVendorQuotes.filter(
      ({ isAcceptable, errorMsg }) => isAcceptable || errorMsg,
    );
  }

  return (
    <>
      <div id="review-summary-tab" tabIndex="0" role="tab" className="grid-row">
        <div className="grid-col-3">
          {vehicleContract && (
            <VehicleInformation
              contract={vehicleContract}
              options={perVehicleOptionsState}
              quantity={requisitionCartState?.quantity}
              standardItem={requisitionStandardItem}
              colorsFrmProp={colors}
              engine={selectedEngine}
            />
          )}
        </div>
        <div className="grid-col-9 padding-x-3">
          <div className="review-section">
            <ReviewSummaryTaskList
              status={requisitionCartState.requisitionStatus}
            />

            {isContractingOfficer ||
              requisitionCartState?.onHold ||
              inPendingCustomerResponseStatus ||
              inOrderAdminApprovalStatus ||
              inEngineerApprovalStatus ||
              isFinalApproval ||
              isOrderingAdmin || (
                <>
                  <div className="review-title">Review summary</div>
                </>
              )}
            {currentUser?.id === requisitionCartState?.createdByInfo?.id &&
              [
                RequisitionStatus.FINAL_APPROVAL,
                RequisitionStatus.DRAFT_APPROVAL,
              ].includes(status) && (
                <>
                  <div className="review-desc">
                    You have already submitted this requisition to your agency
                    approver. You may review, print and post comments to this
                    order.
                  </div>
                </>
              )}
          </div>

          {showError && (
            <div className="margin-bottom-3">
              <SystemAlert
                systemError={{
                  showError,
                }}
                setSystemError={setShowError}
              />
            </div>
          )}

          <div className="display-flex flex-align-end justify-content-end margin-bottom-4 requisition-action-container">
            <RequisitionActionsButtonDropdown menuItems={menuItems} />
          </div>

          {isEngineer &&
            requisitionCartState?.onHold &&
            requisitionActivities[0] && (
              <ReviewSummaryOnHoldHeader
                onHoldRequisitionActivity={requisitionActivities[0]}
              />
            )}

          <div
            className="review-vehicle-build review-submit-requisition"
            ref={printRef}
          >
            {isReqPrevReturned &&
              isNot_CO_Engineer &&
              [
                RequisitionStatus.DRAFT_APPROVAL,
                RequisitionStatus.FINAL_APPROVAL,
              ].includes(status) &&
              isApprover && (
                <div className="review-summary-message-container">
                  <ReviewSummaryMessage requisitionId={requisitionId} />
                </div>
              )}

            <div className="custom-review-wrapper">
              <ReviewSections />
            </div>
          </div>

          <>
            {(isCOandCoApproval ||
              (isCOandCoReview && isAreq) ||
              inPendingCustomerResponseStatus ||
              (inOrderAdminApprovalStatus &&
                isAreq &&
                (isOrderingAdmin || isAdmin)) ||
              (isFinalApproval && isAreq)) &&
              !isCOandEngineerReturned && (
                <>
                  <MultipleVendorInstructions
                    status={status}
                    type={isAreq ? 'AREQ' : 'standard requisition'}
                  />
                  {isCOandCoReview && (
                    <AreqAddMultipleVendors
                      visible={isCOandCoReview}
                      reference={formRef}
                      draftId={requisitionId}
                      requisitionName={requisitionCartState.friendlyName}
                      customClass="review-summary-tab"
                      fromCoToEngineer
                      activeContracts={activeContracts}
                      selectedOptions={
                        requisitionCartState?.clientData?.selectedOptions
                          ?.selectedOptions
                      }
                      vendorQuotes={fullVendorQuotes}
                      contractCostBreakDowns={allModelCostBreakDown}
                      isLeasing={
                        requisitionCartState?.transactionType ===
                        RequisitionTransactionType.VEHICLE_LEASE
                      }
                    />
                  )}

                  <MultipleVendorsAcceptance
                    visible={
                      isCOandCoApproval ||
                      (inPendingCustomerResponseStatus &&
                        !selectedVendorQuote) ||
                      (inOrderAdminApprovalStatus && !selectedVendorQuote)
                    }
                    requisitionId={requisitionId}
                    requisitionName={requisitionCartState.friendlyName}
                    reference={formRef}
                    vendorQuotes={fullVendorQuotes}
                    isCo={isCOandCoApproval}
                    status={status}
                    activeContracts={activeContracts}
                    allModelCostBreakDown={allModelCostBreakDown}
                    requisitionCartState={requisitionCartState}
                  />

                  <SelectedVendorAcceptance
                    visible={Boolean(selectedVendorQuote)}
                    requisitionId={requisitionId}
                    requisitionName={requisitionCartState.friendlyName}
                    reference={formRef}
                    vendorQuotes={fullVendorQuotes}
                    status={status}
                    requisition={requisitionCartState}
                  />
                </>
              )}

            {displayMessageLines(isAreq, status) && (
              <RequisitionMessageLines
                requisitionId={requisitionId}
                isEditAllowed={isCOandCoApproval}
              />
            )}

            {(isEngineer ||
              isContractingOfficer ||
              isOrderingAdmin ||
              isAdmin) &&
              inEngineerApprovalStatus &&
              isAreq && (
                <>
                  <div className="actions-section-title">
                    CURRENT STEP ACTIONS
                  </div>

                  {multipleVendorAcceptanceErrors && (
                    <Alert type="error" slim>
                      This section has{' '}
                      <strong>
                        {convertNumToWors(
                          multipleVendorAcceptanceErrors.length,
                        )}
                      </strong>{' '}
                      Please address all fields outlined in red before
                      proceeding to the next step.
                    </Alert>
                  )}

                  <MultipleVendorsAcceptance
                    requisitionId={requisitionId}
                    requisitionName={requisitionCartState.friendlyName}
                    reference={formRef}
                    vendorQuotes={fullVendorQuotes}
                    status={status}
                    setErrors={setMultipleVendorAcceptanceErrors}
                    requisitionCartState={requisitionCartState}
                    activeContracts={activeContracts}
                  />
                </>
              )}
          </>
          {isCOandEngineerReturned &&
            status !== RequisitionStatus.ENGINEERING_APPROVAL && (
              <>
                <div className="actions-section-title">
                  CURRENT STEP ACTIONS
                </div>
                <section className="vendor-quote-section-title">
                  {isAreq && (
                    <>
                      <div className="quote-title">
                        Review and confirm information, then submit to GSA
                        Engineering
                      </div>

                      <div className="title-desc">
                        You may also return to the previous step to make further
                        edits
                      </div>
                    </>
                  )}
                </section>
                {isAreq && (
                  <>
                    <div className="section-main-title">
                      AREQ requisition information
                      {!editingVendors &&
                        (!isCOandCoApproval || !isCOandCoReview) && (
                          <Button
                            variant="outline"
                            onClick={() => {
                              setIsEditingVendors(true);
                              setIsExpanded(false);
                            }}
                            data-testid="vehicle-spec-edit-section"
                            leftIcon={{ name: 'edit' }}
                            label="Edit"
                            size="small"
                          />
                        )}
                    </div>
                    <AreqAddMultipleVendors
                      visible={
                        isCOandCoReview ||
                        (isCOandEngineerReturned && editingVendors)
                      }
                      reference={formRef}
                      draftId={requisitionId}
                      requisitionName={requisitionCartState.friendlyName}
                      customClass="review-summary-tab"
                      fromCoToEngineer
                      activeContracts={activeContracts}
                      selectedOptions={
                        requisitionCartState?.clientData?.selectedOptions
                          ?.selectedOptions
                      }
                      vendorQuotes={fullVendorQuotes}
                      canEdit={isCOandEngineerReturned}
                      contractCostBreakDowns={allModelCostBreakDown}
                      isLeasing={
                        requisitionCartState?.transactionType ===
                        RequisitionTransactionType.VEHICLE_LEASE
                      }
                    />
                    <MultipleVendorsAcceptance
                      visible={
                        (isCOandEngineerReturned &&
                          !editingVendors &&
                          status !== RequisitionStatus.CONTRACTING_REVIEW) ||
                        isCOandCoApproval ||
                        (inPendingCustomerResponseStatus &&
                          !selectedVendorQuote)
                      }
                      canEdit={isCOandEngineerReturned}
                      requisitionId={requisitionId}
                      requisitionName={requisitionCartState.friendlyName}
                      reference={formRef}
                      vendorQuotes={fullVendorQuotes}
                      isCo={isCOandCoApproval || isCOandEngineerReturned}
                      status={status}
                      isReturned={isReqPrevReturned}
                      requisitionCartState={requisitionCartState}
                      activeContracts={activeContracts}
                    />
                  </>
                )}
              </>
            )}
          {status === RequisitionStatus.ORDERING_ADMIN_REVIEW && (
            <>
              <div className="list-title margin-bottom-20">
                Current Step Actions
              </div>
              {state.isLeasingAdditionalRequirementsPreview ? (
                <>
                  <div className="title">
                    Review and confirm entered information, then submit to GSA
                    Engineering
                  </div>
                  <div className="margin-bottom-20">
                    You may also return to the previous step to make further
                    edits AREQ requisition information
                  </div>
                  <AreqDetails
                    headerOverride="AREQ requisition information"
                    agencyReferenceNumber={state.agencyReferenceNumber}
                    additionalRequirements={state.additionalRequirements}
                    createdByInfo={currentUser}
                    onClickOverride={() => {
                      dispatch({
                        type: VehicleRequisitionContextActions.SET_IS_LEASING_ADDITIONAL_REQUIREMENTS_PREVIEW,
                        payload: false,
                      });
                    }}
                    isEditable
                    allActiveContracts={activeContracts}
                    allCostBreakdown={state.allCostBreakdown}
                    contractCostBreakDowns={allModelCostBreakDown}
                    isLeasing={
                      requisitionCartState?.transactionType ===
                      RequisitionTransactionType.VEHICLE_LEASE
                    }
                    primary={primary}
                    secondary={secondary}
                  />
                </>
              ) : (
                <ReviewSummaryMultipleAdditionalRequirements
                  formRef={formRef}
                  createdByInfo={currentUser}
                  vehicleType={
                    requisitionStandardItem?.vehicleTypeCode?.parentCode
                  }
                  additionalRequirements={state.additionalRequirements}
                  agencyReferenceNumber={state.agencyReferenceNumber}
                  requisitionType={requisitionCartState.requisitionType}
                  requisitionCartState={requisitionCartState}
                  setRequisitionCartState={setRequisitionCartState}
                />
              )}
            </>
          )}

          <div className="display-flex">
            {!requisitionActivityLoading && !isPrevReqDataLoading && (
              <ActionButtons
                reference={formRef}
                requisitionId={requisitionId}
                requisitionCartState={requisitionCartState}
                requisitionCartValidations={requisitionCartValidations}
                canSubmitOrder={canSubmitOrder}
                currentStandardItem={standardItemData}
              />
            )}
            <div className="flex-fill" />
            {allowComments && (
              <div>
                <Button
                  type="button"
                  variant="outline"
                  data-testid="add-comment-button-bottom"
                  className="margin-left-auto"
                  onClick={() => {
                    setShowAddCommentModal(true);
                  }}
                  leftIcon={{ name: 'edit' }}
                  label="Add comment"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <CommentModal
        data-testid="add-comment-modal"
        isOpen={showAddCommentModal}
        onSubmit={handleAddComment}
        onCancel={handleCancelComment}
        body="Your comment will be added to this timeline and sent to your GSA service rep."
        isRequired
      />
      <DisplayAreqPrintPreviewModal
        isOpen={displayAreqPrintPreviewModal.isOpen}
        handleClose={displayAreqPrintPreviewModal.closeModal}
        handlePrint={handlePrint}
      />
    </>
  );
};

ReviewSummaryTab.propTypes = {
  setTab: PropTypes.func.isRequired,
  requisitionId: PropTypes.string.isRequired,
  requisitionContract: PropTypes.instanceOf(Object).isRequired,
  requisitionStandardItem: PropTypes.instanceOf(Object).isRequired,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
  setRequisitionCartState: PropTypes.func.isRequired,
  perVehicleOptionsState: PropTypes.instanceOf(Array).isRequired,
  standardItemData: PropTypes.instanceOf(Object).isRequired,
  paintAndGraphicsOptionsState: PropTypes.instanceOf(Array).isRequired,
  taggedOptionsState: PropTypes.instanceOf(Array).isRequired,
  totalUploadedFiles: PropTypes.instanceOf(Object).isRequired,
  requisitionCartValidations: PropTypes.arrayOf(Object).isRequired,
  calculatedPriceData: PropTypes.instanceOf(Object).isRequired,
  toggleViewSelectedOptions: PropTypes.func,
  activeContracts: PropTypes.instanceOf(Array),
  allModelCostBreakDown: PropTypes.instanceOf(Array),
  attachmentTypes: PropTypes.instanceOf(Array).isRequired,
  optionalReqData: PropTypes.instanceOf(Array).isRequired,
  updateRequisitionColorFn: PropTypes.func.isRequired,
};

ReviewSummaryTab.defaultProps = {
  toggleViewSelectedOptions: null,
  activeContracts: [],
  allModelCostBreakDown: [],
};

export default ReviewSummaryTab;
