import { createSlice } from '@reduxjs/toolkit';

const reviewInitialState = {
  sinContracts: [],
  attachmentTypes: [],
  contractsCostBreakdown: null,
  contract: null,
  costBreakdown: null,
  customPaintAttachments: [],
  optionalRequirements: null,
  paintOptions: [],
  requisition: null,
  standardItem: null,
  taggedOptions: [],
  validations: null,
};

const reviewSlice = createSlice({
  name: 'review',
  initialState: reviewInitialState,
  reducers: {
    resetState: () => {
      return reviewInitialState;
    },
    setSinContracts: (state, action) => {
      return {
        ...state,
        sinContracts: action.payload,
      };
    },
    setContract: (state, action) => {
      return {
        ...state,
        contract: action.payload,
      };
    },
    setAttachmentTypes: (state, action) => {
      return {
        ...state,
        attachmentTypes: action.payload,
      }; 
    },
    setOptionalRequirements: (state, action) => {
      return {
        ...state,
        optionalRequirements: action.payload,
      };
    },
    setRequisition: (state, action) => {
      return {
        ...state,
        requisition: action.payload,
        validations: action.payload.validations,
        paintOptions: action.payload.clientData?.selectedOptions?.customerInputs?.paintAndGraphicsOptions,
        taggedOptions: action.payload.clientData?.selectedOptions?.customerInputs?.taggedOptions,
        customPaintAttachments: action.payload.clientData?.selectedOptions?.supportingDocuments || [],
      };
    },
    setRequisitionColor: (state, action) => {
      return {
        ...state,
        requisition: {
          ...state.requisition,
          vehicles: [
            {
              ...state.requisition.vehicles[0],
              ...action.payload,
            }
          ]
        },
      };
    },
    setStandardItem: (state, action) => {
      return {
        ...state,
        standardItem: action.payload,
      };
    },
    setContractsCostBreakdown: (state, action) => {
      return {
        ...state,
        contractsCostBreakdown: action.payload?.modelCostBreakDown,
      };
    },
    setCostBreakdown: (state, action) => {
      return {
        ...state,
        costBreakdown: action.payload,
      };
    },
  },
});

export const {
  resetState,
  setSinContracts,
  setContract,
  setAttachmentTypes,
  setContractsCostBreakdown,
  setCostBreakdown,
  setOptionalRequirements,
  setRequisition,
  setRequisitionColor,
  setStandardItem,
} = reviewSlice.actions;

export default reviewSlice.reducer;
